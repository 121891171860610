import {Modal} from "@mui/material";
import {useForm} from "react-hook-form";
import {Eixo} from "interfaces/Eixo";
import {serviceUpdateEixo} from "api/EixoService";
import {useModalContext} from "contexts/ModalContext";


interface FormEixoValues {
    nome: string;
    descricao: string | null;
}

export default function ModalEditarEixo(
    {
        modalState: [modalOpen, setModalOpen],
        eixo,
        refetch
    }: {
        modalState: [boolean, (modalOpen: boolean) => void],
        eixo: Eixo,
        refetch: () => void
    }
) {
    const {openNotification} = useModalContext();

    const {register, handleSubmit, reset, setValue} = useForm<FormEixoValues>({
        defaultValues: {
            nome: eixo.nome,
            descricao: eixo.descricao
        }
    });

    const handleFormSubmit = async (data: FormEixoValues) => {
        try {
            const newEixo = {
                id: eixo.id,
                nome: data.nome,
                descricao: data.descricao || ""
            }
            const response = await serviceUpdateEixo(newEixo)
            openNotification('Eixo atualizado com sucesso', 'success');
            refetch();
        } catch (error) {
            console.log(error)
        }
        setModalOpen(false);
        reset();
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <form className="bg-white-1 w-[50rem] px-16 h-auto p-4 mx-auto rounded-xl"
                  onSubmit={handleSubmit(handleFormSubmit)}
            >

                <p className="mt-[2.5%] text-[#132D46] font-raleway text-center font-bold text-lg">
                    Editar Eixo
                </p>

                <div className="mt-[2%] mb-[2%] h-[0.063rem] border-[0.063rem] bg-gray-500"></div>

                <p className="text-[#4F4F4F] font-raleway font-bold text-sm">Nome</p>
                <input
                    className="w-full bg-[#EFF2F7] opacity-30 rounded-[0.625rem]"
                    type="text"
                    {...register('nome', {required: true})}
                />

                <p className="mt-4 text-[#4F4F4F] font-raleway font-bold text-sm">Descrição</p>
                <textarea
                    className="w-full bg-[#EFF2F7] opacity-30 rounded-[0.625rem]"
                    {...register('descricao', {required: false})}
                />

                <div className="flex justify-center mt-4 space-x-4">
                    <button
                        type="button"
                        className="bg-red-to_do rounded-[0.75rem] w-28 text-sm h-10 text-white"
                        onClick={() => setModalOpen(false)}
                    >
                        Cancelar
                    </button>
                    <button
                        className="bg-[#01C38E] rounded-[0.75rem] w-28 text-sm h-10 text-[#FFFFFF]"
                        type="submit"
                    >
                        Editar Eixo
                    </button>
                </div>

            </form>

        </Modal>
    );
}