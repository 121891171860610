import {Header} from "components/Headers/HeaderFelipe";
import React from 'react';
import {TemplateLeftBar} from "components";
import ComoFuncionaOpcoes from "./components/ComoFuncionaOpcoes";
import ComoFuncionaDashboard from "./components/ComoFuncionaDashboard";
import ComoFuncionaQuestionarios from "./components/ComoFuncionaQuestionarios";

export default function ComoFunciona() {
    return (
        <TemplateLeftBar>

            <Header fluxo={[
                {nome: 'Como funciona', path: '/como-funciona'},
            ]}/>

            <div className='flex flex-col'>

                <div className='mt-16 text-2xl font-bold text-blue-login'>
                    <div>O que é?</div>
                </div>

                <div className='mt-4 text-lg font-semibold text-gray-5'>
                    <div>
                        A plataforma GAIA foi criada na intenção de facilitar avaliações de logísticas internas de
                        instituições públicas e privadas, através da criação de questionários a serem respondidos pelo
                        público.
                    </div>
                </div>

                <div className='flex space-x-16 justify-center mt-8'>
                    <div
                        className='flex flex-col bg-white-1 w-[26rem] h-[26rem] items-center justify-between rounded-xl border border-gray-light gap-6 p-6'>
                        <div
                            className='text-2xl font-bold text-blue-login text-center px-4 min-h-[4rem] flex items-center justify-center'>
                            Crie e compartilhe seu questionário
                        </div>
                        <img className='h-auto w-40'
                             src={require('../../assets/images/ComoFuncionaImages/como_funciona_01.png')} alt=""/>
                        <div
                            className='text-lg font-medium text-gray-5 text-center px-16 min-h-[5rem] flex items-center justify-center'>
                            Crie o seu questionário, com eixos que ditarão a sua avaliação da pesquisa.
                        </div>
                    </div>

                    <div
                        className='flex flex-col bg-white-1 w-[26rem] h-[26rem] items-center justify-between rounded-xl border border-gray-light gap-6 p-6'>
                        <div
                            className='text-2xl font-bold text-blue-login text-center px-4 min-h-[4rem] flex items-center justify-center'>
                            Compare os resultados com outras intituições
                        </div>
                        <img className='h-auto w-60'
                             src={require('../../assets/images/ComoFuncionaImages/como_funciona_02.png')} alt=""/>
                        <div
                            className='text-lg font-medium text-gray-5 text-center min-h-[5rem] flex items-center justify-center'>
                            Compare seus resultados para compreender a média obtida em outros lugares e analisar o
                            mercado.
                        </div>
                    </div>

                    <div
                        className='flex flex-col bg-white-1 w-[26rem] h-[26rem] items-center justify-between rounded-xl border border-gray-light gap-6 p-6'>
                        <div
                            className='text-2xl font-bold text-blue-login text-center px-4 min-h-[4rem] flex items-center justify-center'>
                            Obtenha o diagnóstico comentado
                        </div>
                        <img className='h-auto w-40'
                             src={require('../../assets/images/ComoFuncionaImages/como_funciona_03.png')} alt=""/>
                        <div
                            className='text-lg font-medium text-gray-5 text-center min-h-[5rem] flex items-center justify-center'>
                            Obtenha o diagnóstico comentado, obtendo possíveis soluções para aprimorar seus resultados
                            futuros.
                        </div>
                    </div>
                </div>

                <div className='mt-16 text-2xl font-bold text-blue-login'>
                    <div>Como Funciona?</div>
                </div>

                <ComoFuncionaOpcoes
                    titulo="Dashboard"
                    Conteudo={ComoFuncionaDashboard}
                />
                <ComoFuncionaOpcoes
                    titulo="Questionários"
                    Conteudo={ComoFuncionaQuestionarios}
                />
                <ComoFuncionaOpcoes
                    titulo="Eixos"
                    Conteudo={ComoFuncionaDashboard}
                />
                <ComoFuncionaOpcoes
                    titulo="Resultados"
                    Conteudo={ComoFuncionaDashboard}
                />
                <ComoFuncionaOpcoes
                    titulo="Comparar"
                    Conteudo={ComoFuncionaDashboard}
                />
                <ComoFuncionaOpcoes
                    titulo="Biblioteca de Ativos"
                    Conteudo={ComoFuncionaDashboard}
                />

            </div>

        </TemplateLeftBar>
    )
}