import {Line as LineRadar} from 'react-chartjs-2';
import {Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip} from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);


export default function LinhaAtividade({data}: { data: any }) {

    return (
        <LineRadar
            data={{
                labels: data.map((d: any) => d.mes),
                datasets: [
                    {
                        label: 'Atividade nos últimos meses',
                        data: data.map((d: any) => d.total),
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                    }
                ]
            }}
            options={{
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 4
                        }
                    }
                }
            }}
        />
    )

}