import {useState} from "react";
import {AiOutlineClose, AiOutlineSearch} from "react-icons/ai";
import DebouncedInput from "./DebouncedInput";

interface SearchTextProps {
    column: [columnValue: any, setColumnValue: Function],
    keyValue: string,
}

export default function SearchText({column: [columnValue, setColumnValue], keyValue}: SearchTextProps) {
    const [showInput, setShowInput] = useState(false);
    const columnFilterValue = columnValue[keyValue] ?? '';

    function remove() {
        const newColumnValue = {...columnValue};
        delete newColumnValue[keyValue];
        setColumnValue(newColumnValue);
    }

    function update(value: string) {
        setColumnValue({...columnValue, [keyValue]: value});
        if (value === '') {
            remove();
        }
    }


    return (
        <div
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowInput(false);
                }
            }}
            tabIndex={0}
        >
            <AiOutlineSearch
                className="select-none cursor-pointer"
                onClick={() => setShowInput(!showInput)}
            />
            {
                showInput && (
                    <div
                        className="flex items-center absolute bg-white border-[1px] border-[#101010] rounded-lg p-1"
                    >
                        <DebouncedInput
                            className="w-[fit] rounded-lg mx-1 border-[1px] border-[#132D46] p-1 text-[#132D46]"
                            onChange={update}
                            placeholder={`Pesquise`}
                            value={columnFilterValue}
                        />
                        <AiOutlineClose
                            className="bg-[#D9D9D9] select-none cursor-pointer mx-1 rounded-lg p-1"
                            onClick={() => {
                                remove();
                                setShowInput(false);
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
}

