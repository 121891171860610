export const SmallChart = {
    elements: {
        line: {
            borderWidth: 1, // Set the line width
        },
        point: {
            radius: 0, // Set the point radius
            borderWidth: 2, // Set the point border width
            hoverRadius: 6, // Set the point hover radius
            pointHitRadius: 14, // Set the radius when hit detection is performed
        }
    },
    plugins: {
        legend: {
            display: false,
            position: 'bottom' as const,
            labels: {
                boxWidth: 10,
                font: {
                    size: 10,
                }
            }
        },


    },
    scales: {

        r: {
            ticks: {
                display: false,
            },
            pointLabels: {
                display: true,
                font: {
                    size: 5
                }
            },
            grid: {
                color: 'rgba(0, 0, 0, 0.3)' // Set the color of the radar grid lines
            },
            suggestedMin: 0,
            suggestedMax: 100,
        }
    },

}


export const LargeChart = {
    scales: {
        r: {
            ticks: {

                display: true,
                color: 'rgba(0, 0, 0, 0.3)',
                font: {
                    size: 10,
                },
                backdropColor: 'rgba(0, 0, 0, 0)',
            },
            pointLabels: {
                display: true,
                font: {
                    size: 10,
                },
            },
            grid: {
                color: 'rgba(0, 0, 0, 0.3)' // Set the color of the radar grid lines
            },
            suggestedMin: 0,
            suggestedMax: 100,
        },


    },
    plugins: {
        legend: {
            display: false,
        },
    },
}