import HomeNavbar from "./components/HomeNavbar";
import SectionHome from "./components/SectionHome";
import SectionSobre from "./components/SectionSobre";
import SectionComoFunciona from "./components/SectionComoFunciona";
import SectionFramework from "./components/SectionFramework";
import SectionFeedbacks from "./components/SectionFeedbacks";
import SectionArtigos from "./components/SectionArtigos";
import SectionBotoes from "./components/SectionBotoes";
import SectionFooter from "./components/SectionFooter";

export default function Home() {
    return (
        <div>
            <HomeNavbar/>

            <div id='home'>
                <SectionHome/>
            </div>

            <div id='sobre'>
                <SectionSobre/>
            </div>

            <div id='comoFunciona'>
                <SectionComoFunciona/>
            </div>

            <div id='framework'>
                <SectionFramework/>
            </div>

            <div id='feedbacks'>
                <SectionFeedbacks/>
            </div>

            <div> {/*Artigos*/}
                <SectionArtigos/>
            </div>

            <div> {/*Botões*/}
                <SectionBotoes/>
            </div>

            <div> {/*Footer*/}
                <SectionFooter/>
            </div>
        </div>
    );
}