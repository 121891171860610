import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getEstatisticasQuestionariosUsuario, getEstatisticasUsuario} from "api/UsuarioService";
import {EstatisticasQuestionariosUsuario, EstatisticasUsuario} from "interfaces/Estatisticas";

export function useGetEstatisticasUsuario(id: number) {
    const [estatisticasUsuario, setEstatisticasUsuario] = useState<EstatisticasUsuario | null>(null);
    const {data, refetch, error} = useQuery<EstatisticasUsuario>({
        queryKey: ['estatisticasUsuario', id],
        queryFn: async () => {
            const response = await getEstatisticasUsuario(id);
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setEstatisticasUsuario(data);
        }
    }, [data]);

    return {estatisticasUsuario, refetch, error};
}

export function useGetEstatisticasQuestionariosUsuario(id: number) {
    const [estatisticasUsuario, setEstatisticasUsuario] = useState<EstatisticasQuestionariosUsuario | null>(null);
    const {data, refetch, error} = useQuery<EstatisticasQuestionariosUsuario>({
        queryKey: ['estatisticasQuestionariosUsuario', id],
        queryFn: async () => {
            const response = await getEstatisticasQuestionariosUsuario(id);
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setEstatisticasUsuario(data);
        }
    }, [data]);

    return {estatisticasUsuario, refetch, error};
}