import React, {useEffect} from 'react';
import './styles.css';

interface LoadingOverlayProps {
    active: boolean;
}

export default function LoadingOverlay({active}: LoadingOverlayProps) {
    useEffect(() => {
        if (active) {
            // Disable scroll when overlay is active
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable scroll when overlay is not active
            document.body.style.overflow = 'auto';
        }

        // Cleanup: Re-enable scroll when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [active]);

    if (!active) {
        return null; // Don't render the overlay if not active
    }

    return (
        <div className="loading-overlay">
            <svg
                width="200"
                height="200"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                className="loading-svg"
            >
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{stopColor: '#01C38E', stopOpacity: 1}}/>
                        <stop offset="100%" style={{stopColor: '#39C69F', stopOpacity: 1}}/>
                    </linearGradient>
                    <mask id="mask">
                        <rect x="0" y="0" width="100" height="100" fill="url(#gradient)"/>
                    </mask>
                </defs>
                <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="url(#gradient)"
                    strokeWidth="10"
                    fill="none"
                    mask="url(#mask)"
                >
                    <animate
                        attributeName="stroke-dasharray"
                        from="0, 251.2"
                        to="251.2, 0"
                        dur="2s"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </div>
    );
}
