import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {BsFillPlusCircleFill} from "react-icons/bs";
import {FaRegTrashAlt} from "react-icons/fa";
import {GoPlus} from "react-icons/go";
import {LuCopy} from "react-icons/lu";
import "./styles.css";
import PerguntaAlternativas from 'interfaces/PerguntaAlternativa';
import {
    serviceAtualizarPergunta,
    serviceDeletarPerguntaQuestionario,
    serviceDuplicarPerguntaQuestionario,
    serviceNovaPerguntaQuestionario
} from 'api/QuestionarioService';
import {SimpleQuestionario} from 'interfaces/Questionario';
import LoadingOverlay from 'components/LoadingOverlay';
import {useModalContext} from 'contexts/ModalContext';
import {useMutation} from '@tanstack/react-query';

export default function PerguntasQuestionarioForm
({
     questionarioState: [questionario, setQuestionario],
     editingQueueState: [editedQueue, setEditedQueue],
 }: {
    questionarioState: [SimpleQuestionario, (questionario: SimpleQuestionario) => void],
    editingQueueState: [number[], Dispatch<SetStateAction<number[]>>]
}) {
    const inputRef = useRef<HTMLInputElement | null>(null);


    const {openNotification} = useModalContext();

    const [editingId, setEditingId] = useState<number | null>(null);
    const [perguntas, setPerguntas] = useState<PerguntaAlternativas[]>(questionario.perguntas!);
    const eixos = questionario!.eixos!;

    useEffect(() => {
        setQuestionario({
            ...questionario,
            perguntas
        });
    }, [perguntas]);

    function updateQueue(id: number) {
        if (!editedQueue.includes(id)) {
            setEditedQueue((prevQueue) => [...prevQueue, id]);
        }
    }

    const {mutate: mutateUpdatePergunta, isPending: isPendingUpdatePergunta} = useMutation({
        mutationFn: serviceAtualizarPergunta,
        onSuccess: (response: any) => {
            setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
                if (pergunta.id === response.data.id) {
                    return response.data;
                }
                return pergunta;
            }))
        },
        onError: (error: any) => {
            console.log(error);
        }
    });

    useEffect(() => {
        function liveSync() {
            if (editedQueue.length > 0) {
                editedQueue.forEach(async (id) => {
                    const pergunta = perguntas.find(pergunta => pergunta.id === id)!;
                    if (!pergunta) return;
                    mutateUpdatePergunta({pergunta});
                    setEditedQueue((prevQueue) => prevQueue.filter((queueId) => queueId !== id));
                });
            }
        }

        const interval = setInterval(liveSync, 5000);

        return () => clearInterval(interval);
    }, [editedQueue, perguntas]);

    function changePerguntaDescricao(id: number, descricao: string) {
        setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
            if (pergunta.id === id) {
                return {
                    ...pergunta,
                    descricao
                }
            }
            return pergunta;
        }));
        updateQueue(id);
    }

    function changePerguntaComentario(id: number, comentario: string) {
        setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
            if (pergunta.id === id) {
                return {
                    ...pergunta,
                    comentario
                }
            }
            return pergunta;
        }));
        updateQueue(id);
    }

    function changeAlternativaDescricao(id: number, alternativaIndex: number, descricao: string) {
        setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
            if (pergunta.id === id) {
                return {
                    ...pergunta,
                    alternativas: pergunta.alternativas.map((alternativa: any, index: number) => {
                        if (index === alternativaIndex) {
                            return {
                                ...alternativa,
                                descricao
                            }
                        }
                        return alternativa;
                    })
                }
            }
            return pergunta;
        }));
        updateQueue(id);
    }

    function changeFatorMultiplicativo(id: number, alternativaIndex: number, fatorMultiplicativo: number) {
        setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
            if (pergunta.id === id) {
                return {
                    ...pergunta,
                    alternativas: pergunta.alternativas.map((alternativa: any, index: number) => {
                        if (index === alternativaIndex) {
                            return {
                                ...alternativa,
                                fator_multiplicativo: fatorMultiplicativo
                            }
                        }
                        return alternativa;
                    })
                }
            }
            return pergunta;
        }));
        updateQueue(id);
    }

    function addAlternativa(id: number) {
        setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
            if (pergunta.id === id) {
                return {
                    ...pergunta,
                    alternativas: [
                        ...pergunta.alternativas,
                        {
                            descricao: 'Nova Alternativa',
                            fator_multiplicativo: 1,
                            comentario: ''
                        }
                    ]
                }
            }
            return pergunta;
        }));
        updateQueue(id);
    }

    function deleteAlternativa(id: number, alternativaIndex: number) {
        setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
            if (pergunta.id === id) {
                if (pergunta.alternativas.length > 1) {
                    return {
                        ...pergunta,
                        alternativas: pergunta.alternativas.filter((alternativa: any, index: number) => index !== alternativaIndex)
                    }
                } else {
                    openNotification("A pergunta deve ter ao menos uma alternativa", 'error');
                    return pergunta;
                }
            }
            return pergunta;
        }));
        updateQueue(id);
    }


    const {mutate: mutateNovaPergunta, isPending: isPendingNovaPergunta} = useMutation({
        mutationFn: serviceNovaPerguntaQuestionario,
        onSuccess: (response: any) => {
            setPerguntas(
                (prevPerguntas: any) => [...prevPerguntas, response.data]
            );
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || 'Erro ao criar pergunta';
            openNotification(message, 'error');
        }
    });

    const {mutate: mutateDeletePergunta, isPending: isPendingDeletePergunta} = useMutation({
        mutationFn: serviceDeletarPerguntaQuestionario,
        onSuccess: (response: any) => {
            openNotification("Pergunta deletada com sucesso", 'success');
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || 'Erro ao deletar pergunta';
            openNotification(message, 'error');
        }
    });

    const {mutate: mutateDuplicatePergunta, isPending: isPendingDuplicatePergunta} = useMutation({
        mutationFn: serviceDuplicarPerguntaQuestionario,
        onSuccess: (response: any) => {
            setPerguntas(
                (prevPerguntas: any) => [...prevPerguntas, response.data]
            );
        },
        onError: (error: any) => {
            const message = error?.response?.data?.message || 'Erro ao duplicar pergunta';
            openNotification(message, 'error');
        }
    });

    function deleteQuestion(id: number) {
        if (perguntas.length === 1) {
            openNotification("O questionário deve ter ao menos uma pergunta", 'error');
            return;
        }
        mutateDeletePergunta({idPergunta: id}, {
            onSuccess: () => {
                setPerguntas(perguntas.filter(pergunta => pergunta.id !== id));
            }
        });
    }

    const pending = isPendingNovaPergunta || isPendingDeletePergunta || isPendingDuplicatePergunta;

    return (
        <>
            <LoadingOverlay active={pending}/>

            <div>
                <div className="justify-self-start font-semibold text-lg bg-white-1 uppercase rounded-xl shadow-xl p-3">
                    Editar/Criar Questões
                </div>

                {
                    perguntas.length === 0 &&
                    <div className="justify-self-center rounded-xl shadow-xl p-8 relative">
                        <div className="placeholder-custom capitalize w-full p-20">
                            <p className="w-fit mt-auto mx-auto font-normal text-sm">Sem questões cadastradas ainda</p>
                            <p className="w-fit mb-auto mx-auto font-semibold text-lg">Adicione uma pergunta</p>
                        </div>
                    </div>
                }

                {
                    perguntas.length > 0 && perguntas.map((pergunta, index) => (
                        <div key={pergunta.id}
                             className={`justify-self-center rounded-xl bg-white-1 shadow-xl p-8 relative flex flex-col gap-2 mt-4 ${editingId === pergunta.id! ? 'border-l-8 border-green-smart_city' : 'cursor-pointer'}`}
                             onClick={() => {
                                 setEditingId(pergunta.id!);
                             }}
                        >
                            {editingId === pergunta.id! ? (
                                <>
                                    <p className="font-semibold text-lg">Pergunta {index + 1}</p>
                                    <input
                                        type="text"
                                        placeholder="Pergunta sem título"
                                        value={pergunta.descricao}
                                        onChange={(e) => changePerguntaDescricao(pergunta.id!, e.target.value)}
                                        className="border-0 border-b w-[55%] bg-blue-light/[0.4]"
                                    />

                                    <input
                                        type="text"
                                        placeholder="Descrição..."
                                        value={pergunta.comentario}
                                        onChange={(e) => changePerguntaComentario(pergunta.id!, e.target.value)}
                                        className="border-0 border-b w-full"
                                    />

                                    <div className="flex flex-col gap-2 ml-6 mt-2">
                                        {pergunta.alternativas.map((alternativa, alternativaIndex) => (
                                            <div key={alternativaIndex} className="flex items-center">
                                                <div className="checkbox-wrapper">
                                                    <div className="round">
                                                        <input
                                                            type="checkbox"
                                                            readOnly={true}
                                                            checked={false}
                                                        />
                                                        <label htmlFor={`checkbox-${alternativaIndex}`}></label>
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Adicionar alternativa"
                                                    value={alternativa.descricao}
                                                    onChange={(e) => changeAlternativaDescricao(pergunta.id!, alternativaIndex, e.target.value)}
                                                    className="border rounded-md w-full ml-2"
                                                    ref={inputRef}
                                                />
                                                <div className="ml-2">
                                                    <input className="w-[4rem]" type="number"
                                                           value={alternativa.fator_multiplicativo}
                                                           onChange={(e) => changeFatorMultiplicativo(pergunta.id!, alternativaIndex, parseInt(e.target.value))}
                                                    />
                                                </div>
                                                <button className="text-gray-5 ml-2"
                                                        onClick={() => deleteAlternativa(pergunta.id!, alternativaIndex)}>
                                                    <FaRegTrashAlt size={30}/></button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <button className="w-auto" onClick={() => addAlternativa(pergunta.id!)}>
                                            <div className="flex items-center ml-6 mt-2">
                                                <GoPlus className="text-[#4F4F4F]" size={30}/>
                                                <div className="text-[#4F4F4F] font-medium text-base">Adicionar opção</div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="border-t border-gray-light_3 w-full flex gap-4 pt-2">
                                        <button className="text-gray-5 ml-auto"
                                                onClick={() => deleteQuestion(pergunta.id!)}><FaRegTrashAlt size={30}/>
                                        </button>
                                        <button className="text-gray-5"
                                                onClick={() => mutateDuplicatePergunta({idPergunta: pergunta.id!})}>
                                            <LuCopy size={30}/></button>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <p className="font-semibold text-lg">Pergunta {index + 1}</p>
                                    <div className="font-semibold text-2xl text-gray-5">{pergunta.descricao}</div>
                                    <div className="font-medium text-lg text-gray-light_3">{pergunta.comentario}</div>
                                    <div className="flex flex-col gap-2">
                                        {pergunta.alternativas.map((alternativa, alternativaIndex) => (
                                            <div key={alternativaIndex} className="flex items-center">
                                                <div className="checkbox-wrapper">
                                                    <div className="round">
                                                        <input type="checkbox"/>
                                                        <label></label>
                                                    </div>
                                                </div>
                                                <div
                                                    className="ml-2 text-base font-medium text-gray-5">{alternativa.descricao}</div>
                                                {/* <div className="ml-auto">
                                                    <div className="w-[2rem] h-[2rem] bg-white-7 text-sm font-medium text-center align-middle">{alternativa.fator_multiplicativo}</div>
                                                </div> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                }

                <div className="w-fit font-bold text-6xl text-green-smart_city border-icon mt-4"
                     onClick={() => {
                         mutateNovaPergunta({idQuestionario: questionario!.id!});
                     }}
                >
                    <BsFillPlusCircleFill/>
                </div>
            </div>
        </>
    );
}
