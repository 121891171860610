import React from "react"
import {useUserContext} from "contexts/UserContext";
import LeftBarUsuario from "./Usuario";
import LeftBarAdmin from "./Admin";

export default function LeftSideBar() {
    const {nivelPermissao, loading} = useUserContext();

    if (loading) return <div>Carregando...</div>;

    if (nivelPermissao! >= 1) {
        return LeftBarAdmin();
    }

    return LeftBarUsuario();
}