import React from 'react';
import {Modal} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useModalContext} from 'contexts/ModalContext';
import {Ativo} from 'interfaces/Ativo';
import {serviceUpdateAtivo} from 'api/AtivoService';

interface ModalEditarAtivoProps {
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    ativo: Ativo;
    refetch: () => void;
}

interface AtivoFormData {
    nome: string;
    descricao: string;
}

export default function ModalEditarAtivo({
                                             modalState: [modalOpen, setModalOpen],
                                             ativo,
                                             refetch
                                         }: ModalEditarAtivoProps) {
    const {register, handleSubmit, reset} = useForm<AtivoFormData>({
        defaultValues: {
            nome: ativo.nome,
            descricao: ativo.descricao
        }
    })

    const {openNotification} = useModalContext();

    async function handleFormSubmit(data: AtivoFormData) {
        try {
            await serviceUpdateAtivo({idAtivo: ativo.id, ...data});
            openNotification('Ativo editado com sucesso!', 'success');
            refetch();
            setModalOpen(false);
        } catch (error) {
            openNotification('Erro ao editar ativo', 'error');
        }
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <form className="bg-white-1 w-[50rem] px-16 h-[20rem] rounded-[1rem]"
                  onSubmit={handleSubmit(handleFormSubmit)}>

                <p className="mt-[2.5%] text-[#132D46] font-raleway text-center font-bold text-lg">
                    Editar Ativo
                </p>

                <div className="mt-[2%] mb-[2%] h-[0.063rem] border-[0.063rem] bg-gray-500"></div>

                <p className="text-[#4F4F4F] font-raleway font-bold text-sm">Nome</p>
                <input
                    className="w-full bg-[#EFF2F7] rounded-[0.625rem]"
                    type="text"
                    {...register('nome', {required: true})}
                />

                <p className="mt-4 text-[#4F4F4F] font-raleway font-bold text-sm">Descrição</p>
                <textarea
                    className="w-full bg-[#EFF2F7] rounded-[0.625rem]"
                    {...register('descricao', {required: false})}
                />

                <button
                    className="bg-[#01C38E] mt-3 rounded-[0.75rem] w-28 text-sm h-10 text-[#FFFFFf]"
                    type="submit"
                >
                    Editar Ativo
                </button>

            </form>
        </Modal>
    )

}
    