import React, {useState} from "react";
import 'chart.js/auto';
import './styles.css';
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {atenEixoToDataset} from "utils/AtenEixoToDataset";
import {useTimeAgo} from 'next-timeago';
import {LargeChart, SmallChart} from "./ChartOptions";
import {Popover, PopoverContent, PopoverTrigger} from './Popover';
import {Radar} from "react-chartjs-2";

function formatDate(date: string) {
    const originalDate = new Date(date);

    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1;
    return `${day}/${month < 10 ? '0' : ''}${month}`;
}

export default function CardRadar({resposta}: { resposta: UsuarioRespondeQuestionario }) {
    const [hover, setHover] = useState(false);
    const {TimeAgo} = useTimeAgo();

    const ultimaAtualizacao = resposta.data_resposta;
    const resultados = resposta.resultados;


    return (
        <div className={`card-container overflow-visible shadow-xl`}>
            <Popover placement="right">
                <PopoverTrigger>
                    <Radar
                        className="w-[15rem]"
                        data={atenEixoToDataset({
                            graficos: [{
                                label: resposta!.questionario!.nome!,
                                resultado: resultados,
                                backgroundColor: "rgba(1, 195, 142, 0.5)",
                                borderColor: "rgba(1, 195, 142, 1)",
                                fill: false
                            }]
                        })}
                        options={SmallChart}
                    />
                </PopoverTrigger>

                <PopoverContent className="Popover shadow-2xl">
                    <Radar
                        data={atenEixoToDataset({
                            graficos: [{
                                label: resposta!.questionario!.nome!,
                                resultado: resultados,
                                backgroundColor: "rgba(1, 195, 142, 0.5)",
                                borderColor: "rgba(1, 195, 142, 1)",
                                fill: false
                            }]
                        })}
                        options={LargeChart}
                    />
                </PopoverContent>

            </Popover>


            <h1 className="card-title text-center">{resposta.questionario!.nome!}</h1>
            <h1 className="card-title text-center my-2">{resposta.usuario.organizacao.nome}</h1>
            <p className="card-date">{formatDate(ultimaAtualizacao as string)}</p>
            <p className="card-desc"> aberto <TimeAgo date={ultimaAtualizacao as string} locale='pt-br'/>
            </p>
        </div>
    );
};

