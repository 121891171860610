import {Ativo} from "interfaces/Ativo";
import {IoDocumentTextSharp} from "react-icons/io5";
import {OpenAtivo} from "utils/OpenNewWindowApi";

export default function CardsMaisAcessados({arquivo}: { arquivo: Ativo }) {
    return (
        <div key={arquivo.id}
             className="flex flex-col w-1/6 h-64 rounded-xl mx-2 border hover:shadow-lg cursor-pointer overflow-hidden"
             onClick={async () => {
                 try {
                     OpenAtivo(arquivo.id)
                 } catch (error) {
                 }
             }}
        >
            <div className="bg-gray-light h-52 flex items-center justify-center rounded-t-xl">
                <IoDocumentTextSharp className="text-8xl font-bold text-green-smart_city"/>
            </div>
            <div
                className="bg-white-1 h-12 p-4 truncate overflow-hidden whitespace-nowrap rounded-b-xl text-blue-login font-bold text-base max-w-full">
                {arquivo.nome}
            </div>
        </div>
    );
}
