import React, {useState} from 'react';
import {BubbleQuestionario, TemplateLeftBar} from "components";
import PaginacaoQuestionarios from 'components/FluxoProgresso';
import LoadingScreen from 'components/LoadingScreen';
import GraficosResultados from './components/GraficosResultados';
import DiagnosticoComentado from './components/DiagnosticoComentado';
import {useParams} from "react-router-dom";
import {useGetRespostaQuestionario} from "../../hooks/UseGetRespostasQuestionario";


export function RespostasIndividuaisUsuario() {

    // const {usuarioRespondeQuestionario, loading} = useQuestionarioContext();
    const {id} = useParams();
    const {resposta} = useGetRespostaQuestionario(Number(id));
    const [estadoAtual, setEstadoAtual] = useState<number>(1);


    console.log('[Respostas Individuais Usuario] usuarioRespondeQuestionario:', resposta)

    if (!resposta) {
        return (
            <TemplateLeftBar>
                <LoadingScreen/>
            </TemplateLeftBar>
        );
    }

    return (
        <TemplateLeftBar>
            <>
                <BubbleQuestionario
                    texto={resposta.questionario.nome!}
                />

                <div className='w-full bg-[#132D46] rounded-xl mt-4'>
                    <p className='text-center text-[1.5rem] text-[white] font-bold font-raleway py-3'>
                        Resultados
                    </p>
                </div>

                <div className='flex mt-6 justify-between w-full'>
                    <div className="w-4/5 bg-[white] p-8 rounded-xl">
                        {
                            estadoAtual === 1 ? <GraficosResultados resultado={resposta.resultados}/>
                                : estadoAtual === 2 ?
                                    <DiagnosticoComentado resposta={resposta}/>
                                    : estadoAtual === 3 ? null
                                        : null
                        }

                        <div className="mt-12">
                            <button
                                className={`bg-[#01C38E] text-white-1 px-8 py-2 rounded-lg ${(estadoAtual === 1) ? "hidden" : ""}`}
                                onClick={() => setEstadoAtual(estadoAtual - 1)} disabled={estadoAtual === 1}
                            >
                                Anterior
                            </button>
                            <button
                                className={`float-right text-white-1 px-8 py-2 rounded-lg bg-[#01C38E] ${(estadoAtual === 2) ? "hidden" : ""}`}
                                onClick={() => setEstadoAtual(estadoAtual + 1)}
                            >
                                Próximo
                            </button>
                        </div>
                    </div>

                    <PaginacaoQuestionarios
                        estados={[
                            {
                                id: 1,
                                mensagem: 'Gráficos e resultados'
                            },
                            {
                                id: 2,
                                mensagem: 'Diagnóstico comentado'
                            },
                            {
                                id: 3,
                                mensagem: 'Ir para framework'
                            }
                        ]}
                        estadoAtual={estadoAtual}
                    />
                </div>
            </>
        </TemplateLeftBar>
    );
}
