import {createColumnHelper} from '@tanstack/react-table';
import Modal from '@mui/material/Modal';
import {UsuarioRespondeQuestionario} from 'interfaces/UsuarioRespondeQuestionario';
import {getRespostaQuestionario} from 'api/RespostasService';
import {SimpleQuestionario} from 'interfaces/Questionario';
import useGetManualTableFilters from 'hooks/UseGetManualTableFilters';
import {useGetRespostas} from 'hooks/UseGetRespostasQuestionario';
import ManualTable from 'components/ManualTable';
import ManualPaginationArrows from 'components/ManualTable/components/ManualPaginationArrows';

export default function ModalSelecionarResposta(
    {
        questionario,
        setResposta,
        state: [open, setOpen]
    }:
        {
            questionario: SimpleQuestionario,
            setResposta: (resposta: UsuarioRespondeQuestionario) => void,
            state: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
        }
) {

    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters(8);
    const {respostas, pagination} = useGetRespostas({
        page,
        pageSize,
        filters: {
            ...filters,
            questionario__id: questionario.id!.toString()
        },
        ordering
    });

    const columnHelper = createColumnHelper<UsuarioRespondeQuestionario>();
    const columns = [
        columnHelper.accessor(
            'data_resposta',
            {
                header: 'Data',
                cell: (info: any) => (
                    new Date(info.row.original.data_resposta).toLocaleDateString()
                ),
                size: 1,
                enableColumnFilter: false,
            },
        ),
        columnHelper.accessor(
            'usuario.nome_completo',
            {
                header: 'Nome',
                cell: info => <>{info.row.original.usuario.nome_completo}</>,
                size: 400,
                meta: {
                    filterKey: 'usuario__nome_completo__icontains',
                    sortingKey: 'usuario__nome_completo',
                }
            }
        ),
        columnHelper.accessor(
            'usuario.organizacao.nome',
            {
                header: 'Instituição',
                cell: info => <>{info.row.original.usuario.organizacao.nome}</>,
                size: 400,
                meta: {
                    filterKey: 'usuario__organizacao__nome__icontains',
                    sortingKey: 'usuario__organizacao__nome',
                }
            }
        ),
        columnHelper.accessor(
            'comentario',
            {
                header: 'Comentado?',
                cell: info => (
                    <>
                        {info.row.original.comentario ? 'Sim' : 'Não'}
                    </>
                ),
                size: 400,
                enableColumnFilter: false
            }
        ),
    ];

    async function onRowClick(row: any) {
        try {
            const response = await getRespostaQuestionario({idResposta: row.id});
            setResposta(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className="bg-white-1 w-[90rem] h-[50rem] px-[10rem] rounded-sm">
                <p className="mt-[2.5%] font-raleway font-bold text-xs">
                    RESULTADOS ENCONTRADOS
                </p>

                <div className="mt-[2%] mb-[2%] h-px border-[0.063rem] bg-gray-500"></div>

                <ManualTable
                    className="w-full m-auto"
                    columns={columns}
                    rowOnClick={onRowClick}
                    data={respostas}
                    filterState={[filters, setFilters]}
                    ordering={[ordering, setOrdering]}
                />

                <ManualPaginationArrows
                    currentPageState={[page, setPage]}
                    lastPage={Math.ceil(pagination.count / pageSize)}
                />
            </div>
        </Modal>
    );
};

