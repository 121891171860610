import {AtendimentoEixo} from "../interfaces/Eixo";

export function atenEixoToDataset({graficos}: {
    graficos: {
        label: string,
        resultado: AtendimentoEixo[],
        backgroundColor: string
        borderColor: string
        fill: boolean
    }[]
}): {
    labels: string[],
    datasets: {
        label: string,
        data: number[],
        backgroundColor: string[]
        borderColor: string[]
        fill: boolean
    }[]
} {

    const labels = graficos[0].resultado.map(r => r.nome);

    const datasets = graficos.map(g => {
        const data = g.resultado.map(r => r.resultado);
        const backgroundColor = g.resultado.map(r => g.backgroundColor);
        const borderColor = g.resultado.map(r => g.backgroundColor);
        const fill = g.fill;
        return {
            label: g.label,
            data,
            backgroundColor,
            borderColor,
            fill
        }
    });


    return {
        labels,
        datasets
    }


}