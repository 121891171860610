import {useState} from "react";
import {AiOutlineSelect} from "react-icons/ai";

interface SearchSelectProps {
    column: [columnValue: any, setColumnValue: Function],
    keyValue: string,
    options?: { value: string, label: string }[]
}

export default function SearchSelect({
                                         column: [columnValue, setColumnValue],
                                         keyValue,
                                         options = []
                                     }: SearchSelectProps) {
    const [showSelect, setShowSelect] = useState(false);
    const columnFilterValue = columnValue[keyValue] ?? '';

    return (
        <div
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowSelect(false);
                }
            }}
            tabIndex={0}
            className="relative inline-block"
        >
            <button
                onClick={() => setShowSelect(!showSelect)}
                type="button"
                className="select-none cursor-pointer"
            >
                <AiOutlineSelect/>
            </button>

            {showSelect && (
                <select
                    className="absolute top-full left-0 mt-1 border-[1px] border-[#132D46] p-1 rounded-lg text-[#132D46] bg-white"
                    onChange={(e) => {
                        setColumnValue({...columnValue, [keyValue]: e.target.value});
                        setShowSelect(false);
                    }}
                    value={columnFilterValue}
                >
                    <option value={''}>
                        <AiOutlineSelect className="inline-block mr-2"/> Selecione
                    </option>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            )}
        </div>
    );
}
