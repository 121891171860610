import React from 'react';

interface CaixaComTextoENumeroProps {
    numero: number;
    texto: string;
}

export default function CaixaComTextoENumero({numero, texto}: CaixaComTextoENumeroProps) {
    return (
        <div
            className="flex flex-col items-center justify-center text-blue-smart_city rounded-lg p-4 w-[12.75rem] h-[9.1875rem] text-center  shadow-2xl">
            <h1 className="text-4xl font-bold font-montserrat">{numero}</h1>
            <p className="text-base font-bold font-raleway">{texto}</p>
        </div>
    );
};

