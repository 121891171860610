import React, {useEffect, useRef} from 'react';
import "./styles.css";
import Chart, {ChartConfiguration} from 'chart.js/auto';

interface DoughnutChartProps {
    data: {
        total: number;
        situacao: string;
    }[];
}

export default function PizzaStatusQuestionarios({data}: DoughnutChartProps) {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart<'doughnut'> | null>(null);

    const labels = data.map((item) => item.situacao);
    const values = data.map((item) => item.total);

    // Custom colors for the chart based on the status
    const bgColor = ['#147AD6', '#F43232', '#01C38E', '#FF8E2B', '#A9A9A9'];

    useEffect(() => {
        if (chartRef.current) {
            if (chartInstance.current) {
                // Destroy the previous chart instance
                chartInstance.current.destroy();
            }

            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                // Create a new chart instance
                chartInstance.current = new Chart(ctx, {
                    type: 'doughnut',
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                data: values,
                                backgroundColor: bgColor,
                                borderWidth: 0,
                            },
                        ],
                    },
                    options: {
                        plugins: {
                            title: {
                                display: false,
                            },
                            legend: {
                                display: false,
                            },
                        },
                        cutout: '70%',
                        aspectRatio: 1,
                        maintainAspectRatio: false,
                        responsive: true,
                    },
                } as ChartConfiguration<'doughnut'>);
            }
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);

    return (
        <div className="flex flex-col md:flex-row w-full h-full items-center bg-[#132D46] p-6 rounded-xl">
            {/* Graph container */}
            <div className="w-full md:w-[40%] flex items-center justify-center">
                <canvas ref={chartRef} className="w-[80%] h-[80%]"/>
            </div>

            {/* Title and Legend organized horizontally */}
            <div className="w-full md:w-[60%] flex flex-col justify-center items-start pl-4">
                <p className="text-2xl font-bold chart-title mb-4">Relação do Status dos Questionários</p>
                <ul className="flex flex-wrap space-x-4 space-y-2">
                    {data.map((item, index) => (
                        <li key={index} className="flex items-center space-x-2">
                            <span
                                className="w-4 h-4 inline-block"
                                style={{backgroundColor: bgColor[index]}}
                            ></span>
                            <span className="chart-title text-lg">{item.situacao}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
