import {createColumnHelper} from '@tanstack/react-table';
import Modal from '@mui/material/Modal';
import {Eixo} from 'interfaces/Eixo';
import useGetManualTableFilters from 'hooks/UseGetManualTableFilters';
import {useGetEixos} from 'hooks/UseGetEixos';
import ManualTable from 'components/ManualTable';
import ManualPaginationArrows from 'components/ManualTable/components/ManualPaginationArrows';

export default function ModalSelecionarEixos(
    {
        modalState: [open, setOpen],
        eixosState: [eixosQuestionario, setEixosQuestionario]
    }:
        {
            modalState: [boolean, (open: boolean) => void],
            eixosState: [Eixo[], (eixos: Eixo[]) => void]
        }
) {

    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters(8);
    const {eixos, pagination, refetch} = useGetEixos({page, pageSize, filters, ordering});

    const columnHelper = createColumnHelper<Eixo>();
    const columns = [
        columnHelper.display(
            {
                id: 'checkbox',
                header: '',
                cell: info => (
                    <input
                        type="checkbox"
                        className="w-5 h-5"
                        readOnly={true}
                        checked={eixosQuestionario.some((eixo) => eixo.id === info.row.original.id)}
                    />
                ),
            }
        ),
        columnHelper.accessor(
            'nome',
            {
                header: 'Eixo',
                cell: info => (
                    <button
                        className="w-full text-left px-4"
                    >
                        {info.row.original.nome}
                    </button>
                ),
            }
        ),
        columnHelper.accessor(
            'descricao',
            {
                header: 'Descrição',
                cell: info => (
                    <div>
                        {info.row.original.descricao}
                    </div>
                ),
            }
        ),
    ];

    function rowOnClick(row: any) {
        if (!eixosQuestionario.some((eixo) => eixo.id === row.id)) {
            setEixosQuestionario([...eixosQuestionario, row]);
            setOpen(false);
        }
    }


    return (
        <Modal
            className="flex justify-center items-center"
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className="bg-white-1 w-[90rem] px-14 h-[50rem] rounded-[1rem]">
                <p className="mt-[2.5%] font-raleway font-bold text-xs">
                    RESULTADOS ENCONTRADOS
                </p>

                <div className="mt-[2%] mb-[2%] h-[0.063rem] border-[0.063rem] bg-gray-500"></div>

                <ManualTable
                    className="w-full m-auto mb-6 rounded-lg shadow-lg overflow-hidden"
                    columns={columns}
                    data={eixos}
                    rowOnClick={rowOnClick}
                    filterState={[filters, setFilters]}
                    ordering={[ordering, setOrdering]}
                />

                <ManualPaginationArrows
                    currentPageState={[page, setPage]}
                    lastPage={Math.ceil(pagination.count / pageSize)}
                />
            </div>

        </Modal>
    );
};

