import {InputHTMLAttributes, useEffect, useState} from "react"

type CalendarInputProps = {
    value: string
    onChange: (value: string) => void
    debounce?: number
} & InputHTMLAttributes<HTMLInputElement>

export default function CalendarInput(
    {
        value: initialValue,
        onChange,
        debounce = 50,
        ...props
    }: CalendarInputProps) {

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input type="date" {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}