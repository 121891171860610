import axios from "axios";
import {serviceRefreshToken} from "./AuthService";

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const fileApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'multipart/form-data;',
    },
});

api.interceptors.request.use(authInterceptor);
fileApi.interceptors.request.use(authInterceptor);

function authInterceptor(config: { headers: any }) {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}

function saveTokens({accessToken, refreshToken}: { accessToken: string, refreshToken: string }) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
}

function clearTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}


api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const refreshTokenValue = localStorage.getItem('refreshToken');

        if (error.response.status === 401 && refreshTokenValue && !originalRequest._retry) {
            originalRequest._retry = true;
            clearTokens();

            try {
                const response = await serviceRefreshToken({token: refreshTokenValue});
                const accessToken = response.data.access;
                const refreshToken = response.data.refresh;

                saveTokens({accessToken, refreshToken});

                originalRequest.headers.Authorization = `${accessToken}`;

                return axios(originalRequest);
            } catch (err) {
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    },
);