import Usuario from 'interfaces/Usuario';
import React from 'react';

interface InformacoesUsuarioProps {
    usuario: Usuario
}

export default function InformacoesUsuario({usuario}: InformacoesUsuarioProps) {


    return (
        <div className="flex items-center w-[45.5rem] h-[9.1875rem] bg-blue-smart_city rounded-lg justify-center">
            {
                usuario.foto_url ?
                    <img src={process.env.REACT_APP_API_URL + usuario.foto_url}
                         className="w-[5.5625rem] h-[5.5625rem] rounded-full mx-[2.125rem]"/>

                    :
                    <div className="w-[5.5625rem] h-[5.5625rem] rounded-full mx-[2.125rem] bg-green-1"></div>
            }
            <div className="mr-auto font-raleway">
                <h2 className="text-2xl font-bold text-white-1">Olá, {usuario.nome_completo}!</h2>
            </div>
        </div>
    );
}
