import {Modal} from "@mui/material";
import {serviceCriarQuestionario} from "api/QuestionarioService";
import {useQuestionarioContext} from "contexts/QuestionarioContext";
import {GoPencil} from "react-icons/go";
import {TfiReload} from "react-icons/tfi";
import {useNavigate} from "react-router-dom";

export default function ModalCriarQuestionario
(
    {
        modalState: [modalOpen, setModalOpen],
        modalDupState: [modalDupOpen, setModalDupOpen],
    }:
        {
            modalState: [boolean, (modalOpen: boolean) => void],
            modalDupState: [boolean, (modalDupOpen: boolean) => void],
        }
) {
    const {updateQuestionario} = useQuestionarioContext();
    const navigate = useNavigate();

    async function handleNovoQuestionario() {
        try {
            const response = await serviceCriarQuestionario();
            updateQuestionario(response.data);
            navigate('/administrar-questionario/' + response.data.id);
        } catch (error) {
            console.log(error);
        }
    }

    function handleCriarAPartirDeExistente() {
        setModalOpen(false);
        setModalDupOpen(true);
    }

    return (
        <>
            {
                modalOpen && (
                    <Modal
                        className="flex justify-center items-center"
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                    >
                        <div
                            className="bg-white-1 w-[40rem] h-[21.25rem] gap-12 px-12 flex items-center justify-evenly rounded-lg outline-none">
                            <div
                                className="grid grid-rows-3 h-2/3 w-2/3 rounded-[1rem] place-content-center font-raleway border p-1 cursor-pointer"
                                onClick={handleNovoQuestionario}
                            >
                                <GoPencil className="text-5xl mx-auto mt-auto text-[#00bc8f]"/>
                                <div className="text-[#132D46] text-2xl font-bold text-center mt-2">
                                    Novo <br/> Questionário
                                </div>
                                <div className="text-[#132D46] text-base font-normal text-center mt-4">
                                    Começar a partir do zero
                                </div>
                            </div>

                            <div
                                className="grid grid-rows-3 rounded-[1rem] h-2/3 w-2/3 place-content-center font-raleway border p-1 cursor-pointer"
                                onClick={handleCriarAPartirDeExistente}
                            >
                                <TfiReload className="text-5xl mx-auto mt-auto text-[#00bc8f]"/>
                                <div className="text-[#132D46] text-2xl font-bold text-center mt-2">
                                    Criar a partir de <br/> existente
                                </div>
                                <div className="text-[#132D46] text-base font-normal text-center mt-4">
                                    Personalize um modelo
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </>
    );
}
