import {Modal} from "@mui/material";
import {createColumnHelper} from "@tanstack/react-table";
import {serviceDuplicarQuestionario} from "api/QuestionarioService";
import ManualTable from "components/ManualTable";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import {useGetQuestionarios} from "hooks/UseGetQuestionarios";
import {SimpleQuestionario} from "interfaces/Questionario";
import {useNavigate} from "react-router-dom";

export default function ModalCriarExistente
(
    {
        modalState: [modalOpen, setModalOpen],
    }:
        {
            modalState: [boolean, (modalOpen: boolean) => void],
        }
) {
    const navigate = useNavigate();

    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters(8);
    const {questionarios, pagination, refetch} = useGetQuestionarios({page, pageSize, filters, ordering});


    async function handleNovoQuestionario({idQuestionario}: { idQuestionario: number }) {
        try {
            const response = await serviceDuplicarQuestionario({idQuestionario});
            navigate('/administrar-questionario/' + response.data.id);
            setModalOpen(false);

        } catch (error) {
            console.log(error);
        }
    }

    const columnHelper = createColumnHelper<SimpleQuestionario>();
    const columns = [
        columnHelper.accessor(
            'nome',
            {
                header: 'Nome',
                cell: info => (
                    <button
                        className="w-full text-left px-4"
                    >
                        {info.row.original.nome}
                    </button>
                ),
            }
        ),
        columnHelper.display({
                id: 'descricao',
                header: 'Descrição',
                cell: info => (
                    <div>
                        {info.row.original.descricao}
                    </div>
                ),
            }
        ),
    ];

    async function rowOnClick(row: any) {
        const response = await handleNovoQuestionario({idQuestionario: row.id});
    }


    return (
        <>
            {
                modalOpen && (
                    <Modal
                        className="flex justify-center items-center"
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                    >
                        <div className="bg-white-1 w-[90rem] px-14 h-[50rem] rounded-[1rem]">
                            <p className="mt-[2.5%] font-raleway font-bold text-xs">
                                QUESTIONÁRIOS DISPONÍVEIS
                            </p>

                            <div className="mt-[2%] mb-[2%] h-[0.063rem] border-[0.063rem] bg-gray-500"></div>


                            <ManualTable
                                className="w-full m-auto"
                                columns={columns}
                                data={questionarios}
                                filterState={[filters, setFilters]}
                                ordering={[ordering, setOrdering]}
                                rowOnClick={rowOnClick}
                            />

                            <ManualPaginationArrows
                                currentPageState={[page, setPage]}
                                lastPage={Math.ceil(pagination.count / pageSize)}
                            />
                        </div>
                    </Modal>
                )}
        </>
    )
}