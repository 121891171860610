const baseUrl = process.env.REACT_APP_API_URL;


export function OpenNewWindow(url: string) {
    const accessToken = localStorage.getItem('accessToken');

    window.open(`${baseUrl}${url}`, '_blank');
}

export function OpenAtivo(id: number) {
    window.open(`${baseUrl}/ativos/${id}`, '_blank');
}

export function BaixarAtivo(id: number) {
    window.open(`${baseUrl}/ativos/baixar-ativo/${id}`, '_blank');
}

export async function GerarRelatorio(id: number) {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${baseUrl}/questionario/gerar-relatorio/${id}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch the report');
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');
    } catch (error) {
        console.error('Error generating report:', error);
    }
}
