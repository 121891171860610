import React, {createContext, useContext, useEffect, useState} from "react";

import {SimpleQuestionario} from "../interfaces/Questionario";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";

type QuestionarioContextType = {
    questionario: SimpleQuestionario | null;
    usuarioRespondeQuestionario: UsuarioRespondeQuestionario | null;
    idQuestionario: number | null;
    idRespostas: number[];

    loading: boolean;
    updateQuestionario: (questionario: SimpleQuestionario) => void;
    updateUsuarioRespondeQuestionario: (usuarioRespondeQuestionario: UsuarioRespondeQuestionario) => void;
    updateIdQuestionario: (id: number) => void;
    updateIdRespostas: (ids: number[]) => void;

};

const QuestionarioContext = createContext<QuestionarioContextType>({} as QuestionarioContextType);

export const useQuestionarioContext = () => useContext(QuestionarioContext);

export function QuestionarioProvider({children}: { children: React.ReactNode }) {
    const [questionario, setQuestionario] = useState<SimpleQuestionario | null>(null);
    const [usuarioRespondeQuestionario, setUsuarioRespondeQuestionario] = useState<UsuarioRespondeQuestionario | null>(null);
    const [idQuestionario, setIdQuestionario] = useState<number | null>(null);
    const [idRespostas, setIdRespostas] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const questionario = localStorage.getItem("questionario");

        if (questionario !== null && questionario !== undefined) {
            setQuestionario(JSON.parse(questionario));
        }

        const idQuestionario = localStorage.getItem("idQuestionario");
        if (idQuestionario !== null && idQuestionario !== undefined) {
            setIdQuestionario(parseInt(idQuestionario));
        }

        const idRespostas = localStorage.getItem("idRespostas");
        if (idRespostas !== null && idRespostas !== undefined) {
            setIdRespostas(JSON.parse(idRespostas));
        }

        const usuarioRespondeQuestionario = localStorage.getItem("usuarioRespondeQuestionario");
        if (usuarioRespondeQuestionario !== null && usuarioRespondeQuestionario !== undefined) {
            setUsuarioRespondeQuestionario(JSON.parse(usuarioRespondeQuestionario));
        }

        setLoading(false);
    }, []);

    function updateQuestionario(questionario: SimpleQuestionario) {
        localStorage.setItem("questionario", JSON.stringify(questionario));
        setQuestionario(questionario);
    }

    function updateIdQuestionario(id: number) {
        localStorage.setItem("idQuestionario", JSON.stringify(id));
        setIdQuestionario(id);
    }

    function updateIdRespostas(ids: number[]) {
        localStorage.setItem("idRespostas", JSON.stringify(ids));
        setIdRespostas(ids);
    }

    function updateUsuarioRespondeQuestionario(usuarioRespondeQuestionario: UsuarioRespondeQuestionario) {
        localStorage.setItem("usuarioRespondeQuestionario", JSON.stringify(usuarioRespondeQuestionario));
        setUsuarioRespondeQuestionario(usuarioRespondeQuestionario);
    }


    return (
        <QuestionarioContext.Provider
            value={
                {
                    loading,
                    questionario, updateQuestionario,
                    idQuestionario, updateIdQuestionario,
                    idRespostas, updateIdRespostas,
                    usuarioRespondeQuestionario, updateUsuarioRespondeQuestionario

                }
            }>

            {children}

        </QuestionarioContext.Provider>
    )
}