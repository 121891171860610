import {useEffect, useState} from "react";
import Usuario from "../interfaces/Usuario";
import {PaginatedResponse, Pagination} from "../interfaces/Pagination";
import {useQuery} from "@tanstack/react-query";
import {getUsuarios} from "../api/UsuarioService";

interface UseGetUsuarioProps {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export function useGetUsuarios({page = 1, pageSize = 10, filters, ordering}: UseGetUsuarioProps) {
    const [usuarios, setUsuarios] = useState<Usuario[]>([]);
    const [pagination, setPagination] = useState<Pagination>({count: 0});
    const [debouncedParams, setDebouncedParams] = useState({page, pageSize, filters, ordering});

    const {data, refetch, error} = useQuery<PaginatedResponse<Usuario>>({
        queryKey: ['usuarios', debouncedParams.page, debouncedParams.pageSize, debouncedParams.filters, debouncedParams.ordering],
        queryFn: async () => {
            const response = await getUsuarios({
                page: debouncedParams.page,
                pageSize: debouncedParams.pageSize,
                filters: debouncedParams.filters,
                ordering: debouncedParams.ordering,
            });
            return response.data;
        },
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedParams({page, pageSize, filters, ordering});
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page, pageSize, filters, ordering]);

    useEffect(() => {
        if (data) {
            setUsuarios(data.results);
            setPagination({
                ...data
            });
        }
    }, [data]);

    return {usuarios, pagination, refetch, error};
}