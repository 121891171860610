import React, {useState} from "react";
import {FaAngleRight, FaRegCircle} from "react-icons/fa";
import {AiOutlineWindows} from "react-icons/ai";
import {HiOutlineLightBulb} from "react-icons/hi";
import {CiChat1} from "react-icons/ci";
import {IoSettingsOutline} from "react-icons/io5";
import {RxExit} from "react-icons/rx";
import {useUserContext} from "contexts/UserContext";
import {useNavigate} from "react-router-dom";
import {FaRegFolderClosed} from "react-icons/fa6";

export default function LeftBarUsuario() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    const navigate = useNavigate();

    const {logout} = useUserContext();

    return (
        <div
            className={`w-[19rem] min-h-[58rem] h-full shadow-2xl rounded-2xl font-raleway ${sidebarCollapsed ? 'w-[6rem]' : ''}`}>

            <ul className={`p-4 flex flex-col justify-between text-blue-smart_city min-h-[50rem] h-full bg-white-1 rounded-xl w-[18.56rem] fixed ${sidebarCollapsed ? 'w-[6rem]' : ''}`}>

                <li className="mt-14 mb-12 flex items-center justify-center">
                    <img className='' src={sidebarCollapsed ? require('../../../assets/images/icone-smartcity.png') : require('../../../assets/images/logo-smartcity.png')} alt="logo" />
                </li>

                <li className="mx-auto mb-12 w-8 h-6 flex" onClick={toggleSidebar}>
                    <p className="text-sm font-bold w-full text-center bg-gray-light rounded-2xl">
                        {sidebarCollapsed ? '\u003E\u003E' : '\u003C\u003C'}
                    </p>
                </li>

                <button
                    onClick={() => navigate('/dashboard')}
                    className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city"
                >
                    <AiOutlineWindows
                        className={`text-3xl ${sidebarCollapsed ? 'mx-auto text-2xl' : 'mr-4'}`}
                    />
                    <p className={`font-bold text-[1.12rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                        Dashboard
                    </p>
                </button>

                <li className="mb-4">
                    <button
                        onClick={() => navigate('/como-funciona')}
                        className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                    >
                        <HiOutlineLightBulb
                            className={`text-3xl ${sidebarCollapsed ? 'mx-auto text-2xl' : 'mr-4'}`}
                        />
                        <p className={`font-bold text-[1.12rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                            Como Funciona
                        </p>
                    </button>
                </li>

                <li className="mb-4">
                    <span className="flex p-[0.5em] justify-start">
                        <CiChat1
                            className={`text-3xl stroke-1 ${sidebarCollapsed ? 'mx-auto text-2xl' : 'mr-4'}`}
                        />
                        <p className={`font-bold text-[1.12rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                            Questionários
                        </p>
                    </span>

                    <ul className="ml-4">
                        <li>
                            <button
                                onClick={() => navigate('/questionarios-disponiveis')}
                                className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                            >
                                {sidebarCollapsed ? (
                                    <FaRegCircle className="w-[0.8em] h-[0.8em] my-auto mr-4"/>
                                ) : (
                                    <FaAngleRight className="w-[1em] h-[1em] my-auto mr-4"/>
                                )}
                                <p className={`font-bold text-[1rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                                    Questionários Disponíveis
                                </p>
                            </button>
                        </li>

                        <li>
                            <button
                                onClick={() => navigate('/meus-diagnosticos')}
                                className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                            >
                                {sidebarCollapsed ? (
                                    <FaRegCircle className="w-[0.8em] h-[0.8em] my-auto mr-4"/>
                                ) : (
                                    <FaAngleRight className="w-[1em] h-[1em] my-auto mr-4"/>
                                )}
                                <p className={`font-bold text-[1rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                                    Meus Diagnósticos
                                </p>
                            </button>
                        </li>

                        <li>
                            <button
                                onClick={() => navigate('/selecionar-questionario')}
                                className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                            >
                                {sidebarCollapsed ? (
                                    <FaRegCircle className="w-[0.8em] h-[0.8em] my-auto mr-4"/>
                                ) : (
                                    <FaAngleRight className="w-[1em] h-[1em] my-auto mr-4"/>
                                )}
                                <p className={`font-bold text-[1rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                                    Comparar
                                </p>
                            </button>
                        </li>
                    </ul>
                </li>

                <li className="mb-4">
                    <button
                        onClick={() => navigate('/biblioteca-ativos')}
                        className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                    >
                        <FaRegFolderClosed
                            className={`text-3xl ${sidebarCollapsed ? 'mx-auto text-2xl' : 'mr-4'}`}
                        />
                        <p className={`font-bold text-[1.12rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                            Biblioteca de ativos
                        </p>
                    </button>
                </li>

                <li className="mb-4">
                    <button
                        onClick={() => navigate('/configuracoes')}
                        className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                    >
                        <IoSettingsOutline
                            className={`text-3xl ${sidebarCollapsed ? 'mx-auto text-2xl' : 'mr-4'}`}
                        />
                        <p className={`font-bold text-[1.12rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                            Configurações
                        </p>
                    </button>
                </li>

                <li className="mt-auto mb-10">
                    <button
                        onClick={logout}
                        className="flex p-[0.5em] justify-start rounded-md hover:text-white-1 hover:bg-blue-smart_city w-full"
                    >
                        <RxExit className={`w-[1.5em] h-[1.5em] ${sidebarCollapsed ? 'mx-auto text-2xl' : 'mr-4'}`}/>
                        <p className={`font-bold text-[1.12rem] ${sidebarCollapsed ? 'hidden' : ''}`}>
                            Sair
                        </p>
                    </button>
                </li>
            </ul>
        </div>
    );
}
