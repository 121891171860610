export default function SectionSobre() {
    return (
        <div className='flex flex-col text-blue-login items-center justify-center p-24 bg-[#D9D9D9] gap-16'>

            <div className='text-5xl font-extrabold'>
                Sobre o projeto
            </div>

            <div className='text-2xl font-medium px-[30rem]'>
                “A plataforma GAIA foi criada na intenção de facilitar avaliações de logísticas internas de instituições
                públicas e privadas, através da criação de questionários.”
            </div>

            <div className='text-3xl font-extrabold'>
                Cidades que participam
            </div>

            <div className='flex gap-10'>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <img src={require('assets/images/landing/apucarana.jpg')}
                         className='h-64 bg-green-smart_city rounded-t-xl'></img>
                    <div
                        className='h-16 bg-white-1 text-lg font-bold text-blue-login rounded-b-xl flex items-center pl-8'>
                        Apucarana
                    </div>
                </div>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <img src={require('assets/images/landing/arapongas.jpg')}
                         className='h-64 bg-green-smart_city rounded-t-xl'></img>
                    <div
                        className='h-16 bg-white-1 text-lg font-bold text-blue-login rounded-b-xl flex items-center pl-8'>
                        Arapongas
                    </div>
                </div>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <img src={require('assets/images/landing/cambe.jpg')}
                         className='h-64 bg-green-smart_city rounded-t-xl'></img>
                    <div
                        className='h-16 bg-white-1 text-lg font-bold text-blue-login rounded-b-xl flex items-center pl-8'>
                        Cambé
                    </div>
                </div>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <img src={require('assets/images/landing/ibipora.jpg')}
                         className='h-64 bg-green-smart_city rounded-t-xl'></img>
                    <div
                        className='h-16 bg-white-1 text-lg font-bold text-blue-login rounded-b-xl flex items-center pl-8'>
                        Ibiporã
                    </div>
                </div>

            </div>

        </div>
    )
}