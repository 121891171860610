import {useEffect, useState} from "react";
import {useQuestionarioContext} from "../../../../contexts/QuestionarioContext";
import {getRespostaQuestionario} from "../../../../api/RespostasService";
import {TabelaTaxasServico} from "./components";
import {Header} from "components/Headers/HeaderFelipe";
import {TemplateLeftBar} from "components";
import Barras from "components/Graficos/Barras";
import {atenEixoToDataset} from "utils/AtenEixoToDataset";
import Radar from "components/Graficos/Radar";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import LoadingScreen from "components/LoadingScreen";
import {getCor, getCorReduzida} from "utils/Cores";

export default function CompararRespostas() {

    const {idRespostas, questionario} = useQuestionarioContext();

    const [respostas, setRespostas] = useState<UsuarioRespondeQuestionario[]>([]);
    console.log('[Respostas Individuais Usuario] - respostas', respostas);

    const [barrasOrRadar, setBarrasOrRadar] = useState('barras');

    useEffect(() => {
        async function fetchRespostas() {
            try {
                const responses = await Promise.all(idRespostas.map(async (idResposta) => {
                    return await getRespostaQuestionario({idResposta: idResposta});
                }));

                setRespostas(responses.map((response: any) => response.data));
            } catch (error) {
                console.log('Erro ao buscar respostas', error)
            }
        }

        fetchRespostas();
    }, [idRespostas])


    if (respostas.length === 0) {
        return (
            <TemplateLeftBar>
                <LoadingScreen/>
            </TemplateLeftBar>
        )
    }

    return (
        <TemplateLeftBar>
            <div className="px-10 relative flex justify-center w-[90%]">
                <div className={"w-full"}>
                    <Header fluxo={[
                        {nome: 'Questionários', path: '/meus-questionarios'},
                        {nome: 'Comparar', path: '/comparar-respostas'},
                    ]}/>

                    <p className="font-bold text-2xl text-[#132D46]">
                        Comparação dos resultados selecionados
                    </p>

                    <div className="flex items-center h-16 bg-[#D9D9D9] bg-opacity-20 rounded-r-[1.25rem] mt-5">
                        <div className="w-2 bg-[#01C38E] h-[100%] rounded-l-[1.25rem]"></div>
                        <p className="font-bold text-[#132D46] text-base ml-4">Questionário:</p>
                        {questionario && (
                            <p className="text-[#132D46] text-base ml-2">{questionario.nome}</p>
                        )}
                        <p className="font-bold text-[#132D46] text-base ml-4">Descrição:</p>
                        {questionario && (
                            <p className="text-[#132D46] text-base ml-2">{questionario.descricao}</p>
                        )}
                    </div>

                    <div className="mt-5">
                        <button
                            className={`${barrasOrRadar === 'barras' ? 'bg-[#01C38E] text-white-1' : 'bg-white-1 text-[#132D46]'} px-4 py-2 rounded-l`}
                            onClick={() => setBarrasOrRadar('barras')}
                        >
                            Gráfico de Barras
                        </button>
                        <button
                            className={`${barrasOrRadar === 'radar' ? 'bg-[#01C38E] text-white-1' : 'bg-white-1 text-[#132D46]'} px-4 py-2 rounded-r`}
                            onClick={() => setBarrasOrRadar('radar')}
                        >
                            Gráfico de Radar
                        </button>

                    </div>

                    <div className=" bg-white-1 rounded-[1.25rem] p-12 mt-5">
                        {
                            (respostas.length > 0) &&
                            <>

                                <div className={`${barrasOrRadar === 'barras' ? '' : 'h-[500px]'}`}>
                                    {
                                        barrasOrRadar === 'barras' ? (
                                                <Barras
                                                    data={atenEixoToDataset({
                                                        graficos:
                                                            respostas.map((resposta, index) => ({
                                                                label: resposta.usuario.organizacao.nome,
                                                                resultado: resposta.resultados,
                                                                backgroundColor: getCorReduzida(index),
                                                                borderColor: getCor(index),
                                                                fill: false
                                                            }))
                                                    })}
                                                />
                                            ) :
                                            (
                                                <Radar
                                                    data={atenEixoToDataset({
                                                        graficos:
                                                            respostas.map((resposta, index) => ({
                                                                label: resposta.usuario.organizacao.nome,
                                                                resultado: resposta.resultados,
                                                                backgroundColor: getCorReduzida(index),
                                                                borderColor: getCor(index),
                                                                fill: false
                                                            }))
                                                    })}
                                                    op={2}
                                                />
                                            )
                                    }
                                </div>

                                <TabelaTaxasServico respostas={respostas}/>
                            </>

                        }

                    </div>
                </div>
            </div>

        </TemplateLeftBar>
    )
}