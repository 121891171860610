import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {GerarRelatorio} from "utils/OpenNewWindowApi";

export default function DiagnosticoComentado(
    {
        resposta,
    }:
        {
            resposta: UsuarioRespondeQuestionario;
        }) {
    return (
        <>
            <p className='text-[1.5rem] text-[#132D46] font-bold font-raleway py-3'>
                Diágnostico Comentado
            </p>
            <p className='text-[#132D46] text-[1.125rem] font-raleway'>
                {resposta.comentario ? resposta.comentario : 'Nenhum comentário disponível'}
            </p>

            <button className=" bg-blue-smart_city text-white-1 rounded-lg p-4 text-lg mt-12"
                    onClick={async () => await GerarRelatorio(resposta.id!)}
            >
                Baixar relatório do Questionário
            </button>


        </>
    );
}
