import Questionario from 'interfaces/Questionario';
import React from 'react';

export default function NomeDescricaoForm
(
    {
        questionarioState: [questionario, setQuestionario]
    }:
        {
            questionarioState: [Questionario, (questionario: Questionario) => void]
        }
) {

    return (
        <div className="bg-[#FFFFFF] rounded-[0.75rem] shadow-[0.75rem] p-5 relative">
            <div className="mb-3 font-bold text-base text-[#4F4F4F]">NOME E DESCRIÇÃO</div>
            <div className="mb-3">
                <label htmlFor="nome" className="mb-2 font-bold text-sm text-[#4F4F4F]">
                    Nome
                </label>
                <div className="relative mt-2 rounded-[0.5rem] shadow-[0.875rem]">
                    <input
                        type="text"
                        id="nome"
                        name="nome"
                        onChange={(e) => setQuestionario({...questionario, nome: e.target.value})}
                        value={questionario.nome}
                        className="py-[0.125rem] px-1 w-full h-8 text-sm rounded-[0.5rem] border-[#D9D9D9]"
                    />
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="descricao" className="mb-1 font-bold text-xs text-[#4F4F4F]">
                    Descrição
                </label>
                <div className="relative mt-2 rounded-[0.5rem] shadow-[0.875rem]">
                    <textarea
                        id="descricao"
                        name="descricao"
                        value={questionario.descricao}
                        onChange={(e) => setQuestionario({...questionario, descricao: e.target.value})}
                        rows={4}
                        className="form-input py-[0.125rem] px-1 block w-full text-sm leading-5 rounded-[0.5rem] transition duration-150 ease-in-out border-[#D9D9D9]"
                    />
                </div>
            </div>
        </div>
    );
};


