import {createColumnHelper} from "@tanstack/react-table";
import {SimpleQuestionario} from "interfaces/Questionario";

export default function ColunasMeusQuestionarios() {
    const columnHelper = createColumnHelper<SimpleQuestionario>();


    return [
        columnHelper.display(
            {
                id: "nome",
                header: "Questionário",
                cell: info => (
                    <>
                        {info.row.original.nome}
                    </>
                ),
            },
        ),
        columnHelper.display(
            {
                id: 'qtd_respostas',
                header: "Nº de Respostas",
                cell: info => (
                    <>
                        {info.row.original.qtd_respostas}
                    </>
                ),
            }
        ),
        columnHelper.display(
            {
                id: "data_ultima_resposta",
                header: "Data Última Resposta",
                cell: info => (
                    <>
                        {
                            info.row.original.data_ultima_resposta ?
                                new Date(info.row.original.data_ultima_resposta).toLocaleDateString()
                                : "Nunca respondido"
                        }
                    </>
                ),
            }
        ),

    ]
}