import {FcDocument} from "@react-icons/all-files/fc/FcDocument";

import oGerenciamentoDe from 'assets/images/landing/o-gerenciamento-de.pdf';
import aModelFor from 'assets/images/landing/a-model-for.pdf';
import aPerformance from 'assets/images/landing/a-performance.pdf';

function CardArtigo({ title, link }: { title: string; link: string }) {
    return (
        <div className='flex flex-col w-80 rounded-xl shadow-xl'>
            <div className='flex-1 bg-blue-login text-lg font-bold text-white-1 p-4 overflow-hidden'>
                <p className='h-full truncate'>{title}</p>
            </div>
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className='h-64 bg-white-1 rounded-b-xl flex justify-center items-center'
            >
                <FcDocument size={128} />
            </a>
        </div>
    );
}



export default function SectionArtigos() {
    return (
        <div className='flex flex-col text-blue-login items-center justify-center p-24 bg-[#CED8E2] gap-16'>

            <div className='text-5xl font-extrabold'>
                Artigos
            </div>

            <div className='text-4xl font-extrabold'>
                Conheça nossos outros artigos
            </div>

            <div className='flex gap-10 text-center'>

                <CardArtigo
                    title='O GERENCIAMENTO DE INFRAESTRUTURA BASEADO EM ITIL 4 COMO PRÁTICA AUXILIAR NO PROCESSO DE GESTÃO DE TI DE PREFEITURAS MUNICIPAIS'
                    link={oGerenciamentoDe}
                />
                <CardArtigo
                    title='A MODEL FOR IMPLEMENTING THE ITIL 4 EVENT MANAGEMENT AND MONITORING PRACTICE IN A PUBLIC ORGANIZATION'
                    link={aModelFor}
                />
                <CardArtigo
                    title='A PERFORMANCE AND CAPACITY MANAGEMENT MODEL BASED ON ITIL V4 AS A TOOL FOR IMPROVING INFORMATION AND COMMUNICATION TECHNOLOGY MANAGEMENT IN MUNICIPAL GOVERNMENTS'
                    link={aPerformance}
                />


            </div>

        </div>
    )
}