import {api} from "./api";

export async function serviceLogin({nome, password}: { nome: string, password: string }) {
    const response = await api.post('/auth/get-token', {login: nome, password: password});
    return response;
}

export async function serviceRefreshToken({token}: { token: string }) {
    const response = await api.post('/auth/refresh-token', {refresh: token});
    return response;
}

