import {TemplateLeftBar} from "components";
import {Header} from "components/Headers/HeaderFelipe";
import ColunasUsuarios from "./components/ColunasUsuarios";
import {useEffect, useState} from "react";
import {Ativo} from "interfaces/Ativo";
import TabelaHover from "components/Tables/TabelaHover";
import CardsMaisAcessados from "./components/CardAcesso";
import {serviceGetAtivos} from "api/AtivoService";

export default function BibliotecaDeAtivosUsuarios() {
    const [arquivos, setArquivos] = useState<Ativo[]>([]);

    useEffect(() => {
        const fetchArquivos = async () => {
            try {
                const response = await serviceGetAtivos();
                setArquivos(response.data.data);
            } catch (error) {
                console.error("Erro ao buscar arquivos:", error);
            }
        };

        fetchArquivos();
    }, []);

    return (
        <TemplateLeftBar>
            {/*<Header fluxo={['Biblioteca de Ativos']}/>*/}
            <Header fluxo={[
                {nome: 'Biblioteca de Ativos', path: '/biblioteca-ativos'}
            ]}/>

            <div className='text-blue-login font-bold text-2xl my-8'>Mais acessados</div>
            <div className="flex bg-white-1 p-6 gap-8 rounded-xl shadow-xl overflow-hidden flex-wrap">
                {
                    arquivos.slice(0, 5).map((arquivo) => (
                        <CardsMaisAcessados arquivo={arquivo}/>
                    ))
                }
            </div>

            <div className='text-blue-login font-bold text-2xl my-8'>Todos os arquivos</div>
            <TabelaHover
                columns={ColunasUsuarios()}
                data={arquivos}
            />
        </TemplateLeftBar>
    )
}