import {SimpleQuestionario} from 'interfaces/Questionario';
import React, {useEffect, useState} from 'react';
import {FaCircle} from 'react-icons/fa';
import {IoIosArrowDown, IoIosArrowForward} from 'react-icons/io';

export default function ConfigQuestionarioForm
(
    {
        questionarioState: [questionario, setQuestionario],
    }:
        {
            questionarioState: [SimpleQuestionario, (questionario: SimpleQuestionario) => void],
        }
) {
    const [expanded, setExpanded] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Ativo");

    const toggleOptions = () => {
        setExpanded(!expanded);
    };

    const handleOptionClick = (option: string) => {
        setQuestionario({
            ...questionario,
            situacao: option === "Ativo" ? 1 : (option === "Inativo" ? 2 : 3)
        });
        setExpanded(false);
    };

    useEffect(() => {
        setSelectedOption(questionario.situacao === 1 ? "Ativo" : (questionario.situacao === 2 ? "Inativo" : "Em construção"));
    }, [questionario]);

    return (
        <div className="bg-[#FFFFFF] rounded-[0.75rem] shadow-[0.75rem] p-5 relative">
            <div className="mb-3 font-bold text-xl text-[#4F4F4F] uppercase">Configurar questionário</div>
            <div className="mb-3 font-bold text-xl text-[#4F4F4F]">Status</div>
            <div className="relative">
                <div
                    className={`w-48 h-10 bg-[#6E6E6E]/[0.2] border border-[#6E6E6E] rounded text-center content-center flex justify-between items-center ${expanded ? 'rounded-b-none border-b-0' : ''}`}
                    onClick={toggleOptions}>
                    <div className="flex items-center ml-2 text-[#4F4F4F]">
                        <FaCircle className="mr-2"
                                  color={selectedOption === "Ativo" ? "#01C38E" : (selectedOption === "Inativo" ? "#F43232" : "#FF8E2B")}/>
                        {selectedOption}
                    </div>
                    {expanded ? <IoIosArrowDown className="mr-2"/> : <IoIosArrowForward className="mr-2"/>}
                </div>
                {expanded && (
                    <div
                        className="top-full mt-0 bg-[#6E6E6E]/[0.2] border-[#6E6E6E] border-b border-l border-r rounded-b shadow-lg w-48">
                        {["Ativo", "Inativo", "Em construção"].filter(option => option !== selectedOption).map(option => (
                            <div key={option}
                                 className="cursor-pointer p-2 hover:bg-gray-100 hover:rounded-b flex text-center content-center text-gray-5"
                                 onClick={() => handleOptionClick(option)}>
                                <FaCircle className="mr-2"
                                          color={option === "Ativo" ? "#01C38E" : (option === "Inativo" ? "#F43232" : "#FF8E2B")}/>
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
