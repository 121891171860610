import React from 'react';
import {Modal} from '@mui/material';
import {SubmitHandler, useForm} from 'react-hook-form';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {FiUpload} from "react-icons/fi";
import {IoDocumentTextSharp} from "react-icons/io5";
import {useModalContext} from 'contexts/ModalContext';
import {serviceCriarAtivo} from 'api/AtivoService';

interface ModalNovoAtivoProps {
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    refetch: () => void;
}

interface CustomFile extends File {
    path?: string;
}

interface FormData {
    descricao: string;
}

const ModalNovoAtivo: React.FC<ModalNovoAtivoProps> = ({
                                                           modalState: [modalOpen, setModalOpen],
                                                           refetch,
                                                       }) => {

    const {register, handleSubmit, reset} = useForm<FormData>();
    const {openNotification} = useModalContext();
    const [file, setFile] = React.useState<any | null>(null);

    const onDrop = (acceptedFiles: CustomFile[], fileRejections: FileRejection[], event: DropEvent) => {
        const file = acceptedFiles[0];
        setFile({
            file: file,
            path: file.path,
            name: file.name
        });
    };

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true
    });

    const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
        if (!file) {
            openNotification("Selecione um arquivo", "error");
            return;
        }

        const descricao = data.descricao;
        try {
            const response = await serviceCriarAtivo({nome: file!.name, descricao, arquivo: file.file!});
            setModalOpen(false);
            refetch()
            openNotification("Arquivo enviado com sucesso", "success");
        } catch (error) {
            console.log(error);
            setModalOpen(false);
            openNotification("Erro ao enviar arquivo", "error");
        }

        reset();
        setFile(null);
    };

    const handleClose = () => {
        setModalOpen(false);
        setFile(null);
    };

    return (
        <>
            <Modal
                className="flex justify-center items-center"
                open={modalOpen}
                onClose={handleClose}
            >
                <form className="bg-white-1 w-[70rem] px-16 h-auto mx-auto rounded p-4"
                      onSubmit={handleSubmit(handleFormSubmit)}>

                    <p className="mt-[2.5%] text-blue-login text-start font-bold text-2xl">
                        Novo Ativo
                    </p>

                    <div className="mt-[2%] mb-[2%] h-1 border bg-gray-500"></div>

                    <p className='font-bold text-sm text-[#4B4B4B]'>Arquivo</p>

                    <div
                        {...getRootProps({className: 'dropzone bg-[#F1F1F1] h-80 border-[#D4D4D4] border-2 border-dashed flex justify-center items-center rounded-[0.625rem] p-4'})}
                        onClick={open}
                    >
                        <input {...getInputProps()} />
                        {!file ? (
                            <div className='flex flex-col items-center'>
                                <FiUpload className='text-8xl text-green-smart_city'/>
                                <p className='text-2xl font-bold text-blue-login'>Carregar Arquivo</p>
                            </div>
                        ) : (
                            <div className='flex flex-col items-center'>
                                <IoDocumentTextSharp className='text-8xl text-green-smart_city'/>
                                <p className='text-xl font-bold text-blue-login mt-2'>{file.name}</p>
                                <button
                                    type="button"
                                    className='text-sm text-blue-login underline mt-2'
                                >
                                    Escolher outro arquivo
                                </button>
                            </div>
                        )}
                    </div>

                    <p className="mt-4 text-[#4F4F4F] font-raleway font-bold text-sm">Descrição</p>
                    <textarea
                        className="w-full bg-[#EFF2F7] opacity-30 rounded-[0.625rem]"
                        {...register('descricao', {required: false})}
                    />

                    <div className="flex justify-center gap-8 mt-4">
                        <button
                            className="bg-[#D9D9D9] rounded w-28 font-bold text-base h-10 text-[#4B4B4B]"
                            type="button"
                            onClick={handleClose}
                        >
                            Cancelar
                        </button>
                        <button
                            className="bg-[#01C38E] rounded w-28 font-bold text-base h-10 text-[#FFFFFF]"
                            type="submit"
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default ModalNovoAtivo;
