import FluxoProgresso from "components/FluxoProgresso";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {useEffect, useState} from "react";
import RespostasIndividuais from "./components/RespostasIndividuais";
import EstatisticasQuestionario from "./components/EstatisticasQuestionario";
import ComentarQuestionario from "./components/ComentarQuestionario";
import {SimpleQuestionario} from "interfaces/Questionario";


export default function Resultados({questionario}: { questionario: SimpleQuestionario }) {

    const [estadoAtual, setEstadoAtual] = useState(1);
    const [resposta, setResposta] = useState<UsuarioRespondeQuestionario | null>(null);
    const [comentario, setComentario] = useState('');

    useEffect(() => {
        if (resposta) {
            setComentario(resposta.comentario || '');
        }
    }, [resposta])

    useEffect(() => {
        if (estadoAtual === 1) {
            setResposta(null)
        }
    }, [estadoAtual])

    return (
        <>
            <div className="w-full flex justify-between">
                <div className="w-[80%]">

                    <div className="flex items-center h-auto bg-[#D9D9D9] bg-opacity-20 rounded-r-[1.25rem] mt-5 mb-5">
                        <div className="w-2 bg-[#01C38E] h-auto rounded-l-[1.25rem]"></div>
                        <div
                            className='flex flex-col gap-2 border-l-4 rounded-tl-lg rounded-bl-lg border-green-smart_city'>
                            <div className='flex flex-nowrap'>
                                <p className="font-bold text-[#132D46] text-base ml-4">Questionário:</p>
                                <p className="font-medium text-[#132D46] text-base ml-2">{questionario.nome}</p>
                            </div>

                            <div className='flex flex-nowrap'>
                                <p className="font-bold text-[#132D46] text-base ml-4">Descrição:</p>
                                <p className="font-medium text-[#132D46] text-base ml-2">{questionario.descricao}</p>
                            </div>
                        </div>
                    </div>

                    {
                        estadoAtual === 1 ? <EstatisticasQuestionario questionario={questionario}/>
                            : estadoAtual === 2 ?
                                <RespostasIndividuais questionario={questionario}
                                                      respostaState={[resposta, setResposta]}/>
                                : estadoAtual === 3 ?
                                    <ComentarQuestionario resposta={resposta!}
                                                          comentarioState={[comentario, setComentario]}/>
                                    : null
                    }
                </div>


                <FluxoProgresso
                    estados={[
                        {id: 1, mensagem: "Estatísticas do questionário"},
                        {id: 2, mensagem: "Respostas individuais"},
                        {id: 3, mensagem: "Diagnóstico comentado"},
                    ]}

                    estadoAtual={estadoAtual}

                />

            </div>

            <div className="w-[80%] mt-6">
                <button
                    className={`bg-primary text-black px-4 py-2 rounded-lg bg-white-1 drop-shadow-xl ${(estadoAtual === 1) ? "hidden" : ""}`}
                    onClick={() => estadoAtual === 2 && resposta ? setResposta(null)
                        : setEstadoAtual(estadoAtual - 1)}
                >
                    Anterior
                </button>
                <button
                    className={`float-right bg-primary text-white-1 px-8 py-2 rounded-lg bg-[#01C38E] drop-shadow-xl ${(estadoAtual === 2 && !resposta || estadoAtual === 3) ? "hidden" : ""}`}
                    onClick={() => setEstadoAtual(estadoAtual + 1)}>
                    {
                        (estadoAtual === 2 && resposta) ? "Realizar diagnóstico comentado" : "Próximo"
                    }
                </button>
            </div>
        </>
    )
}