import Radar from "components/Graficos/Radar";
import {AtendimentoEixo} from "interfaces/Eixo";
import {atenEixoToDataset} from "utils/AtenEixoToDataset";
import TabelaComparativo from "./TabelaComparativo";
import {RgbaCores, RgbaCoresReduzidas} from "utils/Cores";

export default function ComparativoTab
({
     resposta,
     mediaQuestionario
 }: {
    resposta: AtendimentoEixo[],
    mediaQuestionario: AtendimentoEixo[],
}) {
    return (
        <div className="items-center justify-left flex">
            <div className="w-[40%] mr-8 rounded-3xl shadow-xl p-2">
                <Radar
                    data={atenEixoToDataset({
                        graficos: [
                            {
                                label: "Resultado Obtido",
                                resultado: resposta,
                                backgroundColor: RgbaCoresReduzidas.VerdeRgba,
                                borderColor: RgbaCores.VerdeRgba,
                                fill: false
                            },
                            {
                                label: "Média do Questionário",
                                resultado: mediaQuestionario,
                                backgroundColor: RgbaCoresReduzidas.AzulRgba,
                                borderColor: RgbaCores.AzulRgba,
                                fill: false
                            }
                        ]
                    })}
                    op={2}
                />
            </div>
            <div className="w-[40%]">
                <div className="mb-4 font-raleway font-bold text-[1rem] text-gray-5 ">
                    Taxa de atendimento por eixo
                </div>

                <TabelaComparativo resultados={resposta} mediaQuestionario={mediaQuestionario}/>

            </div>
        </div>
    );
}
