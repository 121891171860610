import {Modal} from "@mui/material"
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

export default function ModalAlterarSenha({
                                              setValue,
                                              modalState: [open, setOpen],
                                          }:
                                              {
                                                  setValue: any,
                                                  modalState: [boolean, (open: boolean) => void]
                                              }) {
    const {register} = useForm();

    const [senha, setSenha] = useState('');
    const [senhaConfirmacao, setSenhaConfirmacao] = useState('');

    useEffect(() => {
        setSenha('');
        setSenhaConfirmacao('');
    }, [open]);

    return (
        <Modal
            open={open}
            className="w-[40rem] h-[24rem] m-auto"
            onClose={() => setOpen(false)}
        >
            <div className="w-full h-full bg-white-1 rounded-2xl p-8">
                <p className="font-bold font-raleway text-2xl text-black-2">
                    Editar Senha
                </p>

                <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                    Nova senha
                </p>
                <input type="password"
                       className="w-[100%] bg-[#DCDCDC] p-2 rounded-lg mt-2"
                       onChange={(e) => {
                           setSenha(e.target.value)
                       }}
                />

                <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                    Confirmar nova senha
                </p>
                <input type="password"
                       className="w-[100%] bg-[#DCDCDC] p-2 rounded-lg mt-2"
                       onChange={(e) => {
                           setSenhaConfirmacao(e.target.value)
                       }}
                />

                <div className="flex items-center justify-center gap-4 mt-12">
                    <button className="bg-gray-100 w-[10rem] text-black-2 font-bold rounded-lg p-2 mt-4"
                            onClick={() => {
                                setOpen(false)
                            }}
                    >
                        Cancelar
                    </button>

                    <button className="bg-green-1 w-[10rem] text-white-1 font-bold rounded-lg p-2 mt-4"
                            type="submit" form="formDados"
                            onClick={() => {
                                if (senha === senhaConfirmacao) {
                                    setValue('senha', senha)
                                }
                            }}
                    >
                        Salvar
                    </button>
                </div>

            </div>
        </Modal>
    )
}