import React, {useState} from "react";
import {deleteUsuario} from "api/UsuarioService";
import Usuario from "interfaces/Usuario";
import {CellContext, createColumnHelper} from "@tanstack/react-table";
import {TfiTrash} from "react-icons/tfi";
import {useNavigate} from "react-router-dom";
import {useModalContext} from "contexts/ModalContext";
import {GoPencil} from "react-icons/go";
import {useGetUsuarios} from "hooks/UseGetUsuarios";
import ManualTable from "components/ManualTable";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";

export default function TabelaUsuarios() {

    const {openNotification, openConfirmationModal} = useModalContext()
    const navigate = useNavigate();

    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const {usuarios, pagination, refetch} = useGetUsuarios({page, pageSize, filters, ordering});

    async function handleDeleteUsuario(id: number) {
        try {
            await deleteUsuario({idUsuario: id});

            refetch();

            openNotification("Usuário excluído com sucesso!", "success");
        } catch (error: any) {
            const message = error.response?.data?.message || "Erro ao excluir usuário!";
            openNotification(message, "error");
        }
    }

    const confirmarExclusaoUsuario = (id: number) => {
        openConfirmationModal("Deseja realmente excluir este usuário?", "warning", () => handleDeleteUsuario(id));
    };

    const columnHelper = createColumnHelper<Usuario>();
    const columns = [
        columnHelper.accessor('login', {
            header: 'Nome',
            cell: (info: CellContext<Usuario, string>) => (<>{info.getValue()}</>),
            meta: {
                filterKey: 'login__icontains',
            }
        }),
        columnHelper.accessor('email', {
            header: 'Email',
            cell: (info: CellContext<Usuario, string>) => (<>{info.getValue()}</>),
            meta: {
                filterKey: 'email__icontains',
            }
        }),
        columnHelper.accessor('organizacao.nome', {
            header: 'Organização',
            cell: (info: CellContext<Usuario, string>) => (<>{info.getValue()}</>),
            meta: {
                filterKey: 'organizacao__nome__icontains',
                sortingKey: 'organizacao__nome',
            }
        }),
        columnHelper.accessor('cidade', {
            header: 'Cidade',
            cell: (info: CellContext<Usuario, string>) => (<>{info.getValue()}</>),
            meta: {
                filterKey: 'cidade__icontains',
            }
        }),
        {
            id: 'options',
            header: '',
            cell: (info: CellContext<Usuario, unknown>) => (
                <div className="ml-auto  flex space-x-4">
                    <GoPencil
                        className="text-green-smart_city cursor-pointer ml-auto font-medium text-2xl"
                        onClick={() => {
                            navigate(`/editar-usuario/${info.row.original.id}`);
                        }}
                    />
                    <TfiTrash
                        className="text-red-to_do cursor-pointer ml-auto font-medium text-2xl"
                        onClick={() => confirmarExclusaoUsuario(info.row.original.id!)}
                    />
                </div>
            ),
        }
    ];


    return (
        <>
            <div className={'m-auto pt-[0.75rem] text-[0.75rem]'}>

                <ManualTable
                    className="w-full m-auto mb-12"
                    columns={columns}
                    data={usuarios}
                    filterState={[filters, setFilters]}
                    ordering={[ordering, setOrdering]}
                />

                <ManualPaginationArrows
                    currentPageState={[page, setPage]}
                    lastPage={Math.ceil(pagination.count / pageSize)}
                />

            </div>
        </>
    );
}
