import React from "react";
import PizzaStatusQuestionarios from "./components/PizzaStatusQuestionarios";
import {IoIosArrowForward, IoMdAddCircleOutline} from "react-icons/io";
import {PiChatBold} from "react-icons/pi";
import {useNavigate} from "react-router-dom";
import {serviceCriarQuestionario} from "api/QuestionarioService";
import ColunasUltimasRespostas from "./components/ColunasUltimasRespostas";
import {TemplateLeftBar} from "components";
import ColunasMeusQuestionarios from "./components/ColunasMeusQuestionarios";
import {RightSidebar} from "components/Bars/RightBar";
import CardRadar from "pages/Dashboard/Admin/components/CardRadar";
import {useUserContext} from "contexts/UserContext";
import LoadingScreen from "components/LoadingScreen";
import {useGetEstatisticasQuestionariosUsuario} from "hooks/UseGetEstatisticasUsuario";
import {useGetQuestionarios} from "hooks/UseGetQuestionarios";
import {useGetRespostas} from "hooks/UseGetRespostasQuestionario";
import ManualTable from "components/ManualTable";

export default function Dashboard() {
    const navigate = useNavigate();

    const {id} = useUserContext();
    const {estatisticasUsuario} = useGetEstatisticasQuestionariosUsuario(id!);
    const {questionarios} = useGetQuestionarios({
        pageSize: 5,
        filters: {'usuario__id': `${id}`, 'data_ultima_resposta__isnull': 'false'},
        ordering: '-data_ultima_resposta'
    });
    const {respostas} = useGetRespostas({pageSize: 5, ordering: '-data_resposta'});

    function onRowClickMeusQuestionarios(row: any) {
        navigate('/administrar-questionario/' + row.id);
    }

    function onRowClickUltimasRespostas(row: any) {
        navigate('/administrar-questionario/' + row.questionario.id);
    }

    async function handleNovoQuestionario() {
        try {
            const response = await serviceCriarQuestionario();
            navigate('/administrar-questionario/' + response.data.id);
        } catch (error) {
            console.log(error);
        }
    }

    if (!estatisticasUsuario) {
        return <LoadingScreen/>;
    }

    return (
        <TemplateLeftBar>
            <div className="flex justify-between w-full">
                <div className="w-[70%] mx-auto bg-white-6">
                    <div className="flex justify-between px-3 gap-4 mt-12">
                        <div className="w-[60%] rounded-2xl bg-blue-smart_city p-2">
                            <PizzaStatusQuestionarios data={estatisticasUsuario?.status || []}/>
                        </div>

                        <button onClick={() => handleNovoQuestionario()}
                                className="w-[20%] aspect-square bg-green-1 rounded-2xl flex flex-col justify-center items-center font-raleway">
                            <IoMdAddCircleOutline className="text-6xl text-white-1"/>
                            <div className="text-white-1 text-xl text-center">Novo <br/> Questionário</div>
                        </button>

                        <button onClick={() => navigate("/selecionar-questionario")}
                                className="w-[20%] aspect-square bg-green-1 rounded-2xl flex flex-col justify-center items-center font-raleway">
                            <PiChatBold className="text-6xl text-white-1"/>
                            <div className="text-white-1 text-xl text-center">Comparar <br/> Resultados</div>
                        </button>
                    </div>

                    <div className="mt-10">
                        {respostas.length > 0 && (
                            <>
                                <p className="font-bold font-raleway text-2xl text-[#132D46]">
                                    Últimos Diagnósticos
                                </p>
                                <div className="flex justify-between mt-4">
                                    {respostas.map((resposta, index) => {
                                        if (index >= 4) return null;
                                        return (
                                            <CardRadar resposta={resposta} key={index}/>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>

                    <div className="w-full mt-10">
                        <p className="font-bold font-raleway text-2xl text-[#132D46]">
                            Últimas Respostas
                        </p>
                        <ManualTable
                            className="mt-4 rounded-lg shadow-lg overflow-hidden w-full"
                            data={respostas}
                            columns={ColunasUltimasRespostas()}
                            rowOnClick={onRowClickUltimasRespostas}
                        />
                    </div>

                    <div className="w-full mt-10">
                        <p className="flex font-bold font-raleway text-2xl text-[#132D46] items-center cursor-pointer hover:shadow-lg w-fit"
                           onClick={() => navigate("/meus-questionarios")}
                        >
                            <IoIosArrowForward/> Meus Questionários
                        </p>
                        <ManualTable
                            className="mt-4 rounded-lg shadow-lg overflow-hidden w-full px-6 py-2"
                            data={questionarios}
                            columns={ColunasMeusQuestionarios()}
                            rowOnClick={onRowClickMeusQuestionarios}
                        />
                    </div>
                </div>

                <div className="w-[24rem]">
                    <RightSidebar estatisticasUsuario={estatisticasUsuario}/>
                </div>
            </div>
        </TemplateLeftBar>
    );
}
