import React, {useState} from "react";
import {CellContext, createColumnHelper} from "@tanstack/react-table";
import {FaCircle} from "react-icons/fa";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import {SimpleQuestionario} from "interfaces/Questionario";

interface ToggleButtonProps {
    expanded: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({expanded, onClick}) => (
    <button onClick={onClick} className="mr-2 font-medium text-lg cursor-pointer p-4">
        {expanded ? <IoIosArrowDown/> : <IoIosArrowForward/>}
    </button>
);

interface DescriptionCellProps {
    descricao: string;
    expanded: boolean;
}

const DescriptionCell: React.FC<DescriptionCellProps> = ({descricao, expanded}) => (
    <div className={`text-gray-500 font-medium text-sm ${expanded ? "" : "line-clamp-1"}`}>
        {descricao}
    </div>
);

export default function SimpleQuestionarioRows(showSituacao: boolean = false) {
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

    const handleToggle = (rowId: string) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };

    const columnHelper = createColumnHelper<SimpleQuestionario>();

    const columns = [
        columnHelper.accessor('id', {
            header: '',
            cell: (info) => (
                <ToggleButton
                    expanded={expandedRows[String(info.row.original.id)] || false}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleToggle(String(info.row.original.id));
                    }}
                />
            ),
            enableColumnFilter: false,
            enableSorting: false,
        }),
        columnHelper.accessor('nome', {
            header: 'Questionário',
            cell: (info) => (
                <div>
                    <div className="font-medium">{info.getValue()}</div>
                    <DescriptionCell
                        descricao={info.row.original.descricao || ''}
                        expanded={expandedRows[String(info.row.original.id)] || false}
                    />
                </div>
            ),
            meta: {
                filterKey: 'nome__icontains',
            },
        }),
        columnHelper.accessor('qtd_respostas', {
            header: 'Nº de Respostas',
            cell: (info: CellContext<SimpleQuestionario, number>) => <>{info.getValue()}</>,
            enableColumnFilter: false,
            enableSorting: false,
        }),
        columnHelper.accessor('data_criacao', {
            header: 'Criado em',
            cell: (info: CellContext<SimpleQuestionario, string>) => (
                <>{new Date(info.getValue()).toLocaleDateString()}</>
            ),
            enableColumnFilter: false,
        }),
        columnHelper.accessor('data_ultima_resposta', {
            header: 'Última resposta',
            cell: (info: CellContext<SimpleQuestionario, string>) => (
                <>
                    {
                        info.getValue() === null ? 'Nunca' :
                            new Date(info.getValue()).toLocaleDateString()
                    }
                </>
            ),
            enableColumnFilter: false,
            enableSorting: true,
        }),
        ...(showSituacao ? [
            columnHelper.accessor('situacao', {
                header: 'Status',
                cell: (info: CellContext<SimpleQuestionario, number>) => (
                    <span className="flex items-center">
                        <FaCircle
                            className={`w-[0.812rem] h-[0.812rem] mr-2
                            ${info.getValue() === 1 ? 'text-green-500' :
                                info.getValue() === 2 ? 'text-red-500' :
                                    info.getValue() === 3 ? 'text-yellow-500' : 'text-gray-500'
                            }`}
                        />
                        {
                            info.getValue() === 1 ? 'Ativo' :
                                info.getValue() === 2 ? 'Inativo' :
                                    info.getValue() === 3 ? 'Em Construção'
                                        : 'Desconhecido'
                        }
                    </span>
                ),
                enableColumnFilter: false,
            }),
        ] : []),
    ];

    return columns;
}
