import {HashLink as Link} from "react-router-hash-link";

export default function SectionFramework() {
    return (
        <div className='flex w-auto h-auto py-24 pl-24 bg-[#CED8E2]'>

            <div className='w-2/5 flex flex-col justify-center items-center gap-4 p-24'>

                <div className='text-4xl font-extrabold text-blue-login text-center'>Framework Cidades Inteligentes
                </div>

                {/* <div className='text-2xl font-medium text-blue-login'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                </div> */}

                <div className='flex items-center justify-start font-bold text-2xl text-white-1 w-full'>
                    <Link className='bg-green-smart_city rounded-lg px-[0.5em] py-[0.5em]' to={'/'}>Acessar framework
                        cidades inteligentes</Link>
                </div>
            </div>

            <div className='w-3/5'>
                <img className='w-full h-full overflow-hidden'
                     src={require('../../../assets/images/homeImages/image-page-04.png')} alt=""/>
            </div>

        </div>
    )
}