import {Bar as BarChart} from 'react-chartjs-2';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip} from 'chart.js';

ChartJS.register(BarElement, LinearScale, CategoryScale, Tooltip, Legend);

const chartOptions = {
    scales: {
        y: {
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
                stepSize: 20
            },
        },
        x: {
            grid: {
                display: false
            }
        }
    }
};

export default function Barras({data}: any) {
    return (
        <BarChart
            data={data}
            options={chartOptions}
        />
    )
}