export default function Pagination({table}: { table: any }) {
    return (
        <div className="flex justify-center text-sm font-medium">
            <button
                onClick={() => table.firstPage()}
                className="bg-white-1 text-white-1 p-[0.5rem] m-[0.75rem] rounded-[0.25rem]"
            >
                <p className="text-gray-500 px-[0.125rem]">
                    Primeiro
                </p>
            </button>

            <button
                onClick={() => table.previousPage()}
                className="bg-white-1 text-white-1 p-[0.5rem] m-[0.75rem] rounded-[0.25rem]"
                disabled={!table.getCanPreviousPage()}
            >
                <p className="text-gray-500 px-[0.125rem]">
                    Anterior
                </p>
            </button>

            <button
                onClick={() => table.setPageIndex(table.getState().pagination.pageIndex)}
                className="bg-green-smart_city text-white-1 p-[0.5rem] m-[0.75rem] rounded-[0.25rem]"
            >
                <p className="text-white-500 px-[1rem]">
                    {table.getState().pagination.pageIndex + 1}
                </p>
            </button>

            <button
                onClick={() => table.nextPage()}
                className="bg-white-1 text-white-1 p-[0.5rem] m-[0.75rem] rounded-[0.25rem]"
                disabled={!table.getCanNextPage()}
            >
                <p className="text-gray-500 px-[0.5rem]">
                    Próximo
                </p>
            </button>

            <button
                onClick={() => table.lastPage()}
                className="bg-white-1 text-white-1 p-[0.5rem] m-[0.75rem] rounded-[0.25rem]"
            >
                <p className="text-gray-500 px-[0.5rem]">
                    Último
                </p>
            </button>

        </div>
    )
}