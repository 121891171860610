import {useForm} from "react-hook-form"
import React from 'react';

import {useUserContext} from "contexts/UserContext";

export function LoginForm() {
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm()

    const {login} = useUserContext()

    const onSubmit = async (data: any) => {
        try {
            const response = await login(data.login, data.senha)
            if (response == true) {
                // navigate('/dashboard')
                window.location.href = '/dashboard'
            } else {
                throw new Error("Erro ao fazer login")
            }
        } catch (error) {
            console.log(error)
        }

        reset()
    }


    return (
        <div className="w-2/3 h-1/2 font-raleway">
            <form className="flex flex-col h-full justify-evenly gap-10" onSubmit={handleSubmit(onSubmit)}>

                <div className="text-blue-login font-bold text-4xl mx-auto">
                    Faça seu login.
                </div>

                <div className="border-b border-gray-login rounded">
                    <label htmlFor="login" className="text-gray-login  font-bold">Login</label>
                    <input
                        id="login"
                        {...register("login", {maxLength: 150, required: true})}
                        className="w-full h-12 px-4 text-gray-login"
                        type="text"
                        name="login"
                        placeholder="Login"
                    />
                </div>

                <div className="border-b border-gray-login rounded">
                    <label htmlFor="senha" className="text-gray-login font-bold">Senha</label>
                    <input
                        id="senha"
                        {...register("senha", {maxLength: 150, required: true})}
                        className="w-full h-12 px-4 text-gray-login"
                        type="password"
                        name="senha"
                        placeholder="Senha"
                    />
                </div>

                {/* <div className="flex">
                    <div className="mr-auto">
                        <div className="checkbox-wrapper-1">
                            <input id="salvarsenha" className="substituted" type="checkbox" aria-hidden="true"/>
                            <label htmlFor="salvarsenha" className="pl-3 text-gray-login">Salvar senha</label>
                        </div>
                    </div>

                    <a className="text-gray-login underline cursor-pointer font-bold"
                       onClick={() => navigate('/esqueci-senha')}>Esqueci minha senha</a>.
                </div> */}

                <button
                    className="w-full p-3 mx-auto border border-black rounded-3xl uppercase text-white-1 bg-blue-login font-bold"
                    type="submit">
                    LOGIN
                </button>

            </form>
        </div>
    );
}

