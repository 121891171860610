import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {serviceBuscarQuestionario} from "api/QuestionarioService";
import {SimpleQuestionario} from "interfaces/Questionario";
import {useEffect, useState} from "react";
import LoadingScreen from "components/LoadingScreen";
import CardPerguntas from "./components/CardPergunta";
import TabelaPesosPergunta from "./components/TabelaPesosPergunta";

export default function VisualizarQuestionario() {
    const {id} = useParams();

    const [questionario, setQuestionario] = useState<SimpleQuestionario | null>(null);

    const query = useQuery({
        queryKey: ['idQuestionario', id],
        queryFn: async () => {
            const response = await serviceBuscarQuestionario({idQuestionario: Number(id)});
            return response.data as SimpleQuestionario;
        }
    });

    useEffect(() => {
        if (query.data) {
            setQuestionario(query.data);
        }
    }, [query.data]);

    if (!questionario) {
        return (
            <LoadingScreen/>
        )
    }

    return (
        <div className="p-4 px-20 flex-grow">
            <div className="flex flex-col gap-4">
                <div
                    className="rounded-lg bg-white-1 text-left text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white-1">
                    <div className="h-5 bg-[#00BC8F] rounded-t-lg"/>
                    <div className="p-6">
                        <h5 className="mb-1 text-5xl font-normal leading-tight ">
                            {questionario.nome}
                        </h5>
                        <p className="mb-4 text-base leading-normal">
                            {questionario.descricao}
                        </p>
                    </div>
                </div>

                {questionario.perguntas!.map((pergunta: any, index: number) => (
                    <>
                        <CardPerguntas
                            pergunta={pergunta}
                        />
                        <TabelaPesosPergunta
                            pergunta={pergunta}
                            eixos={questionario.eixos!}
                        />
                    </>

                ))}


            </div>
        </div>
    )
}
