import {TemplateLeftBar} from "components";
import {Header} from "components/Headers/HeaderFelipe";
import LoadingScreen from "components/LoadingScreen";
import {useEffect, useState} from "react";
import {HiChatBubbleLeft} from "react-icons/hi2";
import {Questionario, Resultados} from "./components";
import {useNavigate, useParams} from "react-router-dom";
import {FaEye} from "react-icons/fa";
import {useQuery} from "@tanstack/react-query";
import {serviceBuscarQuestionario} from "api/QuestionarioService";
import {SimpleQuestionario} from "interfaces/Questionario";
import {useModalContext} from "contexts/ModalContext";


export default function AdministrarQuestionario() {
    const {openNotification} = useModalContext()
    const navigate = useNavigate();

    const [view, setView] = useState("resultados");

    const {id} = useParams();
    const [questionario, setQuestionario] = useState<SimpleQuestionario | null>(null);


    const query = useQuery({
        queryKey: ['idQuestionario', id],
        queryFn: async () => {
            try {
                const response = await serviceBuscarQuestionario({idQuestionario: Number(id)});
                return response.data as SimpleQuestionario;
            } catch (error) {
                navigate('/meus-questionarios');
            }
        },
        retry: 3
    });

    useEffect(() => {
        if (query.data) {
            setQuestionario(query.data);
        }
    }, [query]);

    if (query.isLoading || !questionario) {
        return (
            <TemplateLeftBar>
                <LoadingScreen/>
            </TemplateLeftBar>
        )
    }

    return (
        <TemplateLeftBar>
            <Header fluxo={[
                {nome: 'Questionários', path: '/meus-questionarios'},
                {nome: 'Meus Questionários', path: '/meus-questionarios'},
            ]}/>

            <div className="flex justify-between items-center">
                <div className={"mt-3 text-2xl font-raleway font-bold flex items-center"}>
                    <HiChatBubbleLeft className='pr-2 w-9 h-9 text-green-smart_city'/>
                    <h1>{questionario!.nome}</h1>
                </div>

                <div
                    className="bg-green-1 h-9 rounded-xl gap-2 p-4 w-fit flex justify-center items-center cursor-pointer text-white-1"
                    onClick={() => {
                        window.open(`/visualizar-questionario/${questionario!.id}`, '_blank');
                    }}

                >
                    <FaEye className='text-white'/>
                    <h1>Visualizar</h1>
                </div>
            </div>


            <div className="my-5 w-full text-lg font-bold font-raleway">
                <button
                    className={`${view === 'questionario' ? 'bg-green-smart_city text-white-1' : 'bg-white-1 text-blue-smart_city'} px-4 py-2 rounded-l-2xl w-[50%] border-green-smart_city border`}
                    onClick={() => setView('questionario')}
                >
                    Editar questionário
                </button>
                <button
                    className={`${view === 'resultados' ? 'bg-green-smart_city text-white-1' : 'bg-white-1 text-blue-smart_city'} px-4 py-2 rounded-r-2xl w-[50%] border-green-smart_city border`}
                    onClick={() => setView('resultados')}
                >
                    Resultado
                </button>

            </div>

            {
                view === "resultados" ? (
                    <Resultados questionario={questionario}/>
                ) : view === "questionario" ? (
                    <Questionario questionario_id={questionario!.id!}/>
                ) : <LoadingScreen/>
            }

        </TemplateLeftBar>
    )
}