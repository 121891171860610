import {
    serviceAtualizarEixosQuestionario,
    serviceAtualizarNomeDescricaoQuestionario,
    serviceAtualizarPergunta,
} from "api/QuestionarioService";
import {SimpleQuestionario} from "interfaces/Questionario";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {NomeDescricaoForm} from "./components";
import EixosQuestionarioForm from "./EixosQuestionarioForm";
import {Eixo} from "interfaces/Eixo";
import PerguntaAlternativas from "interfaces/PerguntaAlternativa";
import PerguntasQuestionarioForm from "./components/PerguntasQuestionarioForm";
import RelacaoEixosPerguntasForm from "./components/RelacaoEixosPerguntasForm";
import ConfigQuestionarioForm from "./components/ConfigQuestionarioForm";
import PaginacaoQuestionarios from "components/FluxoProgresso";
import LoadingScreen from "components/LoadingScreen";
import {useModalContext} from "contexts/ModalContext";
import {useMutation} from "@tanstack/react-query";
import {useGetQuestionario} from "hooks/UseGetQuestionarios";
import LoadingOverlay from "components/LoadingOverlay";

export default function Questionario({questionario_id}: { questionario_id: number }) {
    const navigate = useNavigate();
    const {openNotification} = useModalContext();
    const [currentOption, setCurrentOption] = useState<number>(1);


    const [editedQueue, setEditedQueue] = useState<number[]>([]);

    const {
        questionario: questionarioData,
        setQuestionario: setQuestionarioData,
        refetch,
        isLoading
    } = useGetQuestionario(questionario_id);
    const [questionario, setQuestionario] = useState<SimpleQuestionario | null>(questionarioData);

    const [eixosQuestionario, setEixosQuestionario] = useState<Eixo[]>([]);

    useEffect(() => {
        if (questionarioData) {
            setQuestionario(questionarioData);
            setEixosQuestionario(questionarioData.eixos!);
        }
    }, [questionarioData]);

    function getOption() {
        if (!questionario || !questionarioData) {
            return <LoadingScreen/>;
        }
        switch (currentOption) {
            case 1:
                return <NomeDescricaoForm questionarioState={[questionario, setQuestionario]}/>;
            case 2:
                return <EixosQuestionarioForm eixosState={[eixosQuestionario, setEixosQuestionario]}/>;
            case 3:
                return (
                    <PerguntasQuestionarioForm
                        questionarioState={[questionarioData, setQuestionarioData]}
                        editingQueueState={[editedQueue, setEditedQueue]}
                    />
                );
            case 4:
                return (
                    <RelacaoEixosPerguntasForm
                        questionarioState={[questionarioData, setQuestionarioData]}
                        editingQueueState={[editedQueue, setEditedQueue]}
                    />
                );
            case 5:
                return <ConfigQuestionarioForm questionarioState={[questionario!, setQuestionario]}/>;
            default:
                return <></>;
        }
    }

    const {mutate: mutateUpdatePergunta, isPending: isPendingUpdatePergunta} = useMutation({
        mutationFn: serviceAtualizarPergunta,
        onSuccess: (response: any) => {
            setQuestionarioData({
                ...questionarioData,
                perguntas: questionarioData?.perguntas?.map((pergunta: PerguntaAlternativas) => {
                    if (pergunta.id === response.data.id) {
                        return response.data;
                    }
                    return pergunta;
                })
            });
        },
        onError: (error: any) => {
            console.log(error);
        }
    });


    const {mutate: mutateMetadata, isPending: isPendingMetadata} = useMutation({
        mutationFn: serviceAtualizarNomeDescricaoQuestionario,
        onSuccess: (data: any) => {
            setQuestionarioData({
                ...questionarioData,
                nome: data.data.nome,
                descricao: data.data.descricao,
                situacao: data.data.situacao,
            })
            openNotification("Metadados atualizados com sucesso", "success");
        },
        onError: (error: any) => {
            openNotification(error?.response?.data?.error || "Erro ao enviar metadados", "error");
        },
    });

    const {mutate: mutateEixos, isPending: isPendingEixos} = useMutation({
        mutationFn: serviceAtualizarEixosQuestionario,
        onSuccess: (data: any) => {
            setQuestionarioData(data.data);
            openNotification("Eixos atualizados com sucesso", "success");
        },
        onError: (error: any) => {
            openNotification(error?.response?.data?.error || "Erro ao enviar eixos", "error");
        },
    });

    async function handleNext() {
        switch (currentOption) {
            case 1:
                if (questionarioData?.nome === questionario?.nome && questionarioData?.descricao === questionario?.descricao) {
                    setCurrentOption(2);
                    return;
                }
                if (!questionario?.nome) {
                    openNotification("O questionário deve ter um nome válido", "error");
                    return;
                }
                mutateMetadata(
                    {
                        idQuestionario: questionario!.id!,
                        nome: questionario!.nome!,
                        descricao: questionario!.descricao!,
                        situacao: questionario!.situacao!,
                    },
                    {
                        onSuccess: () => {
                            setCurrentOption(2);
                        }
                    }
                );
                break;
            case 2:
                const eixosDeleted = questionarioData?.eixos?.filter(eixoOriginal => !eixosQuestionario.find(eixo => eixo.id === eixoOriginal.id));
                const newEixos = eixosQuestionario.filter(eixo => !questionarioData?.eixos?.find(eixoOriginal => eixoOriginal.id === eixo.id));
                if (!eixosDeleted?.length && !newEixos.length) {
                    setCurrentOption(3);
                    return;
                }
                mutateEixos(
                    {
                        idQuestionario: questionario!.id!,
                        eixos: eixosQuestionario,
                    },
                    {
                        onSuccess: () => {
                            setCurrentOption(3);
                        }
                    }
                );
                break;
            case 3:
                if (questionarioData?.perguntas?.length === 0) {
                    openNotification("O questionário deve ter ao menos uma pergunta", "error");
                    return;
                }
                editedQueue.forEach(async (id) => {
                    const pergunta = questionarioData?.perguntas?.find((pergunta) => pergunta.id === id);
                    if (!pergunta) return;
                    mutateUpdatePergunta({pergunta});
                    setEditedQueue((prev) => prev.filter((editedId) => editedId !== id));
                });
                if (isPendingUpdatePergunta) {
                    while (isPendingUpdatePergunta) {
                    }
                    openNotification("Questionário atualizado com sucesso", "success");
                }
                setCurrentOption(4);
                break;
            case 4:
                editedQueue.forEach(async (id) => {
                    const pergunta = questionarioData?.perguntas?.find((pergunta) => pergunta.id === id);
                    if (!pergunta) return;
                    mutateUpdatePergunta({pergunta});
                    setEditedQueue((prev) => prev.filter((editedId) => editedId !== id));
                });
                if (isPendingUpdatePergunta) {
                    while (isPendingUpdatePergunta) {
                    }
                    openNotification("Questionário atualizado com sucesso", "success");
                }
                setCurrentOption(5);
                break;
            case 5:
                mutateMetadata(
                    {
                        idQuestionario: questionario!.id!,
                        nome: questionario!.nome!,
                        descricao: questionario!.descricao!,
                        situacao: questionario!.situacao!,
                    },
                    {
                        onSuccess: () => {
                            openNotification("Questionário atualizado com sucesso", "success");
                        }
                    }
                );
                break;
            default:
                break;
        }
    }

    async function handlePrevious() {
        switch (currentOption) {
            case 2:
                const eixosDeleted = questionarioData?.eixos?.filter(eixoOriginal => !eixosQuestionario.find(eixo => eixo.id === eixoOriginal.id));
                const newEixos = eixosQuestionario.filter(eixo => !questionarioData?.eixos?.find(eixoOriginal => eixoOriginal.id === eixo.id));
                if (!eixosDeleted?.length && !newEixos.length) {
                    setCurrentOption(1);
                    return;
                }
                mutateEixos(
                    {
                        idQuestionario: questionario!.id!,
                        eixos: eixosQuestionario,
                    },
                    {
                        onSuccess: () => {
                            setCurrentOption(1);
                        }
                    }
                );
                break;
            case 3:
                editedQueue.forEach(async (id) => {
                    const pergunta = questionarioData?.perguntas?.find((pergunta) => pergunta.id === id);
                    if (!pergunta) return;
                    mutateUpdatePergunta({pergunta});
                    setEditedQueue((prev) => prev.filter((editedId) => editedId !== id));
                });
                if (isPendingUpdatePergunta) {
                    while (isPendingUpdatePergunta) {
                    }
                    openNotification("Questionário atualizado com sucesso", "success");
                }
                setCurrentOption(2);
                break;
            case 4:
                editedQueue.forEach(async (id) => {
                    const pergunta = questionarioData?.perguntas?.find((pergunta) => pergunta.id === id);
                    if (!pergunta) return;
                    mutateUpdatePergunta({pergunta});
                    setEditedQueue((prev) => prev.filter((editedId) => editedId !== id));
                });
                if (isPendingUpdatePergunta) {
                    while (isPendingUpdatePergunta) {
                    }
                    openNotification("Questionário atualizado com sucesso", "success");
                }
                setCurrentOption(3);
                break;
            case 5:
                mutateMetadata(
                    {
                        idQuestionario: questionario!.id!,
                        nome: questionario!.nome!,
                        descricao: questionario!.descricao!,
                        situacao: questionario!.situacao!,
                    },
                    {
                        onSuccess: () => {
                            openNotification("Questionário atualizado com sucesso", "success");
                            setCurrentOption(4);
                        }
                    }
                );
                break;
            default:
                break;

        }
    }

    const pending = isPendingMetadata || isPendingEixos || isPendingUpdatePergunta || isLoading;

    if (!questionario || !questionarioData) {
        return <LoadingScreen/>;
    }

    if (questionario.respondido) {
        return (
            <div className="w-full gap-4 flex">
                <div className="w-full">
                    <div className="mt-5 w-full">
                        <ConfigQuestionarioForm questionarioState={[questionario, setQuestionario]}/>
                    </div>

                    <div className="flex justify-between relative mt-5">

                        <div className="w-24 font-semibold text-sm text-white-1 p-1 bg-[#01C38E] rounded cursor-pointer"
                             onClick={() => {
                                 mutateMetadata(
                                     {
                                         idQuestionario: questionario!.id!,
                                         nome: questionario!.nome!,
                                         descricao: questionario!.descricao!,
                                         situacao: questionario!.situacao!,
                                     }
                                 );
                             }}
                        >
                            <p className="mx-auto w-fit">Concluir</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


    return (
        <div className="w-full gap-4 flex">
            <LoadingOverlay active={pending}/>

            <div className="w-full">
                <div className="mt-5 w-full">{getOption()}</div>
                <div className="flex justify-between relative mt-5">
                    <div
                        className={`w-24 font-semibold text-sm text-white-1 p-1 bg-[#01C38E] rounded ${
                            currentOption === 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={handlePrevious}
                    >
                        <p className="mx-auto w-fit">Voltar</p>
                    </div>
                    <div className="w-24 font-semibold text-sm text-white-1 p-1 bg-[#01C38E] rounded cursor-pointer"
                         onClick={handleNext}>
                        <p className="mx-auto w-fit">{currentOption === 5 ? 'Concluir' : 'Próximo'}</p>
                    </div>
                </div>
            </div>
            <div className="w-[15%] mt-5 mb-auto rounded bg-white-1">
                <PaginacaoQuestionarios
                    estados={[
                        {id: 1, mensagem: 'Nome'},
                        {id: 2, mensagem: 'Eixos'},
                        {id: 3, mensagem: 'Questões'},
                        {id: 4, mensagem: 'Eixo-Perguntas'},
                        {id: 5, mensagem: 'Configurações'},
                    ]}
                    estadoAtual={currentOption}
                />
            </div>
        </div>
    );
}
