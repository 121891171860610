import React from 'react';
import {TemplateLeftBar} from 'components';
import {TabelaQuestionarios} from './components';
import {Header} from 'components/Headers/HeaderFelipe';

export default function QuestionariosDisponiveis() {
    return (
        <TemplateLeftBar>
            <Header fluxo={[
                {nome: 'Questionários', path: '/meus-questionarios'},
                {nome: 'Diagnósticos Disponíveis', path: '/questionarios-disponiveis'}
            ]}/>

            <div className={"mt-3 text-2xl font-raleway font-bold"}>
                <h1>Questionários Disponíveis</h1>
            </div>

            <div className="bg-white-2 px-6 py-2 rounded mt-5 w-full">
                <TabelaQuestionarios/>
            </div>
        </TemplateLeftBar>
    )


}
