import {Modal} from "@mui/material";
import React, {createContext, useContext, useState} from "react";
import {CiCircleCheck} from "react-icons/ci";
import {RiErrorWarningLine} from "react-icons/ri";


type ModalContextType = {
    openNotification: (message: string, type: "success" | "error" | "warning" | "info") => void;
    openConfirmationModal: (message: string, type: "success" | "error" | "warning" | "info", action: () => void) => void;
};

const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const useModalContext = () => useContext(ModalContext);

export function ModalProvider({children}: { children: React.ReactNode }) {
    const [isModalNotificationOpen, setIsModalNotificationOpen] = useState<boolean>(false);
    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState<boolean>(false);

    const [modalMessage, setModalMessage] = useState<string>("");
    const [modalAction, setModalAction] = useState<() => void>(() => {
    });
    const [modalType, setModalType] = useState<"success" | "error" | "warning" | "info">("success");

    function openNotification(message: string, type: "success" | "error" | "warning" | "info") {
        setModalMessage(message);
        setModalType(type);
        setIsModalNotificationOpen(true);
    }

    function openConfirmationModal(message: string, type: "success" | "error" | "warning" | "info", action: () => void) {
        setModalMessage(message);
        setModalType(type);
        setModalAction(() => action);
        setIsModalConfirmationOpen(true);
    }

    function getColor(): string {
        if (modalType === "success") return '#01C38E';
        else if (modalType === "error") return '#E44F4F';
        else if (modalType === "warning") return '#FFA900';
        else return '#0079FF';
    }

    return (
        <ModalContext.Provider value={{openNotification, openConfirmationModal}}>
            {children}

            {
                isModalNotificationOpen &&
                <Modal
                    className="w-[40rem] h-[24rem] m-auto"
                    open={isModalNotificationOpen}
                    onClose={() => setIsModalNotificationOpen(false)}
                >
                    <div className="bg-white-1 w-full h-full flex flex-col justify-center items-center rounded-[1rem]">
                        <p className="text-center font-bold font-raleway text-[8rem]">
                            {modalType === "success" ?
                                <CiCircleCheck color={getColor()}/> :
                                <RiErrorWarningLine color={getColor()}/>}
                        </p>

                        <p className="text-center font-bold font-raleway text-[#132D46] text-[2rem] mx-8">
                            {modalMessage}
                        </p>

                        <button
                            className="mt-[1rem] rounded-[0.175rem] w-[20rem] text-[1.2rem] py-[0.3rem] text-[#FFFFFF]"
                            style={{backgroundColor: getColor()}}
                            onClick={() => setIsModalNotificationOpen(false)}
                        >
                            {modalType === "success" ? "Confirmar" : "Voltar"}
                        </button>
                    </div>
                </Modal>
            }

            {
                isModalConfirmationOpen &&
                <Modal
                    className="w-[40rem] h-[24rem] m-auto"
                    open={isModalConfirmationOpen}
                    onClose={() => setIsModalConfirmationOpen(false)}
                >
                    <div className="bg-white-1 w-full h-full flex flex-col justify-center items-center rounded-[1rem]">
                        <p className="text-center font-bold font-raleway text-[8rem]">
                            <RiErrorWarningLine color={getColor()}/>
                        </p>

                        <p className="text-center font-bold font-raleway text-[#132D46] text-[2rem] mx-8">
                            {modalMessage}
                        </p>

                        <div className="mt-[1rem] flex justify-around gap-[1rem]">
                            <button
                                className="font-bold font-raleway py-[0.3rem] px-[1rem] rounded bg-gray-light_2 text-gray-800"
                                type="button"
                                onClick={() => setIsModalConfirmationOpen(false)}
                            >
                                Cancelar
                            </button>
                            <button
                                className="font-raleway text-[#FFFFFF] font-bold py-[0.3rem] px-[1rem] rounded"
                                type="button"
                                style={{backgroundColor: getColor()}}
                                onClick={() => {
                                    modalAction();
                                    setIsModalConfirmationOpen(false);
                                }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </div>
                </Modal>
            }

        </ModalContext.Provider>
    );
}