import {Radar as ChartRadar} from 'react-chartjs-2';
import {Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip} from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const smallChartOptions = {
    elements: {
        line: {
            borderWidth: 5,
        },
        point: {
            radius: 0,
            borderWidth: 8,
            hoverRadius: 6,
            pointHitRadius: 14,
        }
    },
    plugins: {
        legend: {
            display: false,
            position: 'bottom' as const,
            labels: {
                boxWidth: 10,
                font: {
                    size: 10,
                }
            }
        },


    },
    scales: {
        r: {
            ticks: {
                display: true,
            },
            pointLabels: {
                display: true,
            },
            grid: {
                color: 'rgba(0, 0, 0, 0.3)' // Set the color of the radar grid lines
            },
            suggestedMin: 0,
            suggestedMax: 100,
        }
    },
};

const largeChartOptions = {
    elements: {
        line: {
            borderWidth: 5,
        },
        point: {
            radius: 0,
            borderWidth: 8,
            hoverRadius: 6,
            pointHitRadius: 14,
        }
    },
    scales: {
        r: {
            ticks: {
                display: true,
                color: 'rgba(0, 0, 0, 0.3)',
                backdropColor: 'rgba(0, 0, 0, 0)',
            },
            pointLabels: {
                display: true,
                font: {
                    size: 10,
                },
            },
            grid: {
                color: 'rgba(0, 0, 0, 0.3)' // Set the color of the radar grid lines
            },
            suggestedMin: 0,
            suggestedMax: 100,
        },


    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
        },

    },
};

export default function Radar({data, op = 1}: { data: any, op: number }) {
    const options = op === 1 ? smallChartOptions : op === 2 ? largeChartOptions : {};

    return (
        <div className="w-full h-full relative">
            <ChartRadar data={data} options={options}/>
        </div>
    );
}