import {serviceLogin, serviceRefreshToken} from "api/AuthService";
import React, {createContext, useContext, useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";

type QuestionarioContextType = {
    id: number | null;
    foto_url: string | null;
    nome: string | null;
    qtd_respostas: number | null;
    qtd_respostas_comentadas: number | null;
    qtd_questionarios: number | null;
    atividade_ultimos_meses: { mes: string, total: number }[] | null;
    loading: boolean;
    nivelPermissao: number | null;
    login: (nome: string, password: string) => Promise<boolean>;
    logout: () => void;
    refresh: () => Promise<boolean>;
};

const UserContext = createContext<QuestionarioContextType>({} as QuestionarioContextType);

export const useUserContext = () => useContext(UserContext);

export function UserProvider({children}: { children: React.ReactNode }) {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [id, setId] = useState<number | null>(null);
    const [nome, setNome] = useState<string | null>(null);
    const [foto_url, setFotoUrl] = useState<string | null>(null);
    const [nivelPermissao, setNivelPermissao] = useState<number | null>(null);
    const [qtd_respostas, setQtdRespostas] = useState<number | null>(null);
    const [qtd_respostas_comentadas, setQtdRespostasComentadas] = useState<number | null>(null);
    const [qtd_questionarios, setQtdQuestionarios] = useState<number | null>(null);
    const [atividade_ultimos_meses, setAtividadeUltimosMeses] = useState<{ mes: string, total: number }[] | null>(null);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingAccessToken, setLoadingAccessToken] = useState<boolean>(true);

    useEffect(() => {
        if (!loadingAccessToken) {
            setLoading(false);
        }
    }, [loadingAccessToken]);

    useEffect(() => {
        setLoading(true);
        const storageAccessToken = localStorage.getItem("accessToken");
        if (storageAccessToken) {
            setAccessToken(storageAccessToken);
        } else {
            setLoadingAccessToken(false);
        }

    }, []);

    useEffect(() => {
        if (accessToken) {
            const decoded: any = jwtDecode(accessToken);
            setId(decoded.id);
            setNome(decoded.nome);
            setNivelPermissao(decoded.nivel_permissao);
            setQtdRespostas(decoded.qtd_respostas);
            setQtdRespostasComentadas(decoded.qtd_respostas_comentadas);
            setQtdQuestionarios(decoded.qtd_questionarios);
            setAtividadeUltimosMeses(decoded.atividade_ultimos_meses);
            setFotoUrl(decoded.foto_url);
            setLoadingAccessToken(false);
        }
    }, [accessToken]);

    async function login(nome: string, password: string) {
        setLoadingAccessToken(true);
        try {
            const response = await serviceLogin({nome, password});
            const accessToken = response.data.access;
            const refreshToken = response.data.refresh;

            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            setAccessToken(accessToken);

            return true;
        } catch (error) {
            setLoadingAccessToken(false);
            return false;
        }

    }

    async function refresh() {
        setLoadingAccessToken(true);
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
            try {
                const response = await serviceRefreshToken({token: refreshToken});
                const accessToken = response.data.access;
                const newRefreshToken = response.data.refresh;
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", newRefreshToken);
                setAccessToken(accessToken);
                return true;

            } catch (error) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                setLoadingAccessToken(false);
                return false;
            }
        } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }

        setLoadingAccessToken(false);
        return false;
    }


    function logout() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate('/login');
    }


    return (
        <UserContext.Provider
            value={{
                id,
                nome,
                nivelPermissao,
                loading,
                login,
                logout,
                refresh,
                qtd_respostas,
                qtd_respostas_comentadas,
                qtd_questionarios,
                atividade_ultimos_meses,
                foto_url
            }}
        >
            {children}
        </UserContext.Provider>
    );
}