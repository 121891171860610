import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getOrganizacoes, getRamosAtividade} from "api/OrganizacaoService";
import {Organizacao, RamoAtividade} from "interfaces/Organizacao";
import {PaginatedResponse, Pagination} from "interfaces/Pagination";

type UseGetOrganizacoesProps = {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export function useGetOrganizacoes({page = 1, pageSize = 10, filters, ordering}: UseGetOrganizacoesProps) {
    const [organizacoes, setOrganizacoes] = useState<Organizacao[]>([]);
    const [pagination, setPagination] = useState<Pagination>({count: 0});
    const [debouncedParams, setDebouncedParams] = useState({page, pageSize, filters, ordering});

    const {data, refetch, error} = useQuery<PaginatedResponse<Organizacao>>({
        queryKey: ['organizacoes', debouncedParams.page, debouncedParams.pageSize, debouncedParams.filters, debouncedParams.ordering],
        queryFn: async () => {
            const response = await getOrganizacoes({
                page,
                pageSize,
                filters,
                ordering,
            });
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setOrganizacoes(data.results);
            setPagination({
                ...data
            });
        }
    }, [data]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedParams({page, pageSize, filters, ordering});
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page, pageSize, filters, ordering]);

    return {organizacoes, pagination, refetch, error};
}

export function useGetRamosAtividade() {
    const [ramosAtividade, setRamosAtividade] = useState<RamoAtividade[]>([]);

    const {data, refetch, error} = useQuery<RamoAtividade[]>({
        queryKey: ['ramosAtividade'],
        queryFn: async () => {
            const response = await getRamosAtividade();
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setRamosAtividade(data);
        }
    }, [data]);


    return {ramosAtividade, refetch, error};
}


/*
interface UseGetRespostasProps {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}


export function useGetRespostas({page = 1, pageSize = 10, filters, ordering}: UseGetRespostasProps) {
    const [respostas, setRespostas] = useState<UsuarioRespondeQuestionario[]>([]);
    const [pagination, setPagination] = useState<Pagination>({count: 0});
    const [debouncedParams, setDebouncedParams] = useState({page, pageSize, filters, ordering});

    const {data, refetch, error} = useQuery<PaginatedResponse<UsuarioRespondeQuestionario>>({
        queryKey: ['respostas', debouncedParams.page, debouncedParams.pageSize, debouncedParams.filters, debouncedParams.ordering],
        queryFn: async () => {
            const response = await getRespostas({
                page,
                pageSize,
                filters,
                ordering,
            });
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setRespostas(data.results);
            setPagination({
                ...data
            });
        }
    }, [data]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedParams({page, pageSize, filters, ordering});
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page, pageSize, filters, ordering]);

    return {respostas, pagination, refetch, error};
}
*/