import {getUsuario, serviceUpdateUsuario} from "api/UsuarioService";
import {TemplateLeftBar} from "components";
import LoadingScreen from "components/LoadingScreen";
import {useUserContext} from "contexts/UserContext";
import Usuario from "interfaces/Usuario";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {ModalAlterarEmail, ModalAlterarSenha} from "./components";
import {FaPen, FaPencil} from "react-icons/fa6";

export default function Configuracoes() {
    const {id} = useUserContext();

    const [usuario, setUsuario] = useState<Usuario | null>(null);

    const [modalEmail, setModalEmail] = useState(false);
    const [modalSenha, setModalSenha] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
    } = useForm();

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await getUsuario({idUsuario: id!});
                setUsuario(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        if (id) {
            fetchUserData();
        }
    }, [id]);

    const handleUpdate = async (data: any) => {
        reset();
        const uploadData: any = {
            id: id,
        }

        const foto = data.foto[0];
        if (foto) uploadData.foto = foto;

        if (data.email) uploadData.email = data.email.trim();
        if (data.senha) uploadData.senha = data.senha.trim();

        try {
            await serviceUpdateUsuario(uploadData);
            const response = await getUsuario({idUsuario: id!});
            setUsuario(response.data);

            setModalEmail(false);
            setModalSenha(false);
        } catch (error) {
            console.error(error);
        }
    }

    if (!usuario) {
        return (
            <TemplateLeftBar>
                <LoadingScreen/>
            </TemplateLeftBar>
        )
    }

    return (
        <>
            <TemplateLeftBar>
                <p className="text-2xl font-bold font-raleway text-[#132D46] mt-12 mb-6">
                    Meu Perfil
                </p>

                <div className="bg-white-1 p-12 rounded-2xl">
                    <div className="w-[10rem] h-[10rem] relative flex" onChange={handleSubmit(handleUpdate)}>
                        {usuario.foto_url ? (
                            <img src={process.env.REACT_APP_API_URL + usuario.foto_url} alt="Foto de perfil"
                                 className="w-full rounded-full"/>
                        ) : (
                            <div className="w-full rounded-full bg-green-1"></div>
                        )}
                        <input type="file"
                               accept="image/*"
                               {...register('foto')}
                               className="absolute w-full h-full opacity-0 cursor-pointer"
                        />
                    </div>

                    <form onSubmit={handleSubmit(handleUpdate)} id="formDados" className="mt-8">
                        <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                            Nome de usuário
                        </p>
                        <div className="flex items-center w-[48%] mt-2">
                            <input type="text"
                                   className="w-full bg-[#DCDCDC] p-2 rounded-lg"
                                   value={usuario.login}
                                   disabled={true}
                            />
                        </div>

                        <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                            Email cadastrado
                        </p>
                        <div className="flex items-center w-[50%] mt-2">
                            <input type="text"
                                   className="w-full bg-[#DCDCDC] p-2 rounded-lg"
                                   value={usuario.email}
                                   disabled={true}
                            />
                            <FaPen className="cursor-pointer text-green-1 text-2xl ml-2"
                                      onClick={() => setModalEmail(true)}/>
                        </div>
                        {modalEmail &&
                            <ModalAlterarEmail
                                setValue={setValue}
                                email={usuario.email!}
                                modalState={[modalEmail, setModalEmail]}
                            />
                        }

                        <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                            Login
                        </p>
                        <div className="flex items-center w-[48%] mt-2">
                            <input type="text"
                                   className="w-full bg-[#DCDCDC] p-2 rounded-lg"
                                   value={usuario.login}
                                   disabled={true}
                            />
                        </div>

                        <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                            Senha
                        </p>
                        <div className="flex items-center w-[50%] mt-2">
                            <input type="password"
                                   className="w-full bg-[#DCDCDC] p-2 rounded-lg"
                                   value={"********"}
                                   disabled={true}
                            />
                            <FaPen className="cursor-pointer text-green-1 text-2xl ml-2"
                                      onClick={() => setModalSenha(true)}/>
                        </div>
                        {modalSenha &&
                            <ModalAlterarSenha
                                setValue={setValue}
                                modalState={[modalSenha, setModalSenha]}
                            />
                        }

                        <p className="text-lg font-raleway font-bold text-black-2 mt-4">
                            Grau de permissão
                        </p>
                        <div className="flex items-center w-[48%] mt-2">
                            <input type="text"
                                   className="w-full bg-[#DCDCDC] p-2 rounded-lg"
                                   value={usuario.nivel_permissao === 2 ? "Administrador" : usuario.nivel_permissao === 1 ? "Pesquisador" : "Usuário"}
                                   disabled={true}
                            />
                        </div>
                    </form>
                </div>

                <p className="text-2xl font-bold font-raleway text-[#132D46] mt-12 mb-6">
                    Suporte
                </p>

                <div className="bg-white-1 p-12 rounded-2xl">
                    <p className="text-xl font-bold font-raleway text-[#132D46]">
                        Entre em contato conosco
                    </p>
                    <p className="text-lg font-raleway text-[#132D46]">
                        Telefone: 43 99116-1703
                    </p>
                    <p className="text-lg font-raleway text-[#132D46]">
                        Email: rodolfo@uel.br
                    </p>
                </div>
            </TemplateLeftBar>
        </>
    )
}
