import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {TemplateLeftBar} from 'components';
import {Header} from 'components/Headers/HeaderFelipe';
import {RamoAtividade} from "interfaces/Organizacao";
import {createOrganizacao, getRamosAtividade} from 'api/OrganizacaoService';
import CustomDropdownRamos from './components/DropdownRamos';
import CustomDropdown from "pages/Administrar/AdministrarUsuarios/NovoUsuario/components/DropdownHorroroso";
import {ModalAviso} from "components/Modals";
import { useModalContext } from 'contexts/ModalContext';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';

interface FormData {
    nome: string;
    descricao: string;
}

export default function NovaEmpresa() {
    const {openNotification} = useModalContext();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<FormData>();
    const navigate = useNavigate();

    const [ramos, setRamos] = useState<RamoAtividade[]>([]);

    const [selectedRamo, setSelectedRamo] = useState<RamoAtividade | null>(null);
    const [publica, setPublica] = useState<boolean>(true);

    useEffect(() => {
        async function fetchRamos() {
            try {
                const response = await getRamosAtividade();
                setRamos(response.data);
            } catch (error) {
                console.log(error);
                setRamos([]);
            }
        }

        fetchRamos();
    }, []);

    async function onSubmit(data: FormData) {
        if (!selectedRamo) {
            openNotification("Selecione um ramo de atividade", "error");
            return;
        }
        
        try {
            await createOrganizacao({
                nome: data.nome,
                descricao: data.descricao,
                ramo_atividade_id: selectedRamo!.id!,
                publica: publica
            });

            openNotification("Organização criada com sucesso", "success");
            navigate('/painel-organizacoes');
        } catch (error: any) {
            const message = error.response?.data?.message || "Erro ao criar organização";
            openNotification(message, "error");
        }
    }

    return (
        <TemplateLeftBar>
            <div className="w-full">
                <Header fluxo={[
                    {nome: 'Administrador', path: '/opcoes-administrador'},
                    {nome: 'Organizações', path: '/painel-organizacoes'},
                    {nome: 'Nova Organização', path: '/nova-organizacao'}
                ]}/>

                <div className="flex justify-between">
                    <div className="mt-3 text-2xl font-raleway font-bold">
                        <h1>Nova Organização</h1>
                    </div>
                </div>

                <div className="bg-white-1 p-6 mt-4 rounded-xl shadow-xl">
                    <form onSubmit={handleSubmit(onSubmit)} id="formCriarRamo">
                        <div className="w-1/2">
                            <label className="block mb-2">Nome</label>
                            <input
                                className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl" {...register('nome', {required: true})} />
                            {errors.nome && <span className="text-red-600">Este campo é obrigatório</span>}
                        </div>

                        <div className="w-1/2 mt-4">
                            <label className="block mb-2 ml-2">Descrição</label>
                            <textarea className="w-full border bg-[#F1F1F1] border-gray-200 p-[0.3em] rounded-xl"
                                      rows={5} {...register('descricao', {required: true})} />
                            {errors.descricao && <span className="text-red-600">Este campo é obrigatório</span>}
                        </div>
                    </form>

                    <div className="w-1/4 mt-4">
                        <label className="block mb-2 ml-2">Ramo de atividade</label>
                        <Autocomplete
                            className={'rounded-xl bg-white-2 px-2 py-1 border border-[#4B4B4B]/0.2'}
                            options={ramos}
                            getOptionLabel={(option) => option.nome}
                            onChange={(event, value) => {
                                setSelectedRamo(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Selecione o ramo de atividade"
                                />
                            )}
                        />
                        {/* <CustomDropdownRamos ramos={ramos} optionState={[selectedRamo, setSelectedRamo]}/> */}
                    </div>

                    <div className="w-1/4 mt-4">
                        <label className="block mb-2 ml-2">Tipo de Organização</label>
                        <Autocomplete
                            className={'rounded-xl bg-white-2 px-2 py-1 border border-[#4B4B4B]/0.2'}
                            options={['Pública', 'Privada']}
                            getOptionLabel={(option) => option}
                            value={publica === true ? 'Pública' : publica === false ? 'Privada' : 'Pública'}
                            isOptionEqualToValue={(option, value) => option === value}
                            onChange={(event, value) => {
                                setPublica(value === 'Pública');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Selecione o tipo de organização"
                                />
                            )}
                        />
                        {/* <CustomDropdown
                            options={['Pública', 'Privada']}
                            selectedOption={publica === true ? 'Pública' : publica === false ? 'Privada' : 'Pública'}
                            onOptionSelect={(value) => {
                                setPublica(value === 'Pública');
                            }}
                        /> */}
                    </div>

                </div>

                <button type="submit" form="formCriarRamo"
                        className="mt-4 py-[1em] px-[2em] bg-[#01C38E] text-white-1 rounded-xl">
                    Salvar
                </button>
            </div>
        </TemplateLeftBar>
    );
}
