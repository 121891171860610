import {useEffect, useState} from "react";


export default function useGetManualTableFilters(size: number = 10) {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(size);
    const [filters, setFilters] = useState<{}>({});
    const [ordering, setOrdering] = useState<string>("");

    useEffect(() => {
        setPage(1);
    }, [ordering, filters]);

    return {
        page,
        setPage,
        pageSize,
        filters,
        setFilters,
        ordering,
        setOrdering
    }
}