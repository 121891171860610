import {TemplateLeftBar} from "components";
import {Header} from "components/Headers/HeaderFelipe";
import TabelaRamoAtividade from "./components/TabelaRamoAtividade";
import {useNavigate} from "react-router-dom";
import {FaPlus} from "react-icons/fa";

export default function PainelRamoAtividade() {
    const navigate = useNavigate();
    return (
        <TemplateLeftBar>
            <Header fluxo={[
                {nome: 'Administrador', path: '/opcoes-administrador'},
                {nome: 'Ramos de Atividade', path: '/painel-ramos'}
            ]}/>

            <div className={"flex justify-between relative"}>
                <div className={"mt-3 text-2xl font-raleway font-bold"}>
                    <h3>Administrar Ramos de Atividade</h3>
                </div>

                <div className="mt-1 mx-1 flex items-center">
                    <div className="p-2 rounded-lg justify-center items-center flex bg-[#01C38E]"
                         onClick={() => navigate('/novo-ramo')}
                    >
                        <h1 className="text-base flex justify-center items-center font-raleway cursor-pointer font-bold text-[#FFFFFF]">
                            <FaPlus className={'mr-2'}/> <p>Novo Ramo Atividade</p>
                        </h1>
                    </div>
                </div>
            </div>

            <div className="bg-white-2 px-6 py-2 rounded mt-5 w-full">
                <TabelaRamoAtividade/>
            </div>
        </TemplateLeftBar>
    )

}