import {Header} from "components/Headers/HeaderFelipe";
import {TabelaQuestionarios} from "./components";
import {TemplateLeftBar} from "components";

export default function SelecionarQuestionario() {
    return (
        <TemplateLeftBar>
            <div className={"w-full"}>
                <Header fluxo={[
                    {nome: 'Questionários', path: '/meus-questionarios'},
                    {nome: 'Comparar questionários', path: '/selecionar-questionario'},
                ]}/>

                <div className={"mt-3 text-2xl font-raleway font-bold"}>
                    <h1>Selecionar Questionário</h1>
                </div>
                <div className={"mt-[0.125rem] text-base font-raleway"}>
                    <h3>Selecione o questionário que deseja analisar</h3>
                </div>

                <div className="bg-white-2 rounded mt-5 w-full">
                    <TabelaQuestionarios/>
                </div>
            </div>
        </TemplateLeftBar>
    )
}