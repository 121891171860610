export default function SectionComoFunciona() {
    return (
        <div className='bg-blue-login flex flex-col items-center justify-center p-24 gap-16'>

            <div className='text-5xl font-extrabold text-green-smart_city'>
                Principais Ferramentas
            </div>

            <div className='flex gap-10'>

                <div className='flex flex-col w-80 items-center  gap-4'>
                    <div
                        className='h-[4rem] w-[4rem] text-3xl text-blue-login flex items-center justify-center rounded-full bg-green-smart_city'>
                        1
                    </div>

                    <div className='h-auto w-full font-bold text-2xl text-green-smart_city text-center mt-auto'>
                        Obtenha diagnósticos focados em melhorias
                    </div>
                    {/*
                    <div className='h-auto w-full font-medium text-lg text-white-1 text-left mt-auto'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer took a galley
                    </div> */}
                </div>

                <div className='flex flex-col w-80 items-center  gap-4'>
                    <div
                        className='h-[4rem] w-[4rem] text-3xl text-blue-login flex items-center justify-center rounded-full bg-green-smart_city'>
                        2
                    </div>

                    <div className='h-auto w-full font-bold text-2xl text-green-smart_city text-center mt-auto'>
                        Compare seus resultados
                    </div>

                    {/* <div className='h-auto w-full font-medium text-lg text-white-1 text-left mt-auto'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer took a galley
                    </div> */}
                </div>

                <div className='flex flex-col w-80 items-center gap-4'>
                    <div
                        className='h-[4rem] w-[4rem] text-3xl text-blue-login flex items-center justify-center rounded-full bg-green-smart_city'>
                        3
                    </div>

                    <div className='h-auto w-full font-bold text-2xl text-green-smart_city text-center mt-auto'>
                        Obtenha o diagnóstico comentado
                    </div>

                    {/* <div className='h-auto w-full font-medium text-lg text-white-1 text-left mt-auto'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer took a galley
                    </div> */}
                </div>

            </div>

        </div>
    )
}