import {CaixaComTextoENumero, InformacoesUsuario, PopoverButton, TabelaQuestionariosRecentes} from './components';
import {TemplateLeftBar} from "components";
import {useEffect, useState} from 'react';
import Radar from 'components/Graficos/Radar';
import {atenEixoToDataset} from 'utils/AtenEixoToDataset';
import Barras from 'components/Graficos/Barras';
import {useUserContext} from 'contexts/UserContext';
import LoadingScreen from 'components/LoadingScreen';
import {getUsuario} from 'api/UsuarioService';
import Usuario from 'interfaces/Usuario';
import {useGetEstatisticasUsuario} from 'hooks/UseGetEstatisticasUsuario';
import {useGetRespostas} from 'hooks/UseGetRespostasQuestionario';

export default function DashboardUsuario() {

    const {id, nome, loading} = useUserContext();
    const [usuarioData, setUsuarioData] = useState<Usuario | null>(null);
    const {estatisticasUsuario} = useGetEstatisticasUsuario(id!);
    const {respostas: ultimasRespostas} = useGetRespostas({pageSize: 5, ordering: '-data_resposta'});

    useEffect(() => {
        async function fetchUsuarioData() {
            try {
                const response = await getUsuario({idUsuario: id!});
                setUsuarioData(response.data);
            } catch (error) {
                setUsuarioData(null);
                console.log(error);
            }
        }

        if (id) {
            fetchUsuarioData();
        }
    }, [id]);

    if (loading) return <LoadingScreen/>;

    return (
        <TemplateLeftBar>
            <div className="flex mb-[3.0625rem] ml-1.5 my-10">
                {usuarioData && (
                    <div className="fill-blue-smart_city mx-[2.25rem]">
                        <InformacoesUsuario
                            usuario={usuarioData}
                        />
                    </div>
                )}

                <div className="flex gap-[2.25rem]">
                    <CaixaComTextoENumero numero={estatisticasUsuario?.qtd_respostas ?? 0}
                                          texto="Questionários respondidos"/>
                    <CaixaComTextoENumero numero={estatisticasUsuario?.qtd_respostas_comentadas ?? 0}
                                          texto="Diagnósticos comentados"/>

                </div>
            </div>

            <h2 className="text-lg font-bold ml-[3rem] font-raleway text-blue-smart_city mb-[2rem]">
                Último resultado obtido
            </h2>

            {ultimasRespostas.length > 0 && (
                <div
                    className="w-[90.6875rem] h-[33.5625rem] bg-white-1 ml-[2.6875rem] rounded-2xl shadow-2xl mb-[2.875rem] flex">
                    <div className="ml-[3.25rem] w-[33.5625rem] h-[23.5rem]">
                        <h2 className="mt-[2.125rem] text-lg font-bold font-raleway text-blue-smart_city mb-[1.875rem]">
                            {ultimasRespostas[0].questionario.nome}
                        </h2>
                        <Radar
                            data={atenEixoToDataset({
                                graficos: [
                                    {
                                        label: ultimasRespostas[0].questionario.nome!,
                                        resultado: ultimasRespostas[0].resultados,
                                        backgroundColor: "#01C38E90",
                                        borderColor: "#01C38E",
                                        fill: false,
                                    },
                                ],
                            })}
                            op={2}
                        />
                    </div>
                    <div className="w-[45.3125rem] h-[21.9375rem] mt-[6.9375rem]">
                        <Barras
                            data={atenEixoToDataset({
                                graficos: [
                                    {
                                        label: ultimasRespostas[0].questionario.nome!,
                                        resultado: ultimasRespostas[0].resultados,
                                        backgroundColor: "#01C38E90",
                                        borderColor: "#01C38E",
                                        fill: false,
                                    },
                                ],
                            })}
                        />
                    </div>
                    <div className="ml-auto">
                        <PopoverButton options={["Visualizar resultados"]}/>
                    </div>
                </div>
            )}

            <h2 className="text-lg font-bold ml-[3rem] font-raleway text-blue-smart_city mb-[2rem]">
                Questionários recentes
            </h2>

            <div className="w-[90.6875rem] bg-white-1 ml-[2.6875rem] rounded-2xl shadow-2xl mb-auto">
                <TabelaQuestionariosRecentes data={ultimasRespostas}/>
            </div>
        </TemplateLeftBar>
    );
}
