import {api} from "./api";

type getRespostasQuestionarioProps = {
    idQuestionario: number;
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export async function getRespostasQuestionario({
                                                   idQuestionario,
                                                   page = 1,
                                                   pageSize = 10,
                                                   filters,
                                                   ordering
                                               }: getRespostasQuestionarioProps) {
    const response = await api.get(`questionario/${idQuestionario}/respostas`, {
        params: {
            page,
            page_size: pageSize, ...filters,
            ordering
        }
    });
    return response;
}

type getRespostasProps = {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export async function getRespostas({page = 1, pageSize = 10, filters, ordering}: getRespostasProps) {
    const response = await api.get('questionario/respostas', {
        params: {page, page_size: pageSize, ordering, ...filters}
    });
    return response;
}

export async function getRespostaQuestionario({idResposta}: { idResposta: number }) {
    const response = await api.get('questionario/resposta/' + idResposta);
    return response;
}

export async function updateRespostaQuestionario({idResposta, comentario}: { idResposta: number, comentario: string }) {
    const response = await api.put(`questionario/resposta/${idResposta}/comentar`, {comentario});
    return response;
}