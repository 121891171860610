import React from "react";
import LinhaAtividade from "./components/LinhaAtividade";
import {EstatisticasQuestionariosUsuario} from "interfaces/Estatisticas";

export function RightSidebar({estatisticasUsuario}: { estatisticasUsuario: EstatisticasQuestionariosUsuario }) {
    const {usuario, atividade_ultimos_meses, qtd_cadastrados, qtd_comentados, qtd_respondidos} = estatisticasUsuario;
    const atividade = [...atividade_ultimos_meses].reverse().slice(6, 12);

    return (
        <ul className="flex flex-col justify-between fixed right-0 top-0 bottom-0 bg-white-1 rounded-2xl px-4 pb-8 w-[24rem] h-2/3 overflow-y-auto">
            <div className="flex flex-col items-center">
                <li className="mt-10 mb-10 flex items-center justify-center">
                    {usuario.foto_url ? (
                        <img
                            className="w-[3.43rem] h-[3.43rem] mr-4 hidden xl:flex rounded-full"
                            src={`${process.env.REACT_APP_API_URL}${usuario.foto_url}`}
                            alt="Foto de perfil"
                        />
                    ) : (
                        <div className="w-[3.43rem] h-[3.43rem] mr-4 bg-green-1 rounded-full"></div>
                    )}
                    <p className="font-raleway font-bold text-[1rem]">{usuario.nome_completo}</p>
                </li>
                <div className="w-4/5">
                    <hr className="mx-auto bg-gray-100 opacity-30 dark:bg-gray-500"/>
                </div>
            </div>

            <div className="w-full px-6 h-1/3">
                <LinhaAtividade data={atividade}/>
            </div>

            <div className="flex flex-col items-center h-2/5 w-full">
                <div className="w-2/3 mb-10">
                    <hr className="mx-auto bg-gray-100 opacity-30 dark:bg-gray-500"/>
                </div>

                <div className="flex flex-col items-center w-full h-4/5 space-y-6">
                    {[
                        {label: "Questionários Cadastrados", value: qtd_cadastrados},
                        {label: "Relatórios Gerados", value: qtd_comentados},
                        {label: "Respostas Totais", value: qtd_respondidos}
                    ].map((item, index) => (
                        <div key={index} className="w-4/5 shadow-md rounded-xl flex items-center truncate">
                            <p className="font-montserrat font-bold text-[2rem] ml-2 mr-2 w-1/3 text-blue-smart_city">
                                {item.value}
                            </p>
                            <div className="font-raleway font-bold ml-2 mr-2 w-2/3 text-blue-smart_city text-[1rem]">
                                {item.label.split(" ").map((text, i) => (
                                    <p key={i}>{text}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ul>
    );
}
