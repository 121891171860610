import {TemplateLeftBar} from "components";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {useUserContext} from "contexts/UserContext";
import {CaixaComTextoENumero} from "pages/Dashboard/Usuario/components";
import {useGetRespostas} from "hooks/UseGetRespostasQuestionario";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import {createColumnHelper} from "@tanstack/react-table";
import {useGetEstatisticasUsuario} from "hooks/UseGetEstatisticasUsuario";
import ManualTable from "components/ManualTable";
import {useNavigate} from "react-router-dom";

export default function MeusDiagnosticos() {

    // const [respostas, setRespostas] = useState<UsuarioRespondeQuestionario[]>([]);
    const {id} = useUserContext();
    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const {respostas} = useGetRespostas({
        page,
        pageSize,
        filters,
        ordering
    })
    const {estatisticasUsuario} = useGetEstatisticasUsuario(id!);
    const navigate = useNavigate();

    const columnHelper = createColumnHelper<UsuarioRespondeQuestionario>();
    const columns = [
        columnHelper.accessor('questionario.nome', {
            header: 'Questionário',
            cell: (info) => (
                <>{info.row.original.questionario.nome}</>
            ),
            meta: {
                filterKey: 'nome__icontains',
            },
        }),
        columnHelper.accessor('data_resposta', {
                header: 'Data',
                cell: info => (
                    <>{new Date(info.row.original.data_resposta).toLocaleDateString()}</>
                ),
                enableColumnFilter: false,
            }
        ),
        columnHelper.accessor('comentario', {
                header: 'Comentado',
                cell: info => (
                    <>{info.row.original.comentario ? 'Sim' : 'Não'}</>
                ),
                enableColumnFilter: false
            }
        ),
    ];

    function onRowClick(row: any) {
        navigate('/visualizar-resposta/' + row.id);
    }

    return (
        <TemplateLeftBar>
            <div
                className="flex mt-4 mb-12 gap-8"
            >
                <CaixaComTextoENumero
                    texto="Questionários respondidos"
                    numero={estatisticasUsuario?.qtd_respostas!}
                />
                <CaixaComTextoENumero
                    texto="Questionários comentados"
                    numero={estatisticasUsuario?.qtd_respostas_comentadas!}
                />
            </div>

            <ManualTable
                className="w-full m-auto mb-12 rounded-lg shadow-lg overflow-hidden px-6 py-2"
                columns={columns}
                data={respostas}
                filterState={[filters, setFilters]}
                ordering={[ordering, setOrdering]}
                rowOnClick={onRowClick}
            />
        </TemplateLeftBar>
    )
}