import React from 'react';

interface Option {
    id: number;
    text: string;
}

interface CardPerguntasProps {
    title: string;
    description: string;
    options: Option[];
    selectedOption: number | null;
    onOptionChange: (id: number) => void;
}

export default function CardPerguntas({
                                          title,
                                          description,
                                          options,
                                          selectedOption,
                                          onOptionChange
                                      }: CardPerguntasProps) {
    return (
        <div className="w-90p bg-white-1 shadow-md rounded-lg overflow-hidden mb-4">
            {/* Card Header (Title and Description) */}
            <div className="px-6 py-4">
                <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
                <p className="text-sm text-gray-600 mt-1">{description}</p>
            </div>

            {/* Card Body (Options) */}
            <div className="px-6 py-4">
                {/* Options */}
                <div>
                    {options.map(option => (
                        <div key={option.id} className="mb-4 flex items-center">
                            <input
                                type="radio"
                                className="form-radio"
                                id={`option-${option.id}`}
                                name={`question-${title}`}
                                value={option.id}
                                checked={selectedOption === option.id}
                                onChange={() => onOptionChange(option.id)}
                            />
                            <label htmlFor={`option-${option.id}`} className="ml-2 flex-grow">{option.text}</label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
