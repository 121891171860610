import {Modal} from "@mui/material";
import {serviceInsertEixo} from "api/EixoService";
import {useModalContext} from "contexts/ModalContext";
import {useForm} from "react-hook-form";

export default function ModalCriarEixo
(
    {
        modalState: [modalOpen, setModalOpen],
        refetch,
    }:
        {
            modalState: [boolean, (modalOpen: boolean) => void],
            refetch: () => void
        }
) {
    const {openNotification} = useModalContext();
    const {register, handleSubmit, reset} = useForm();

    async function handleFormSubmit(data: any) {
        const nome = data.nome;
        const descricao = data.descricao;

        try {
            const response = await serviceInsertEixo({nome: nome, descricao: descricao});
            openNotification('Eixo criado com sucesso', 'success');
            refetch();
        } catch (error: any) {
            console.log(error);
            const message = error.response.data && error.response.data.error ? error.response.data.error
                : 'Erro desconhecido';

            openNotification(message, 'error');
        }

        reset();

    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <form className="bg-white-1 w-[50rem] px-16 h-[20rem] mx-auto rounded-[1rem]"
                  onSubmit={handleSubmit(handleFormSubmit)}>

                <p className="mt-[2.5%] text-[#132D46] font-raleway text-center font-bold text-lg">
                    Criar Novo Eixo
                </p>

                <div className="mt-[2%] mb-[2%] h-[0.063rem] border-[0.063rem] bg-gray-500"></div>

                <p className="text-[#4F4F4F] font-raleway font-bold text-sm">Nome</p>
                <input
                    className="w-full bg-[#EFF2F7] opacity-30 rounded-[0.625rem]"
                    type="text"
                    {...register('nome', {required: true})}
                />

                <p className="mt-4 text-[#4F4F4F] font-raleway font-bold text-sm">Descrição</p>
                <textarea
                    className="w-full bg-[#EFF2F7] opacity-30 rounded-[0.625rem]"
                    {...register('descricao', {required: false})}
                />

                <button
                    className="bg-[#01C38E] mt-3 rounded-[0.75rem] w-28 text-sm h-10 text-[#FFFFFf]"
                    type="submit"
                >
                    Criar Eixo
                </button>

            </form>

        </Modal>
    );


}
