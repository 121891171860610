import {useState} from "react";
import {AiFillCalendar, AiOutlineClose} from "react-icons/ai";
import CalendarInput from "./CalendarInput";

interface SearchDateProps {
    column: [columnValue: any, setColumnValue: Function],
    keyValue: string,
}

export default function SearchDate({column: [columnValue, setColumnValue], keyValue}: SearchDateProps) {
    const [showInput, setShowInput] = useState(false);
    const columnFilterValue = columnValue[keyValue] ?? '';

    return (
        <div
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowInput(false);
                }
            }}
            tabIndex={0}
        >
            <AiFillCalendar
                className="select-none cursor-pointer"
                onClick={() => setShowInput(!showInput)}
            />
            {
                showInput && (
                    <div
                        className={`flex items-center absolute bg-white border-[1px] border-[#101010] rounded-lg p-1`}
                    >
                        <CalendarInput
                            className="w-[fit] rounded-lg mx-1 border-[1px] border-[#132D46] p-1 text-[#132D46]"
                            onChange={value => setColumnValue({...columnValue, [keyValue]: value})}
                            placeholder={`Pesquise`}
                            type="date"
                            value={columnFilterValue}
                        />
                        <AiOutlineClose
                            className="bg-[#D9D9D9] select-none cursor-pointer mx-1 rounded-lg p-1"
                            onClick={() => {
                                setColumnValue({...columnValue, [keyValue]: ''});
                                setShowInput(false);
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
}
