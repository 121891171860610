import PerguntaAlternativas from 'interfaces/PerguntaAlternativa';
import React from 'react';

interface CardPerguntasProps {
    pergunta: PerguntaAlternativas;
}

export default function CardPerguntas({
                                          pergunta,
                                      }: CardPerguntasProps) {
    return (
        <div className="w-90p bg-white-1 shadow-md rounded-lg overflow-hidden mb-4">

            <div className="px-6 py-4">
                <h2 className="text-lg font-semibold text-gray-800">{pergunta.descricao}</h2>
                <p className="text-sm text-gray-600 mt-1">{pergunta.comentario}</p>
            </div>

            <div className="px-6 py-4">
                <div>
                    {pergunta.alternativas.map(alternativa => (
                        <div key={alternativa.id} className="mb-4 flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    id={`option-${alternativa.id}`}
                                    name={`question-${alternativa.id}`}
                                    value={alternativa.id}
                                    checked={false}
                                    readOnly={true}
                                />
                                <h1 className="ml-2 flex-grow">
                                    {alternativa.descricao}
                                </h1>
                            </div>

                            <div className="bg-gray-300 text-black-1 rounded-lg px-2 w-8 text-center">
                                {alternativa.fator_multiplicativo}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
