import React from "react";
import {SimpleQuestionario} from "interfaces/Questionario";
import {createColumnHelper} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
import TabelaHover from "components/Tables/TabelaHover";
import {filterFnDateEqual} from "components/Tables/components/Filters";
import {useGetQuestionarios} from "hooks/UseGetQuestionarios";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";

export default function TabelaQuestionarios() {
    const navigate = useNavigate();
    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const {questionarios, pagination, refetch} = useGetQuestionarios({
        page,
        pageSize,
        filters: {
            'qtd_respostas__gte': '2',
        },
        ordering
    });

    const columnHelper = createColumnHelper<SimpleQuestionario>();
    const columns = [
        columnHelper.accessor(
            'nome',
            {
                header: 'Questionário',
                cell: info => (
                    <div>
                        {info.row.original.nome}
                    </div>
                ),
            }
        ),
        columnHelper.accessor(
            'data_ultima_resposta',
            {
                header: 'Última resposta',
                cell: info => (
                    <div>
                        {
                            info.row.original.data_ultima_resposta === null ? 'Nunca' :
                                new Date(info.row.original.data_ultima_resposta!).toLocaleDateString()
                        }
                    </div>
                ),
                filterFn: filterFnDateEqual,
                meta: {
                    filterVariant: 'date'
                }
            }
        ),
    ];

    function rowOnClick(row: any) {
        navigate('/selecionar-respostas/' + row.original.id);
    }


    return (
        <TabelaHover data={questionarios} columns={columns} pageSize={10} rowOnClick={rowOnClick}/>
    )
}