import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import Filter from "./components/Filter";
import Sorter from "./components/Sorter";

/** Tabela cujas funções de paginação, ordenação e filtro são manuais
 *
 * @param {string} className - Classes CSS adicionais
 * @param {Array} data - Lista de dados a serem exibidos na tabela
 * @param {Array} columns - Lista de colunas a serem exibidas na tabela
 *
 * @param {Array} ordering - Estado de ordenação da tabela, contém o nome da coluna que está sendo ordenada e a função para alterar o estado
 * @param {Array} filterValues - Estado de filtro da tabela, contém um objeto com os valores de filtro e a função para alterar o estado
 * @param {function} rowOnClick - Função a ser executada ao clicar em uma linha da tabela
 *
 */

interface ManualTableProps {
    className?: string,
    data: Array<any>,
    columns: Array<any>,

    ordering?: [sort: string, setSort: Function],
    filterState?: [filters: {}, setFilters: Function],
    rowOnClick?: Function,
}

export default function ManualTable(
    {
        className = "",

        data,
        columns,

        ordering: [sort, setSort] = ["", () => {
        }],
        filterState: [filter, setFilter] = [{}, () => {
        }],
        rowOnClick,
    }: ManualTableProps) {
    const table = useReactTable({
        data: data,
        columns: columns,

        getCoreRowModel: getCoreRowModel(),

        manualPagination: true,
        manualFiltering: true,

    })

    function getHeader(headerGroup: any) {
        return (
            <tr
                className={`text-left bg-gray-light text-blue-smart_city font-bold bg-opacity-50 text-lg`}
                key={headerGroup.id}
            >
                {
                    headerGroup.headers.map((header: any) => (
                        <th
                            key={header.column.id}
                            className={`p-4 w-[${header.column.getSize()}px]`}
                        >
                            <div className="flex items-center justify-between ">
                                <div>
                                    {
                                        flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )
                                    }
                                </div>
                                <div className="flex items-center justify-between">
                                    {
                                        header.column.getCanFilter() ? (
                                            <div className="mx-[1px] relative">
                                                <Filter
                                                    column={[filter, setFilter]}
                                                    columnMetadata={header.column}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    {
                                        header.column.getCanSort() ? (
                                            <div className="mx-[1px] relative">
                                                <Sorter
                                                    sortState={[sort, setSort]}
                                                    columnMetadata={header.column}
                                                />
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </th>
                    ))
                }
            </tr>
        )
    }

    function getBody(row: any) {
        return (
            <tr
                className=
                    {`bg-white-1 text-gray-5 font-medium font-raleway text-[0.875rem] ${rowOnClick ? 'cursor-pointer' : ''}`}
                onMouseEnter={(e) => {
                    e.currentTarget.classList.remove("bg-white-1");
                    e.currentTarget.classList.add("bg-gray-200");
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.classList.remove("bg-gray-200");
                    e.currentTarget.classList.add("bg-white-1");
                }}
                onClick={() => rowOnClick ? rowOnClick(row.original) : null}
                key={row.id}
            >
                {
                    row.getVisibleCells().map((cell: any) => (
                        <td
                            className={`p-4 text-base font-medium`}
                            key={cell.column.id}
                        >
                            {
                                flexRender(cell.column.columnDef.cell, cell.getContext())
                            }
                        </td>
                    ))
                }
            </tr>
        )
    }

    function getFooter(footerGroup: any) {
        return (
            <tr
                className={`text-left bg-[#D9D9D9] text-[#132D46] font-raleway bg-opacity-50 text-[0.8rem] border-t-[2px] border-[#353535]`}
                key={footerGroup.id}
            >
                {
                    footerGroup.headers.map((header: any) => (
                        <th className="px-2 py-1 text-center"
                            key={header.column.id}
                        >
                            {flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                            )}
                        </th>
                    ))
                }
            </tr>
        )
    }


    return (
        <>
            <table className={className}>
                <thead>
                {table.getHeaderGroups().map(headerGroup => getHeader(headerGroup))}
                </thead>

                <tbody>
                {table.getRowModel().rows.map(row => getBody(row))}
                </tbody>

                {/* <tfoot>
                {table.getFooterGroups().map(footerGroup => getFooter(footerGroup))}
                </tfoot> */}
            </table>
        </>
    )
}