import Radar from "components/Graficos/Radar";
import {atenEixoToDataset} from "utils/AtenEixoToDataset";
import {AtendimentoEixo} from "interfaces/Eixo";
import TabelaAtendimento from "components/Tables/TabelaAtendimento";
import {RgbaCores, RgbaCoresReduzidas} from "utils/Cores";

export default function ResultadoObtidoTab({resultados}: { resultados: AtendimentoEixo[] }) {
    return (
        <div className="items-center justify-left flex">
            <div className="w-[40%] mr-8 rounded-3xl shadow-xl p-2">
                <Radar
                    data={
                        atenEixoToDataset({
                            graficos: [{
                                label: 'Resultado Obtido',
                                resultado: resultados,
                                backgroundColor: RgbaCoresReduzidas.AzulRgba,
                                borderColor: RgbaCores.AzulRgba,
                                fill: false
                            }]
                        })}
                    op={2}
                />
            </div>
            <div className="w-[40%]">
                <div className="mb-4 font-raleway font-bold text-[1rem] text-gray-5 ">
                    Taxa de atendimento por eixo
                </div>
                <TabelaAtendimento resultado={resultados}/>
            </div>
        </div>
    );
}
