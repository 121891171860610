import {api, fileApi} from "./api";

type getUsuariosProps = {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export async function getUsuarios({page = 1, pageSize = 10, filters, ordering}: getUsuariosProps) {
    const response = await api.get('usuario/', {params: {page, page_size: pageSize, ordering, ...filters}});
    return response;
}

export async function getUsuario({idUsuario}: { idUsuario: number }) {
    const response = await api.get('usuario/' + idUsuario);
    return response;
}

export async function criarUsuario(
    {
        login, nome_completo, email, senha, nivel_permissao, cidade, estado, organizacao_id

    }: {
        login: string;
        nome_completo: string;
        email: string;
        senha: string;
        nivel_permissao: number;
        cidade: string;
        estado: string;
        organizacao_id: number;
    }) {

    const response = await api.post('usuario/',
        {
            login: login,
            nome_completo: nome_completo,
            email: email,
            password: senha,
            nivel_permissao: nivel_permissao,
            cidade: cidade,
            estado: estado,
            organizacao_id: organizacao_id
        });

    return response;
}

export async function deleteUsuario({idUsuario}: { idUsuario: number }) {
    const response = await api.delete('usuario/' + idUsuario);
    return response;
}

export async function serviceUpdateUsuario({id, nome, email, senha, foto}: {
    id: number,
    nome?: string,
    email?: string,
    senha?: string,
    foto?: File
}) {
    const formData = new FormData();

    if (nome !== undefined) {
        formData.append('nome', nome);
    }

    if (email !== undefined) {
        formData.append('email', email);
    }

    if (senha !== undefined) {
        formData.append('password', senha);
    }

    if (foto) {
        formData.append('foto', foto);
    }

    const response = await fileApi.put('usuario/' + id, formData);
    return response;
}

export async function serviceUpdateUsuario2({
                                                id,
                                                login,
                                                nome_completo,
                                                password,
                                                nivel_permissao,
                                                email,
                                                organizacao_id,
                                                cidade,
                                                estado
                                            }
                                                : {
    id: number,
    login?: string,
    nome_completo?: string,
    password?: string,
    nivel_permissao?: number,
    email?: string,
    organizacao_id?: number,
    cidade?: string,
    estado?: string
}) {
    const data: { [key: string]: any } = {};

    if (login !== undefined) data.nome = login;
    if (nome_completo !== undefined) data.nome_completo = nome_completo;
    if (password !== undefined) data.password = password;
    if (nivel_permissao !== undefined) data.nivel_permissao = nivel_permissao;
    if (email !== undefined) data.email = email;
    if (organizacao_id !== undefined) data.organizacao_id = organizacao_id;
    if (cidade !== undefined) data.cidade = cidade;
    if (estado !== undefined) data.estado = estado;

    const response = await api.put('usuario/' + id, data);
    return response;
}

export async function getEstatisticasUsuario(id: number) {
    const response = await api.get('/questionario/estatisticas-respostas-usuario/' + id);
    return response;
}

export async function getEstatisticasQuestionariosUsuario(id: number) {
    const response = await api.get('/questionario/estatisticas-questionarios-usuario/' + id);
    return response;
}


