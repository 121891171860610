import {Autocomplete, Modal, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {Organizacao, RamoAtividade} from "interfaces/Organizacao";
import {getRamosAtividade, updateOrganizacao} from "api/OrganizacaoService";
import {useEffect, useState} from "react";
import CustomDropdown from "pages/Administrar/AdministrarUsuarios/NovoUsuario/components/DropdownHorroroso";
import CustomDropdownRamos from 'pages/Administrar/AdministrarOrganizacoes/NovaOrganizacao/components/DropdownRamos';
import "./styles.css";
import { useModalContext } from "contexts/ModalContext";


interface FormOrganizacaoValues {
    nome: string;
    descricao: string | null;
}

export default function ModalEditarOrganizacao(
    {
        modalState: [modalOpen, setModalOpen],
        organizacao,
        refetch
    }: {
        modalState: [boolean, (modalOpen: boolean) => void],
        organizacao: Organizacao,
        refetch: () => void
    }
) {
    const {openNotification} = useModalContext();
    const [ramos, setRamos] = useState<RamoAtividade[]>([]);
    const [selectedRamo, setSelectedRamo] = useState<RamoAtividade | null>(null);
    const [publica, setPublica] = useState<boolean>(organizacao.publica === true ? true : organizacao.publica === false ? false : true);

    useEffect(() => {
        async function fetchRamos() {
            try {
                const response = await getRamosAtividade();
                setRamos(response.data);
                const ramoOrganizacao = organizacao.ramo_atividade
                const findRamo = response.data.find((ramo: RamoAtividade) => ramo.id === ramoOrganizacao.id!)
                setSelectedRamo(findRamo)
            } catch (error) {
                setRamos([]);
            }
        }

        fetchRamos();
    }, []);

    const {register, handleSubmit, reset, setValue} = useForm<FormOrganizacaoValues>({
        defaultValues: {
            nome: organizacao.nome,
            descricao: organizacao.descricao
        }
    });

    const handleFormSubmit = async (data: FormOrganizacaoValues) => {
        if (!selectedRamo) {
            openNotification("Selecione um ramo de atividade", "error");
            return;
        }

        try {
            const response = await updateOrganizacao({
                idOrganizacao: organizacao.id,
                nome: data.nome,
                descricao: data.descricao!,
                ramo_atividade_id: selectedRamo!.id!,
                publica: publica
            });
            refetch();
            openNotification("Organização editada com sucesso", "success");
            setModalOpen(false);
            reset();
        } catch (error: any) {
            const message = error.response?.data?.message || "Erro ao editar organização";
            openNotification(message, "error");
        }
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <form className="bg-white-1 w-[50rem] px-16 h-auto p-4 mx-auto rounded-xl"
                  onSubmit={handleSubmit(handleFormSubmit)}
            >

                <p className="mt-[2.5%] text-[#132D46] font-raleway text-center font-bold text-xl">
                    Editar Organização
                </p>

                <div className="mt-[2%] mb-[2%] h-[0.063rem] border-[0.063rem] bg-gray-500"></div>

                <p className="text-[#4F4F4F] font-raleway font-bold text-lg">Nome</p>
                <input
                    className="w-full bg-[#EFF2F7] opacity-70 rounded-[0.625rem]"
                    type="text"
                    {...register('nome', {required: true})}
                />

                <p className="mt-4 text-[#4F4F4F] font-raleway font-bold text-lg">Descrição</p>
                <textarea
                    className="w-full bg-[#EFF2F7] opacity-70 rounded-[0.625rem]"
                    {...register('descricao', {required: false})}
                />

                <div className="mt-4"/>
                <Autocomplete
                    className={'rounded-xl bg-white-2 px-2 py-1 border border-[#4B4B4B]/0.2'}
                    value={selectedRamo}
                    onChange={(event, newValue) => {
                        setSelectedRamo(newValue);
                    }}
                    options={ramos}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ramo de Atividade"
                            variant="outlined"
                        />
                    )}
                />

                <div className="mt-4"/>
                <Autocomplete
                    options={['Pública', 'Privada']}
                    className={'rounded-xl bg-white-2 px-2 py-1 border border-[#4B4B4B]/0.2'}
                    getOptionLabel={(option) => option}
                    value={publica === true ? 'Pública' : publica === false ? 'Privada' : 'Pública'}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, value) => {
                        setPublica(value === 'Pública');
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo de Organização"
                            variant="outlined"
                        />
                    )}
                />

                <div className="flex justify-center mt-4 space-x-4">
                    <button
                        type="button"
                        className="bg-[#E44F4F] rounded-[0.75rem] w-28 text-sm h-10 cancel-button"
                        onClick={() => setModalOpen(false)}
                    >
                        Cancelar
                    </button>
                    <button
                        className="bg-[#01C38E] rounded-[0.75rem] w-28 text-sm h-10 text-[#FFFFFF]"
                        type="submit"
                    >
                        Editar Organização
                    </button>
                </div>

            </form>

        </Modal>
    );
}