import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {TemplateLeftBar} from 'components';
import {Header} from 'components/Headers/HeaderFelipe';
import {createRamoAtividade} from 'api/OrganizacaoService';
import {ModalAviso} from "components/Modals";
import { useModalContext } from 'contexts/ModalContext';
import { useNavigate } from 'react-router-dom';

interface FormData {
    nome: string;
    descricao: string;
}

export default function NovoRamo() {
    const {register, handleSubmit, reset, formState: {errors}} = useForm<FormData>();

    const { openNotification } = useModalContext();
    const navigate = useNavigate();

    async function onSubmit(data: FormData) {
        try {
            await createRamoAtividade({nome: data.nome, descricao: data.descricao});

            openNotification("Ramo de atividade criado com sucesso!", "success");
            navigate('/painel-ramos');
        } catch (error: any) {
            const message = error.response?.data?.message || "Erro ao criar ramo de atividade!";
            openNotification(message, "error");
        }
    }

    return (
        <TemplateLeftBar>
            <div className="w-full">
                <Header fluxo={[
                    {nome: 'Administrador', path: '/opcoes-administrador'},
                    {nome: 'Ramos de Atividades', path: '/painel-ramos'},
                    {nome: 'Novo Ramo de Atividade', path: '/novo-ramo'}
                ]}/>

                <div className="flex justify-between">
                    <div className="mt-3 text-2xl font-raleway font-bold">
                        <h1>Novo Ramo de Atividade</h1>
                    </div>
                </div>

                <div className="bg-white-1 p-6 mt-4 rounded-xl shadow-xl">
                    <form onSubmit={handleSubmit(onSubmit)} id="formCriarRamo">
                        <div className="w-1/2">
                            <label className="block mb-2">Nome</label>
                            <input
                                className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl" {...register('nome', {required: true})} />
                            {errors.nome && <span className="text-red-600">Este campo é obrigatório</span>}
                        </div>

                        <div className="w-1/2 mt-4">
                            <label className="block mb-2 ml-2">Descrição</label>
                            <textarea className="w-full border bg-[#F1F1F1] border-gray-200 p-[0.3em] rounded-xl"
                                      rows={5} {...register('descricao', {required: true})} />
                            {errors.descricao && <span className="text-red-600">Este campo é obrigatório</span>}
                        </div>
                    </form>

                </div>

                <button type="submit" form="formCriarRamo"
                        className="mt-4 py-[1em] px-[2em] bg-[#01C38E] text-white-1 rounded-xl">
                    Salvar
                </button>
            </div>
        </TemplateLeftBar>
    );
}
