import {HashLink as Link} from "react-router-hash-link";

export default function SectionHome() {
    return (
        <div className='flex w-auto h-auto'>

            <div className='w-2/5 flex flex-col justify-center items-center p-28 gap-8'>

                <div className='text-6xl font-extrabold text-blue-login'>Transforme sua <br/> cidade, transforme <br/> o
                    mundo!
                </div>

                <div className='flex items-center justify-start font-extrabold text-2xl text-white-1 w-full'>
                    <Link className='bg-green-smart_city rounded-lg px-[4em] py-[0.5em]' to={'/login'}>Ir para
                        SmartCity</Link>
                </div>
            </div>

            <div className='w-3/5'>
                <img className='w-full h-full overflow-hidden'
                     src={require('../../../assets/images/homeImages/image-page-01.png')} alt=""/>
            </div>

        </div>
    )
}