type DebouncedInputProps = {
    value: string
    onChange: (value: string) => void
    debounce?: number
    className?: string
    placeholder?: string
}

export default function DebouncedInput(
    {
        value: initialValue,
        onChange,
        className = '',
        placeholder = ''
    }: DebouncedInputProps) {

    return (
        <input placeholder={placeholder} className={className} type="text" value={initialValue}
               onChange={e => onChange(e.target.value)}/>
    )
}