// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes loading-gradient {
    0% {
        -webkit-mask-position: 100% 0;
                mask-position: 100% 0;
    }
    100% {
        -webkit-mask-position: 0 0;
                mask-position: 0 0;
    }
}

.loading-svg circle {
    stroke-dasharray: 0, 251.2;
    animation: loading-gradient 2s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingScreen/styles.css"],"names":[],"mappings":"AAAA;IACI;QACI,6BAAqB;gBAArB,qBAAqB;IACzB;IACA;QACI,0BAAkB;gBAAlB,kBAAkB;IACtB;AACJ;;AAEA;IACI,0BAA0B;IAC1B,8CAA8C;AAClD","sourcesContent":["@keyframes loading-gradient {\n    0% {\n        mask-position: 100% 0;\n    }\n    100% {\n        mask-position: 0 0;\n    }\n}\n\n.loading-svg circle {\n    stroke-dasharray: 0, 251.2;\n    animation: loading-gradient 2s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
