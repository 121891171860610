import SearchDate from "./Filters/SearchDate";
import SearchSelect from "./Filters/SearchSelect";
import SearchText from "./Filters/SearchText";

interface FilterProps {
    column: [columnValue: any, setColumnValue: Function],
    columnMetadata: any,
}

export default function Filter({column: [columnValue, setColumnValue], columnMetadata}: FilterProps) {
    const {filterVariant, filterKey, filterOptions, shit} = columnMetadata.columnDef.meta ?? {}

    const key = filterKey ?? columnMetadata.id;

    return filterVariant === 'select' ? (
        <SearchSelect column={[columnValue, setColumnValue]} keyValue={key} options={filterOptions}/>
    ) : filterVariant === 'date' ? (
        <SearchDate column={[columnValue, setColumnValue]} keyValue={key}/>
    ) : (
        <SearchText column={[columnValue, setColumnValue]} keyValue={key}/>
    )
}
