export default function SectionFooter() {
    return (
        <div className='flex gap-20 bg-white-1 p-20 justify-center items-center'>

            <div className=''>
                <img className='w-full h-full' src={require("../../../assets/images/homeImages/smarty-logo-02.png")}
                     alt=""/>
            </div>

            <div className=''>
                <img className='w-full h-full' src={require("../../../assets/images/homeImages/gaia-logo-01.png")}
                     alt=""/>
            </div>

            <div className=''>
                <img className='w-full h-full' src={require("../../../assets/images/homeImages/uel-logo-01.png")}
                     alt=""/>
            </div>

        </div>
    )
}