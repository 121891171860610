import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {TemplateLeftBar} from 'components';
import {Header} from 'components/Headers/HeaderFelipe';
import {criarUsuario} from "api/UsuarioService";
import {Organizacao} from 'interfaces/Organizacao';
import {useGetOrganizacoes} from 'hooks/UseGetOrganizacoes';
import useGetManualTableFilters from 'hooks/UseGetManualTableFilters';
import {Autocomplete, TextField} from '@mui/material';
import {useModalContext} from 'contexts/ModalContext';
import {useNavigate} from 'react-router-dom';

interface FormData {
    nome_completo: string;
    email: string;
    login: string;
    senha: string;
    estado: string;
    cidade: string;
    nivelPermissao: number;
}

export default function NovoUsuario() {
    const {openNotification} = useModalContext();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<FormData>();
    const navigate = useNavigate();

    const {filters, setFilters} = useGetManualTableFilters(20);
    const {organizacoes} = useGetOrganizacoes({filters});

    const [selectedOrganizacao, setSelectedOrganizacao] = useState<Organizacao | null>(null);

    const niveisPermissao = [
        {nivelPermissao: 0, nome: 'Usuário'},
        {nivelPermissao: 1, nome: 'Pesquisador'},
        {nivelPermissao: 2, nome: 'Administrador'}
    ];
    const [selectedOption, setSelectedOption] = useState(niveisPermissao[0]);

    async function onSubmit(data: FormData) {
        if (!selectedOrganizacao) {
            openNotification("Selecione uma organização", "error");
            return;
        }
        if (!selectedOption) {
            openNotification("Selecione um nível de permissão", "error");
            return;
        }

        try {
            const response = await criarUsuario({
                nome_completo: data.nome_completo,
                estado: data.estado,
                cidade: data.cidade,
                organizacao_id: selectedOrganizacao.id,
                login: data.login,
                email: data.email,
                senha: data.senha,
                nivel_permissao: selectedOption.nivelPermissao
            });

            openNotification("Usuário criado com sucesso!", "success");
            navigate('/painel-usuarios');
        } catch (error: any) {
            console.log(error)
            const message = error?.response?.data?.message || 'Erro ao criar usuário';
            openNotification(message, "error");
        }

    }

    return (
        <TemplateLeftBar>
            <div className="w-full">
                <Header fluxo={[
                    {nome: 'Administrador', path: '/opcoes-administrador'},
                    {nome: 'Usuário', path: '/painel-usuarios'},
                    {nome: 'Novo Usuário', path: '/novo-usuario'}
                ]}/>

                <div className="flex justify-between">
                    <div className="mt-3 text-2xl font-raleway font-bold">
                        <h1>Novo Usuário</h1>
                    </div>
                </div>
                <div className="bg-white-1 p-6 mt-4 rounded-xl shadow-xl">
                    <form onSubmit={handleSubmit(onSubmit)} id="formCriarUsuario">
                        <div className="w-1/2">
                            <label className="block mb-2">Nome</label>
                            <input
                                className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl" {...register('nome_completo', {required: true})} />
                            {errors.nome_completo && <span className="text-red-600">Este campo é obrigatório</span>}
                        </div>

                        <div className="w-1/2 mt-4">
                            <label className="block mb-2 ml-2">Email</label>
                            <input
                                className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl"
                                {...register('email', {
                                    required: "Insira um email válido",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Insira um email válido"
                                    }
                                })}
                            />
                            {errors.email && <span className="text-red-600">{errors.email.message}</span>}
                        </div>

                        <div className="w-1/4 mt-4">
                            <label className="block mb-2 ml-2">Login</label>
                            <input
                                className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl" {...register('login', {required: true})} />
                            {errors.login && <span className="text-red-600">Este campo é obrigatório</span>}
                        </div>

                        <div className="flex mt-4">
                            <div className="w-1/4 pr-1">
                                <label className="block mb-2 ml-2">Senha</label>
                                <input type="password"
                                       className="w-full border bg-[#F1F1F1] border-gray-200 p-[0.3em] rounded-xl" {...register('senha', {required: true})} />
                                {errors.senha && <span className="text-red-600">Este campo é obrigatório</span>}
                            </div>
                        </div>

                        <div className="flex mt-4">
                            <div className="w-[5%] pr-1">
                                <label className="block mb-2 ml-2">Estado</label>
                                <input
                                    className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl" {...register('estado', {required: true})} />
                                {errors.estado && <span className="text-red-600">Este campo é obrigatório</span>}
                            </div>
                            <div className="w-[45%] pl-1">
                                <label className="block mb-2 ml-2">Cidade</label>
                                <input
                                    className="w-full border bg-[#F1F1F1] border-[#4B4B4B]/0.2 p-[0.3em] rounded-xl" {...register('cidade', {required: true})} />
                                {errors.cidade && <span className="text-red-600">Este campo é obrigatório</span>}
                            </div>
                        </div>

                        <div className="w-1/4 mt-4">
                            <label className="block mb-2 ml-2">Organização</label>
                            <Autocomplete
                                className={'rounded-xl bg-white-2 px-2 py-1 border border-[#4B4B4B]/0.2'}
                                options={organizacoes}
                                getOptionLabel={(option) => option.nome}
                                onChange={(event, value) => setSelectedOrganizacao(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Selecione a Organização"
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setFilters({});
                                            } else {
                                                setFilters({...filters, nome__icontains: e.target.value})
                                            }

                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className="w-1/4 mt-4">
                            <label className="block mb-2 ml-2">Grau de Permissão</label>
                            <Autocomplete
                                className={'rounded-xl bg-white-2 px-2 py-1 border border-[#4B4B4B]/0.2'}
                                options={niveisPermissao}
                                getOptionLabel={(option) => option.nome}
                                isOptionEqualToValue={(option, value) => option.nivelPermissao === value.nivelPermissao}
                                onChange={(event, value) => {
                                    if (value) {
                                        setSelectedOption(value);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Selecione o Nível de Permissão"
                                    />
                                )}
                            />
                        </div>


                    </form>

                </div>

                <button type="submit" form="formCriarUsuario"
                        className="mt-4 py-[1em] px-[2em]  bg-[#01C38E] text-white-1 rounded-xl">
                    Salvar
                </button>
            </div>
        </TemplateLeftBar>
    );
}
