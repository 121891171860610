import {updateRespostaQuestionario} from "api/RespostasService";
import {useModalContext} from "contexts/ModalContext";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {GerarRelatorio} from "utils/OpenNewWindowApi";

export default function ComentarQuestionario(
    {
        resposta,
        comentarioState: [comentario, setComentario]
    }:
        {
            resposta: UsuarioRespondeQuestionario
            comentarioState: [string, (comentario: string) => void]
        }
) {
    const {openNotification, openConfirmationModal} = useModalContext();

    async function handleComentario() {
        try {
            const response = await updateRespostaQuestionario({idResposta: resposta.id, comentario: comentario});
            openNotification("Respostas comentada com sucesso!", "success");
        } catch (error) {
            console.log('Erro ao comentar', error);
            openNotification("Erro ao comentar resposta", "error");
        }

    }

    function handleGerarPDF() {
        GerarRelatorio(resposta.id)
    }

    return (
        <div>
            <div className="bg-white-1 rounded-lg p-8">
                <p className='text-gray-5 text-lg font-bold'>
                    Comentários
                </p>
                <textarea
                    className="w-full border border-gray-300 rounded-lg p-2"
                    rows={12}
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                />


            </div>
            <div className='relative'>
                <div className='flex relative'>
                    <button className=" bg-[#01C38E] text-white-1 rounded-lg p-2 mt-2 right-28 top-4 absolute"
                            onClick={() => openConfirmationModal("Deseja comentar a resposta?", "success", () => handleComentario())}
                    >
                        Salvar comentário
                    </button>
                </div>
                <div className='flex relative'>
                    <button className=" bg-blue-smart_city text-white-1 rounded-lg p-2 mt-2 right-0 top-4 absolute"
                            onClick={handleGerarPDF}
                    >
                        Gerar PDF
                    </button>
                </div>
            </div>


        </div>
    )
}