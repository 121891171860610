import React from "react";
import ManualTable from "components/ManualTable";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import {SimpleQuestionario} from "interfaces/Questionario";
import {useGetQuestionarios} from "hooks/UseGetQuestionarios";
import {useNavigate} from "react-router-dom";
import SimpleQuestionarioRows from "components/GenericColumns/SimpleQuestionarioColumns";

export default function TabelaQuestionarios() {
    const navigate = useNavigate();

    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const {questionarios, pagination, refetch} = useGetQuestionarios({page, pageSize, filters, ordering});

    function onRowClick(row: SimpleQuestionario) {
        navigate('/administrar-questionario/' + row.id);
    }

    return (
        <div className="m-auto pt-[0.75rem] text-[0.75rem]">
            <ManualTable
                className="w-full m-auto mb-12 rounded-lg shadow-lg overflow-hidden"
                columns={SimpleQuestionarioRows()}
                data={questionarios}
                filterState={[filters, setFilters]}
                ordering={[ordering, setOrdering]}
                rowOnClick={onRowClick}
            />

            <ManualPaginationArrows
                currentPageState={[page, setPage]}
                lastPage={Math.ceil(pagination.count / pageSize)}
            />
        </div>
    );
}
