import {HashLink as Link} from 'react-router-hash-link';

export default function HomeNavbar() {
    return (
        <div>
            <div className='flex shadow-xl'>

                <div className='w-1/5 p-6 ml-20'>
                    <img className='w-auto' src={require("../../../assets/images/homeImages/smarty-logo-01.png")}
                         alt=""/>
                </div>

                <div className='w-3/5 flex items-center justify-evenly font-medium text-2xl text-blue-login'>
                    <Link smooth to="#home">Home</Link>
                    <Link smooth to="#sobre">Sobre</Link>
                    <Link smooth to="#comoFunciona">Como Funciona</Link>
                    <Link smooth to="#framework">Frameworks</Link>
                    <Link smooth to="#feedbacks">Feedbacks</Link>
                </div>

                <div className='w-1/5 flex items-center justify-center font-extrabold text-2xl text-white-1'>
                    <Link className='bg-green-smart_city rounded-lg px-[1.5em] py-[0.5em]' to={'/login'}>Ir para
                        SmartCity</Link>
                </div>

            </div>
        </div>
    )
}