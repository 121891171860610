import {SimpleQuestionario} from "interfaces/Questionario";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import SelecionarResposta from "./SelecionarResposta";
import VisualizarResposta from "./VisualizarResposta";

export default function RespostasIndividuais(
    {
        questionario,
        respostaState: [resposta, setResposta],
    }:
        {
            questionario: SimpleQuestionario,
            respostaState: [UsuarioRespondeQuestionario | null, (resposta: UsuarioRespondeQuestionario | null) => void],
        }
) {

    if (!resposta) {
        return <SelecionarResposta questionario={questionario} respostaState={[resposta, setResposta]}/>
    }

    return <VisualizarResposta questionario={questionario} resposta={resposta!}/>
}