import {Eixo} from "interfaces/Eixo";
import {api} from "./api";
import PerguntaAlternativas from "interfaces/PerguntaAlternativa";

export async function serviceCriarQuestionario() {
    const response = await api.post('questionario/');
    return response;
}

export async function serviceDuplicarQuestionario({idQuestionario}: { idQuestionario: number }) {
    const response = await api.post('questionario/duplicar-questionario/' + idQuestionario);
    return response;
}

export async function serviceBuscarQuestionario({idQuestionario}: { idQuestionario: number }) {
    const response = await api.get('questionario/' + idQuestionario);
    return response;
}

export async function responderQuestionario({idQuestionario, respostas}:
                                                {
                                                    idQuestionario: number,
                                                    respostas: Array<{ pergunta_id: number, alternativa_id: number }>
                                                }) {
    const response = await api.post('questionario/responder/' + idQuestionario, {respostas: respostas});
    return response;
}

export async function serviceAtualizarNomeDescricaoQuestionario({idQuestionario, nome, descricao, situacao}:
                                                                    {
                                                                        idQuestionario: number,
                                                                        nome: string,
                                                                        descricao: string,
                                                                        situacao: number
                                                                    }) {

    const response = await api.put('questionario/atualizar-cabecalho/' + idQuestionario, {
        nome: nome,
        descricao: descricao,
        situacao: situacao
    });

    return response;
}

export async function serviceAtualizarEixosQuestionario({idQuestionario, eixos}:
                                                            { idQuestionario: number, eixos: Eixo[] }) {

    const response = await api.put('questionario/atualizar-eixos/' + idQuestionario, {eixos: eixos});

    return response;
}

export async function serviceNovaPerguntaQuestionario({idQuestionario}:
                                                          { idQuestionario: number }) {

    const response = await api.post('questionario/nova-pergunta/' + idQuestionario);

    return response;
}

export async function serviceDeletarPerguntaQuestionario({idPergunta}:
                                                             { idPergunta: number }) {

    const response = await api.delete('questionario/deletar-pergunta/' + idPergunta);

    return response;
}

export async function serviceDuplicarPerguntaQuestionario({idPergunta}:
                                                              { idPergunta: number }) {

    const response = await api.post('questionario/duplicar-pergunta/' + idPergunta);

    return response;
}

export async function serviceAtualizarPergunta({pergunta}: { pergunta: PerguntaAlternativas }) {

    // const body = {
    //     id: pergunta.id,
    //     descricao: pergunta.descricao,
    //     comentario: pergunta.comentario,
    //     alternativas: pergunta.alternativas
    // }

    const response = await api.put('questionario/atualizar-pergunta', pergunta);
    return response;
}