import {HashLink as Link} from "react-router-hash-link";

export default function SectionFeedbacks() {
    const equipe = [
        {
            nome: 'Rodolfo Barros',
            imagem: require("../../../assets/images/landing/rodolfo.jpg"),
            link: 'http://lattes.cnpq.br/5948037269019531'
        },
        {
            nome: 'Vanessa Barros',
            imagem: require("../../../assets/images/landing/vanessa.jpg"),
            link: 'http://lattes.cnpq.br/8337722352375779'
        },
        {
            nome: 'Felipe Abrahão',
            imagem: require("../../../assets/images/landing/felipe.png"),
            link: 'https://lattes.cnpq.br/1842663258265604'
        },
        {
            nome: 'Izabela Pereira',
            imagem: require("../../../assets/images/landing/iza.jpg"),
            link: ''
        },
        {
            nome: 'João Kikuti',
            imagem: require("../../../assets/images/landing/kikuti.jpg"),
            link: ''
        }
    ]
    return (
        <div className='bg-blue-login flex flex-col items-center justify-center p-24 gap-16'>

            <div className='text-5xl font-extrabold text-white-1'>
                Equipe
            </div>

            <div className='flex gap-10'>

                {
                    equipe.map((pessoa, index) => (
                        <div className='flex flex-col w-80 items-center gap-4 cursor-pointer' key={index}
                            onClick={() => {
                                pessoa.link && window.open(pessoa.link, '_blank')
                            }}
                        >

                            <div className='w-72 h-72 rounded-full overflow-hidden'>
                                <img className='w-full h-full' src={pessoa.imagem} alt=""/>
                            </div>

                            <div
                                className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                                {pessoa.nome}
                            </div>

                            {
                                pessoa.link &&
                                <a className='h-auto w-full font-medium text-xl text-blue-600 underline text-center mt-auto'>
                                    Lattes
                                </a>
                            }

                        </div>
                    ))
                }

                {/* <div className='flex flex-col w-80 items-center gap-4'>

                    <div className='w-72 h-72 rounded-full overflow-hidden'>
                        <img className='w-full h-full' src={require("../../../assets/images/homeImages/people-01.png")}
                             alt=""/>
                    </div>

                    <div className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                        Rodolfo Barros
                    </div>

                    <div className='h-auto w-full font-medium text-xl text-white-1 text-center mt-auto'>
                        “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”
                    </div>

                    <div
                        className='h-auto w-full flex gap-4 font-medium text-lg text-white-1 text-left mt-auto justify-center'>
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                </div> */}

                {/* <div className='flex flex-col w-80 items-center gap-4'>

                    <div className='w-72 h-72 rounded-full overflow-hidden'>
                        <img className='w-full h-full' src={require("../../../assets/images/homeImages/people-02.png")}
                             alt=""/>
                    </div>

                    <div className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                        Vanessa Barros
                    </div>

                    <div className='h-auto w-full font-medium text-xl text-white-1 text-center mt-auto'>
                        “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”
                    </div>

                    <div
                        className='h-auto w-full flex gap-4 font-medium text-lg text-white-1 text-left mt-auto justify-center'>
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                </div>

                <div className='flex flex-col w-80 items-center gap-4'>

                    <div className='w-72 h-72 rounded-full overflow-hidden'>
                        <img className='w-full h-full' src={require("../../../assets/images/homeImages/people-03.png")}
                             alt=""/>
                    </div>

                    <div className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                        Felipe Abrahão
                    </div>

                    <div className='h-auto w-full font-medium text-xl text-white-1 text-center mt-auto'>
                        “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”
                    </div>

                    <div
                        className='h-auto w-full flex gap-4 font-medium text-lg text-white-1 text-left mt-auto justify-center'>
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                </div> */}

            </div>

            <div className='flex items-center justify-start font-bold text-xl text-white-1'>
                <Link className='bg-green-smart_city rounded-lg px-[2em] py-[0.5em]' to={'/login'}>Acessar plataforma
                    GAIA</Link>
            </div>

        </div>
    )
}