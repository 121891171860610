import {SimpleQuestionario} from "interfaces/Questionario";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import ResultadoObtidoTab from "./components/ResultadoObtidoTab";
import GenericTabs from "components/GenericTabs";
import ComparativoTab from "./components/ComparativoTab";
import RespostasTab from "./components/RespostasTab";

export default function VisualizarResposta
({
     questionario,
     resposta,
 }:
     {
         questionario: SimpleQuestionario,
         resposta: UsuarioRespondeQuestionario,
     }) {

    const tabs = [
        {
            axis_name: 'Resultado Obtido',
            children: (
                <ResultadoObtidoTab resultados={resposta.resultados!}/>
            )
        },
        {
            axis_name: 'Média do Questionário',
            children: (
                <ResultadoObtidoTab resultados={questionario.medias!}/>
            )
        },
        {
            axis_name: 'Comparativo',
            children: (
                <ComparativoTab resposta={resposta.resultados!} mediaQuestionario={questionario.medias!}/>
            )
        },
        {
            axis_name: 'Resumo das Respostas',
            children: (
                <RespostasTab resposta={resposta} questionario={questionario}/>
            )
        }
    ];


    return (
        <GenericTabs tabs={tabs}/>
    )
}