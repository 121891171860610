import {api} from "./api";

export async function getRamosAtividade() {
    const response = await api.get('organizacao/ramo-atividade')
    return response;
}

export async function createRamoAtividade({nome, descricao}: { nome: string, descricao: string }) {
    const response = await api.post('organizacao/ramo-atividade', {nome: nome, descricao: descricao});
    return response;
}

export async function updateRamoAtividade({idRamo, nome, descricao}: {
    idRamo: number,
    nome: string,
    descricao: string
}) {
    const response = await api.put('organizacao/ramo-atividade/' + idRamo, {nome: nome, descricao: descricao});
    return response;
}

export async function deleteRamoAtividade({idRamo}: { idRamo: number }) {
    const response = await api.delete('organizacao/ramo-atividade/' + idRamo);
    return response;
}

////////////////////////////////////// Organização //////////////////////////////////////

type GetOrganizacoesProps = {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export async function getOrganizacoes({page = 1, pageSize = 10, filters, ordering}: GetOrganizacoesProps) {
    const response = await api.get('organizacao/', {
        params: {
            page: page,
            page_size: pageSize,
            ...filters,
            ordering: ordering
        }
    });
    return response;
}

export async function createOrganizacao({nome, descricao, ramo_atividade_id, publica}: {
    nome: string,
    descricao: string,
    ramo_atividade_id: number,
    publica: boolean
}) {
    const response = await api.post('organizacao/', {
        nome: nome,
        descricao: descricao,
        ramo_atividade_id: ramo_atividade_id,
        publica: publica
    });
    return response;
}

export async function updateOrganizacao({idOrganizacao, nome, descricao, ramo_atividade_id, publica}: {
    idOrganizacao: number,
    nome: string,
    descricao: string,
    ramo_atividade_id: number,
    publica: boolean
}) {
    const response = await api.put('organizacao/' + idOrganizacao, {
        nome: nome,
        descricao: descricao,
        ramo_atividade_id: ramo_atividade_id,
        publica: publica
    });
    return response;
}

export async function deleteOrganizacao({idOrganizacao}: { idOrganizacao: number }) {
    const response = await api.delete('organizacao/' + idOrganizacao);
    return response;
}
