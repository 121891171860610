import {AiFillCalendar, AiOutlineClose, AiOutlineSearch} from "react-icons/ai";
import {useEffect, useMemo, useState} from "react";

function DebouncedInput({
                            value: initialValue,
                            onChange,
                            debounce = 50,
                            ...props
                        }) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}

function CalendarInput({
                           value: initialValue,
                           onChange,
                           debounce = 50,
                           ...props
                       }) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input type="date" {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}


function SearchText({column}) {
    const [showInput, setShowInput] = useState(false);
    const columnFilterValue = '';

    return (
        <div
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowInput(false);
                }
            }}
            tabIndex={0}
        >
            <AiOutlineSearch
                className="text-[#132D46] select-none cursor-pointer"
                onClick={() => setShowInput(!showInput)}
            />
            {
                showInput && (
                    <div
                        className={
                            `flex items-center absolute z-[1] bg-white border-[0.1rem] border-[#101010] rounded-lg p-1`
                        }
                    >
                        <DebouncedInput
                            className="w-48 rounded-lg mx-1 border-[0.1rem] border-[#132D46] p-1 bg-[white] "
                            onChange={value => column.setFilterValue(value)}
                            placeholder={`Pesquise`}
                            type="text"
                            value={columnFilterValue}
                        />
                        <AiOutlineClose
                            className="text-[#132D46] bg-[#D9D9D9] select-none cursor-pointer mx-1 rounded-lg p-1"
                            onClick={() => {
                                column.setFilterValue('');
                                setShowInput(false);
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
}

function SearchDate({column}) {
    const [showInput, setShowInput] = useState(false);
    const columnFilterValue = '';
    return (
        <div
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowInput(false);
                }
            }}
            tabIndex={0}
        >
            <AiFillCalendar
                className="text-[#132D46] select-none cursor-pointer"
                onClick={() => setShowInput(!showInput)}
            />
            {
                showInput && (
                    <div
                        className={
                            `flex items-center absolute z-[1] bg-[white] border-[1px] border-[#101010] rounded-lg p-1 
                            `
                        }
                    >
                        <CalendarInput
                            className="w-[fit] rounded-lg mx-1 border-[1px] border-[#132D46] p-1"
                            onChange={value => column.setFilterValue(value)}
                            placeholder={`Pesquise`}
                            type="date"
                            value={columnFilterValue}
                        />
                        <AiOutlineClose
                            className="text-[#132D46] bg-[#D9D9D9] select-none cursor-pointer mx-1 rounded-lg p-1"
                            onClick={() => {
                                column.setFilterValue('');
                                setShowInput(false);
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
}

export default function Filter({column}) {
    const columnFilterValue = column.getFilterValue()
    const {filterVariant} = column.columnDef.meta ?? {}

    const sortedUniqueValues = useMemo(() =>
            filterVariant === 'range'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys())
                    .sort()
                    .slice(0, 5000),
        [column.getFacetedUniqueValues(), filterVariant]
    );


    return filterVariant === 'select' ? (
        <select
            onChange={e => column.setFilterValue(e.target.value)}
            value={columnFilterValue?.toString()}
        >
            <option value="">Todos</option>
            {
                sortedUniqueValues.map(value => (
                    <option value={value} key={value}>
                        {value}
                    </option>
                ))
            }
        </select>
    ) : filterVariant === 'date' ? (
        <SearchDate column={column}/>
    ) : (
        <SearchText column={column}/>
    )
}