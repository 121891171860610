import {Eixo} from "interfaces/Eixo";
import {createColumnHelper} from "@tanstack/react-table";
import {useState} from "react";
import ModalSelecionarEixos from "./ModalSelecionarEixos";
import {TfiTrash} from "react-icons/tfi";
import {FaPlus} from "react-icons/fa6";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";
import ManualTable from "components/ManualTable";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import {useModalContext} from "contexts/ModalContext";

export default function EixosQuestionarioForm(
    {
        eixosState: [eixosQuestionario, setEixosQuestionario]
    }: {
        eixosState: [Eixo[], (eixos: Eixo[]) => void]
    }
) {
    const {openNotification} = useModalContext();
    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const [modalOpen, setModalOpen] = useState(false);


    const columnHelper = createColumnHelper<Eixo | any>();
    const columns = [
        columnHelper.accessor(
            'nome',
            {
                header: 'Eixo',
                cell: info => (
                    <div>
                        {info.row.original.nome}
                    </div>
                ),
                enableColumnFilter: false,
                enableSorting: false,
            }
        ),
        columnHelper.accessor(
            'descricao',
            {
                header: 'Descrição',
                cell: info => (
                    <div>
                        {info.row.original.descricao}
                    </div>
                ),
                enableColumnFilter: false,
                enableSorting: false,
            },
        ),
        columnHelper.accessor(
            ' ',
            {
                header: ' ',
                cell: info => (
                    <div className="ml-auto flex space-x-4">
                        <TfiTrash
                            className="text-red-500 cursor-pointer font-medium text-2xl"
                            onClick={() => {
                                if (eixosQuestionario.length === 1) {
                                    openNotification("O questionário deve ter ao menos um eixo", "error");
                                    return;
                                }
                                const newEixos = eixosQuestionario.filter(eixo => eixo.id !== info.row.original.id);
                                setEixosQuestionario(newEixos);
                            }}
                        />
                    </div>
                ),
                enableColumnFilter: false,
                enableSorting: false,
                size: 50
            }
        )
    ];

    return (
        <div className="bg-[#FFFFFF] rounded-[0.75rem] shadow-[0.75rem] p-5 relative">
            <div className="mb-3 font-bold text-base text-[#4F4F4F]">EIXOS DO QUESTIONÁRIO</div>
            <ManualTable
                className="w-full m-auto mb-12 rounded-lg shadow-lg overflow-hidden"
                columns={columns}
                data={eixosQuestionario.slice((page - 1) * pageSize, page * pageSize)}
            />

            <ManualPaginationArrows
                currentPageState={[page, setPage]}
                lastPage={Math.ceil(eixosQuestionario.length / pageSize)}
            />

            <button
                className="font-bold bg-[#132D46] text-sm text-white-1 flex items-center p-1 px-3 rounded"
                onClick={() => setModalOpen(true)}
            >
                <FaPlus className={'mr-2'}/> <p>Adicionar Eixo</p>
            </button>
            <ModalSelecionarEixos
                modalState={[modalOpen, setModalOpen]}
                eixosState={[eixosQuestionario, setEixosQuestionario]}
            />
            {/* <ModalEditarEixo
                modalState={[editModalOpen, setEditModalOpen]}
                eixo={selectedEixo}
                eixosState={[eixos, setEixos]}
            /> */}
        </div>
    )
}
