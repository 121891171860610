import LeftSidebar from "components/Bars/LeftBar";

export default function TemplateLeftBar({children}: { children: React.ReactNode }) {

    return (
        <div className="bg-white-2 h-[100%] w-[100%] flex mb-32">
            <LeftSidebar/>

            <div className="px-10 relative mx-auto w-[90%]">
                <div className="w-full">
                    {children}
                </div>
            </div>

        </div>
    )
}