export function filterFnDateGreater({row, columnId, value, addMeta}: any) {
    const inputDate = new Date(value)
    const rowDate = new Date(row.original[columnId])


    if (rowDate >= inputDate) {
        return true
    }

    return false
}

export function filterFnDateLess({row, columnId, value, addMeta}: any) {
    const inputDate = new Date(value)
    const rowDate = new Date(row.original[columnId])

    if (rowDate <= inputDate) {
        return true
    }

    return false
}

export function filterFnDateEqual(row: any, columnId: string, filterValue: any, addMeta: (meta: any) => void) {
    const inputDate = new Date(filterValue)

    const rowDate = new Date(row.original[columnId])

    inputDate.setHours(inputDate.getHours() + 3)
    if (rowDate.toDateString() === inputDate.toDateString()) {
        return true
    }

    return false
}

