import React, {useState} from "react";
import {IoDocumentTextSharp} from "react-icons/io5";
import {createColumnHelper} from "@tanstack/react-table";
import {Ativo} from "interfaces/Ativo";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import {FaEllipsisH} from "react-icons/fa";
import {BaixarAtivo, OpenAtivo} from "utils/OpenNewWindowApi";

export default function ColunasUsuarios() {
    const columnHelper = createColumnHelper<Ativo | any>();

    function ThreeDotsClick(row: any) {
        const [open, setOpen] = useState(false);

        const handleToggle = () => {
            setOpen(!open);
        };

        return (
            <div className="relative flex justify-end">
                <div className="flex gap-4 cursor-pointer text-sm font-medium underline">
                    <button className="text-gray-500"
                            onClick={async () => {
                                try {
                                    OpenAtivo(row.id)
                                } catch (error) {
                                }
                            }}
                    >
                        Visualizar
                    </button>
                    <button
                        onClick={async () => {
                            console.log(row)
                            try {
                                BaixarAtivo(row.id);
                            } catch (error) {
                            }
                        }}
                        className="text-gray-500"
                    >
                        Baixar
                    </button>
                    <FaEllipsisH className="ml-5" onClick={handleToggle}/>
                </div>
            </div>
        );
    }

    function NomeCell({info}: { info: any }) {
        const [expanded, setExpanded] = useState(false);

        const handleToggle = () => {
            setExpanded(!expanded);
        };

        return (
            <div className="flex p-2 items-center">
                <div onClick={handleToggle} className="cursor-pointer">
                    {expanded ? <IoIosArrowDown/> : <IoIosArrowForward/>}
                </div>
                <div>
                    <IoDocumentTextSharp className="mr-2 inline-block text-5xl text-green-smart_city"/>
                </div>
                <div>
                    <span className="inline-block font-bold text-base">{info.row.original.nome}</span>
                    <div className={`text-gray-500 text-wrap font-medium text-sm ${expanded ? "" : "line-clamp-1"}`}>
                        {info.row.original.path}
                    </div>
                </div>
            </div>
        );
    }

    return [
        columnHelper.accessor("nome", {
            header: "Nome",
            cell: (info) => <NomeCell info={info}/>,
        }),
        columnHelper.accessor("criado_em", {
            header: "Criado em",
            cell: (info) => {
                return new Date(info.row.original.criado_em).toLocaleDateString();

            },
        }),
        columnHelper.accessor("tamanho", {
            header: "Tamanho",
            cell: (info) => {
                return (
                    <p className="text-right pr-8">
                        {info.row.original.tamanho / 1000}MB
                    </p>
                )
            },
        }),
        columnHelper.accessor("usuario", {
            header: "Criado por",
            cell: (info) => {
                return info.row.original.usuario;
            },
        }),
        columnHelper.accessor(' ', {
            header: '',
            cell: info => {
                return ThreeDotsClick(info.row.original);
            },
            enableColumnFilter: false,
            enableSorting: false,
        }),
    ];
}
