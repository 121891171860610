import React, {useEffect, useRef, useState} from 'react';

interface PopoverButtonProps {
    options: string[];
}

export default function PopoverButton({options}: PopoverButtonProps) {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);

    const togglePopover = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left" ref={popoverRef}>
            <button
                onClick={togglePopover}
                className="p-2 rounded-full hover:bg-gray-200 focus:outline-none text-2xl mr-[1rem]"
            >
                ...
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white-1 border border-gray-200 rounded-lg shadow-lg">
                    {options.map((option, index) => (
                        <div key={index} className="p-4 hover:bg-gray-100 cursor-pointer">
                            <p className="text-sm text-gray-700">{option}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
