import {CellContext, createColumnHelper} from "@tanstack/react-table";
import {deleteRamoAtividade} from "api/OrganizacaoService";
import {TabelaPadrao} from "components";
import {RamoAtividade} from "interfaces/Organizacao";
import {useState} from "react";
import {TfiTrash} from "react-icons/tfi";
import {useNavigate} from "react-router-dom";
import ModalEditarRamoAtividade from "./components/ModalEditarRamoAtividade";
import {FaPen} from "react-icons/fa";
import {useModalContext} from "contexts/ModalContext";
import {useGetRamosAtividade} from "hooks/UseGetOrganizacoes";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import ManualTable from "components/ManualTable";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";

export default function TabelaRamoAtividade() {
    const navigate = useNavigate();
    const {openNotification, openConfirmationModal} = useModalContext();

    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const {ramosAtividade, refetch} = useGetRamosAtividade();

    const [selectedRamo, setSelectedRamo] = useState<RamoAtividade | null>(null);

    const [editModalOpen, setEditModalOpen] = useState(false);

    async function handleDeleteRamo(id: number) {
        try {
            await deleteRamoAtividade({idRamo: id});
            refetch();
            openNotification("Ramo de atividade excluído com sucesso", "success");
        } catch (error) {
            console.error(error);
            openNotification("Erro ao excluir ramo de atividade", "error");
        }
    };

    const confirmarExclusaoRamo = (id: number) => {
        openConfirmationModal("Tem certeza que deseja excluir este ramo de atividade?", "warning", () => handleDeleteRamo(id));
    };


    const columnHelper = createColumnHelper<RamoAtividade>();

    const columns = [
        columnHelper.accessor('nome', {
            header: 'Nome',
            cell: (info) => (
                <div className="relative rounded-md py-2">
                    {info.getValue()}
                </div>
            ),
            enableColumnFilter: false,
            enableSorting: false,
        }),
        columnHelper.accessor('descricao', {
            header: 'Descrição',
            cell: (info) => (
                <div className="relative rounded-md py-2">
                    {info.getValue()}
                </div>
            ),
            enableColumnFilter: false,
            enableSorting: false,
        }),
        {
            header: '',
            id: 'acoes',
            cell: (info: CellContext<RamoAtividade, unknown>) => (
                <div className="ml-auto flex space-x-4">
                    <FaPen
                        className="text-green-smart_city ml-auto cursor-pointer font-medium text-2xl"
                        onClick={() => {
                            setSelectedRamo(info.row.original);
                            setEditModalOpen(true);
                        }}
                    />
                    <TfiTrash
                        className="text-red-to_do cursor-pointer ml-auto font-medium text-2xl"
                        onClick={() => confirmarExclusaoRamo(info.row.original.id!)}
                    />
                </div>
            ),
        }

    ];

    function ShowModalEditarRamoAtividade() {
        return (
            <>
                {
                    editModalOpen &&
                    <ModalEditarRamoAtividade
                        modalState={[editModalOpen, setEditModalOpen]}
                        ramo={selectedRamo!}
                        refetch={refetch}
                    />
                }
            </>
        )
    }

    return (
        <>
            <ShowModalEditarRamoAtividade/>
            <div className={'m-auto pt-[0.75rem] text-[0.75rem]'}>

                <ManualTable
                    className="w-full m-auto mb-12"
                    columns={columns}
                    data={ramosAtividade}
                    filterState={[filters, setFilters]}
                    ordering={[ordering, setOrdering]}
                />

                <ManualPaginationArrows
                    currentPageState={[page, setPage]}
                    lastPage={Math.ceil(ramosAtividade.length / pageSize)}
                />

            </div>
        </>
    );

}
