import {createColumnHelper} from "@tanstack/react-table";
import {serviceDeleteEixo} from "api/EixoService";
import {Header} from "components/Headers/HeaderFelipe";
import {Eixo} from "interfaces/Eixo";
import {useState} from "react";
import ModalCriarEixo from "./components/ModalCriarEixo";
import {TfiTrash} from "react-icons/tfi";
import {FaPen, FaPlus} from "react-icons/fa";
import ModalEditarEixo from "./components/ModalEditarEixo";
import {useModalContext} from "contexts/ModalContext";
import {useGetEixos} from "hooks/UseGetEixos";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import {TemplateLeftBar} from "components";
import ManualTable from "components/ManualTable";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";

const columnHelper = createColumnHelper<Eixo | any>();

export default function AdministrarEixos() {
    const {openNotification} = useModalContext();
    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters();
    const {eixos, pagination, refetch} = useGetEixos({page, pageSize, filters, ordering});

    const [selectedEixo, setSelectedEixo] = useState<Eixo | null>(null);
    const [novoEixoOpen, setNovoEixoOpen] = useState(false);
    const [editEixoOpen, setEditEixoOpen] = useState(false);

    async function deleteEixo(id: number) {
        try {
            await serviceDeleteEixo(id);
            openNotification('Eixo deletado com sucesso', 'success');
            refetch();
        } catch (error: any) {
            console.log(error);
            const message = error.response?.data?.error ?? 'Erro desconhecido';
            openNotification(message, 'error');
        }
    }

    const columns = [
        columnHelper.accessor('nome', {
            header: 'Eixo',
            cell: (info) => <div>{info.row.original.nome}</div>,
            meta: {
                filterKey: 'nome__icontains',
            },
        }),
        columnHelper.accessor('descricao', {
            header: 'Descrição',
            cell: (info) => <div>{info.row.original.descricao}</div>,
            meta: {
                filterKey: 'descricao__icontains',
            },
        }),
        columnHelper.display({
            id: 'options',
            header: '',
            cell: (info) => (
                <div className="flex space-x-4">
                    <FaPen
                        className="text-green-smart_city ml-auto cursor-pointer font-medium text-2xl"
                        onClick={() => {
                            setSelectedEixo(info.row.original);
                            setEditEixoOpen(true);
                        }}
                    />
                    <TfiTrash
                        onClick={() => {
                            deleteEixo(info.row.original.id);
                        }}
                        className="text-[#FF0000] text-2xl font-medium cursor-pointer"
                    />
                </div>
            ),
            size: 1,
        }),
    ];

    function ShowEditarEixo() {
        return (
            <>
                {
                    editEixoOpen && (
                        <ModalEditarEixo
                            modalState={[editEixoOpen, setEditEixoOpen]}
                            eixo={selectedEixo!}
                            refetch={refetch}
                        />
                    )
                }
            </>
        )
    }

    function ShowCriarEixo() {
        return (
            <>
                {
                    novoEixoOpen && (
                        <ModalCriarEixo
                            modalState={[novoEixoOpen, setNovoEixoOpen]}
                            refetch={refetch}
                        />
                    )
                }
            </>
        )
    }


    return (
        <TemplateLeftBar>
            <Header fluxo={[
                {nome: 'Questionários', path: '/meus-questionarios'},
                {nome: 'Administrar Eixos', path: '/administrar-eixos'}
            ]}/>

            <ShowCriarEixo/>
            <ShowEditarEixo/>

            <div className={"flex justify-between relative"}>
                <div className={"mt-3 text-2xl font-raleway font-bold"}>
                    <h1>Administrar Eixos</h1>
                </div>

                <div className="mt-1 mx-1 flex items-center">
                    <div
                        className="p-2 rounded-lg justify-center items-center flex bg-[#01C38E]"
                        onClick={() => setNovoEixoOpen(true)}
                    >
                        <FaPlus className={'text-[#FFFFFF] mr-2'}/> <p
                        className="text-base cursor-pointer font-bold text-[#FFFFFF]">Novo Eixo</p>
                    </div>
                </div>
            </div>

            <div className={'bg-white-2 px-6 py-2 rounded mt-5 w-full'}>
                <div className="m-auto pt-[0.75rem] text-[0.75rem]">
                    <ManualTable
                        className="w-full m-auto mb-12 rounded-lg shadow-lg overflow-hidden"
                        columns={columns}
                        data={eixos}
                        filterState={[filters, setFilters]}
                        ordering={[ordering, setOrdering]}
                    />

                    <ManualPaginationArrows
                        currentPageState={[page, setPage]}
                        lastPage={Math.ceil(pagination.count / pageSize)}
                    />
                </div>
            </div>

        </TemplateLeftBar>
    );
}
