import {useEffect, useState} from 'react';
import {SimpleQuestionario} from 'interfaces/Questionario';
import {PaginatedResponse, Pagination} from 'interfaces/Pagination';
import {useQuery} from '@tanstack/react-query';
import {serviceMeusQuestionarios} from 'api/ResultadosService';
import {serviceBuscarQuestionario} from 'api/QuestionarioService';

interface UseGetQuestionariosProps {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export function useGetQuestionarios({page = 1, pageSize = 10, filters, ordering}: UseGetQuestionariosProps) {
    const [questionarios, setQuestionarios] = useState<SimpleQuestionario[]>([]);
    const [pagination, setPagination] = useState<Pagination>({count: 0});
    const [debouncedParams, setDebouncedParams] = useState({page, pageSize, filters, ordering});
    const {data, refetch, error} = useQuery<PaginatedResponse<SimpleQuestionario>>({
        queryKey: ['questionarios', debouncedParams.page, debouncedParams.pageSize, debouncedParams.filters, debouncedParams.ordering],
        queryFn: async () => {
            const response = await serviceMeusQuestionarios({
                page: debouncedParams.page,
                pageSize: debouncedParams.pageSize,
                filters: debouncedParams.filters,
                ordering: debouncedParams.ordering,
            });
            return response.data;
        },
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedParams({page, pageSize, filters, ordering});
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page, pageSize, filters, ordering]);

    useEffect(() => {
        if (data) {
            setQuestionarios(data.results);
            setPagination({
                ...data
            });
        }
    }, [data]);

    return {questionarios, pagination, refetch, error};
}

export function useGetQuestionario(id: number) {
    const [questionario, setQuestionario] = useState<SimpleQuestionario | null>(null);

    const {data, refetch, error, isLoading} = useQuery<SimpleQuestionario>({
        queryKey: ['questionario', id],
        queryFn: async () => {
            const response = await serviceBuscarQuestionario({idQuestionario: id});
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setQuestionario(data);
        }
    }, [data]);

    return {questionario, setQuestionario, refetch, error, isLoading};
}