import {api} from "./api";
import {Eixo} from "interfaces/Eixo";

interface GetEixosProps {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export async function getEixos({page, pageSize, filters, ordering}: GetEixosProps) {
    const response = await api.get('eixo/', {
        params: {
            page: page,
            page_size: pageSize,
            ...filters,
            ordering: ordering
        }
    });
    return response;
}

export async function serviceDeleteEixo(id: number) {
    const response = await api.delete('eixo/' + id);
    return response;
}

export async function serviceInsertEixo({nome, descricao}: { nome: string, descricao: string }) {
    const response = await api.post('eixo/',
        {
            nome: nome,
            descricao: descricao
        }
    );

    return response;
}

export async function serviceUpdateEixo(eixo: Eixo) {
    const response = await api.put(`eixo/${eixo.id}/`,
        {
            descricao: eixo.descricao,
            nome: eixo.nome,
        }
    );

    return response;
}