interface ManualPaginationArrowsProps {
    currentPageState: [currentPage: number, setCurrentPage: Function],
    lastPage: number,
}

export default function ManualPaginationArrows(
    {currentPageState: [currentPage, setCurrentPage], lastPage}: ManualPaginationArrowsProps
) {

    return (
        <div className="flex justify-center text-gray-500 text-lg font-medium mt-4">
            <button
                type="button"
                onClick={() => setCurrentPage(1)}
                className="bg-[#FFFFFF] px-2 py-1 m-2 rounded-[0.5rem] ml-auto"
            >
                Primeiro
            </button>

            <button
                type="button"
                onClick={() => setCurrentPage(currentPage - 1)}
                className="bg-[#FFFFFF] px-2 py-1 m-2 rounded-[0.5rem]"
                disabled={currentPage === 1}
            >
                Anterior
            </button>

            <button
                type="button"
                onClick={() => setCurrentPage(currentPage + 1)}
                className="bg-[#01C38E] text-white-1 px-2 py-1 m-2 rounded-[0.5rem]"
            >
                <p className="pl-[1rem] pr-[1rem]">
                    {currentPage}
                </p>
            </button>

            <button
                type="button"
                onClick={() => setCurrentPage(currentPage + 1)}
                className="bg-[#FFFFFF] px-2 py-1 m-2 rounded-[0.5rem]"
                disabled={currentPage === lastPage}
            >
                Próximo
            </button>

            <button
                type="button"
                onClick={() => setCurrentPage(lastPage)}
                className="bg-[#FFFFFF] px-2 py-1 m-2 rounded-[0.5rem]"
            >
                Último
            </button>

        </div>
    )
}