import {SimpleQuestionario} from "interfaces/Questionario";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {useState} from "react";
import {FaMagnifyingGlass} from "react-icons/fa6";
import ModalSelecionarResposta from "./ModalSelecionarResposta";

export default function SelecionarResposta(
    {
        questionario,
        respostaState: [resposta, setResposta]
    }:
        {
            questionario: SimpleQuestionario,
            respostaState: [UsuarioRespondeQuestionario | null, (resposta: UsuarioRespondeQuestionario | null) => void]
        }
) {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            {
                modalOpen && (
                    <ModalSelecionarResposta
                        questionario={questionario}
                        setResposta={setResposta}
                        state={[modalOpen, setModalOpen]}
                    />
                )
            }

            <div className="w-full mx-4 my-1 font-raleway text-gray-2">
                <p className="text-base font-bold mb-2">
                    Respostas
                </p>
                <div
                    className="flex items-center"
                >

                    <button
                        className="bg-gray-light text-sm text-start pl-4 py-0.5 border-black-2 border-[0.063rem] rounded border-opacity-50 w-80"
                        onClick={() => setModalOpen(true)}
                    >
                        Pesquisar por respostas
                    </button>

                    <button
                        onClick={() => setModalOpen(true)}
                    >
                        <FaMagnifyingGlass className="w-3.5 h-3.5 fill-green-smart_city ml-3"/>
                    </button>

                </div>


                <p className="text-sx mt-1">
                    Selecione uma resposta para visualizar os resultados
                </p>
            </div>
        </>


    )
}