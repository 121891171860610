import {FaCircle} from "react-icons/fa";

export default function TabelaTaxasServico({data}: any) {
    return (

        <div className="mt-5">
            <p className="font-bold text-xl text-[#132D46]">
                Taxas de serviço
            </p>

            <table className="table-fixed w-full">
                <thead className="font-raleway font-bold text-base bg-[#F2F2F2]">
                <tr className="border-[#C0C0C0] border-[0.063rem]">
                    <th>
                            <span
                                className="px-12 py-3">
                                    Eixo
                            </span>
                    </th>
                    {
                        data.datasets.map((item: any) => (
                            <th>
                                    <span
                                        className="px-16 flex items-center justify-center text-center py-3 "
                                        key={item.label}>
                                            <FaCircle
                                                className={`w-3 h-3 mr-1 text-[${item.borderColor}]`}/>
                                        {item.label}
                                    </span>
                            </th>
                        ))
                    }
                </tr>
                </thead>
                <tbody className="text-[#132D46] text-base font-raleway font-bold bg-[#F2F2F2] bg-opacity-50">
                {
                    data.labels.map((item: any, index: number) => (
                        <tr key={index}>
                            <td className="py-3 pl-6 border-[#C0C0C0] border-[0.063rem] text-start">
                                {item}
                            </td>
                            {
                                data.datasets.map((dataset: any, indexDs: number) => (
                                    <td key={indexDs}
                                        className="py-3 px-12 border-[#C0C0C0] border-[0.063rem] text-center">
                                        {dataset.data[index]}%
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}