import {Eixo} from "interfaces/Eixo";
import PerguntaAlternativas from "interfaces/PerguntaAlternativa";
import {useEffect, useState} from "react";

interface TabelaPesosPerguntasProps {
    pergunta: PerguntaAlternativas;
    eixos: Eixo[];
}

interface EixoRow {
    eixo: Eixo;
    peso: number;
}

interface EixosRow {
    column1: EixoRow;
    column2: EixoRow | null;
}

export default function TabelaPesosPergunta(
    {
        pergunta,
        eixos
    }: TabelaPesosPerguntasProps) {

    const [eixosRows, setEixosRows] = useState<EixosRow[]>([]);

    useEffect(() => {
        const eixosRows: EixosRow[] = [];

        eixos.map((eixo, index) => {
            const eixo_id = eixo.id;
            const peso = pergunta.pesos!.find(peso => peso.eixo_id === eixo_id)?.peso || 0;

            // if the index is odd, we need to create a new row
            // else, append to column2
            if (index % 2 === 0) {
                eixosRows.push({
                    column1: {
                        eixo: eixo,
                        peso: peso
                    },
                    column2: null
                })
            } else {
                eixosRows[eixosRows.length - 1].column2 = {
                    eixo: eixo,
                    peso: peso
                }
            }

        })

        setEixosRows(eixosRows);

    }, [eixos]);

    return (
        <div className="w-full bg-[#D9D9D9] shadow-md rounded-2xl overflow-hidden mb-4">
            <h1 className="text-lg text-black-1 font-raleway font-bold py-4 px-8">
                Relação de Eixos e Pesos
            </h1>
            <table className="w-full">
                <tbody>
                {eixosRows.map((eixosRow, index) => (
                    <tr key={index} className={`flex items-center ${index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'}`}>
                        <div className="flex w-[50%] items-center justify-between">
                            <td className="px-8 py-2">{eixosRow.column1.eixo.nome}</td>
                            <td className="px-8 py-2">{eixosRow.column1.peso}</td>
                        </div>
                        <div className="flex w-[50%] items-center justify-between">
                            <td className="px-8 py-2">{eixosRow.column2?.eixo.nome}</td>
                            <td className="px-8 py-2">{eixosRow.column2?.peso}</td>
                        </div>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    );
}