import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './assets/global.css'

import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from './pages/Dashboard/Admin';
import {MeusQuestionarios} from "./pages/MeusQuestionarios";
import AdministrarQuestionario from "./pages/Administrar/AdministrarQuestionario";
import {QuestionarioProvider} from './contexts/QuestionarioContext';
import SelecionarQuestionario from './pages/Comparacao/Admin/SelecionarQuestionario';
import SelecionarRespostas from './pages/Comparacao/Admin/SelecionarRespostas';
import CompararRespostas from './pages/Comparacao/Admin/CompararRespostas';
import AdministrarEixos from 'pages/Administrar/AdministrarEixos';
import {UserProvider, useUserContext} from 'contexts/UserContext';
import NovoUsuario from "./pages/Administrar/AdministrarUsuarios/NovoUsuario";
import {ResponderQuestionario} from "./pages/ResponderQuestionario";
import DashboardUsuario from './pages/Dashboard/Usuario';
import QuestionariosDisponiveis from 'pages/QuestionariosDisponiveis';
import MeusDiagnosticos from 'pages/MeusDiagnosticos';
import LoadingScreen from "./components/LoadingScreen";
import OpcoesAdministrador from "./pages/Administrar/OpcoesAdministrador";
import PainelUsuarios from "./pages/Administrar/AdministrarUsuarios/PainelUsuarios";
import {RespostasIndividuaisUsuario} from 'pages/RespostasIndividuaisUsuario';
import NovoRamo from 'pages/Administrar/AdministrarRamoAtividade/NovoRamoAtividade';
import NovaEmpresa from 'pages/Administrar/AdministrarOrganizacoes/NovaOrganizacao';
import PainelRamoAtividade from 'pages/Administrar/AdministrarRamoAtividade/PainelRamoAtividade';
import PainelOrganizacoes from 'pages/Administrar/AdministrarOrganizacoes/PainelOrganizacoes';
import SelecionarRespostasUsuario from 'pages/Comparacao/Usuario/SelecionarRespostas';
import CompararRespostasUsuario from 'pages/Comparacao/Usuario/CompararRespostas';
import Configuracoes from 'pages/Configuracoes';
import BibliotecaDeAtivos from 'pages/BibliotecaDeAtivos/Admin';
import BibliotecaDeAtivosUsuarios from "./pages/BibliotecaDeAtivos/Usuario";
import Home from "./pages/Home";
import ComoFunciona from "./pages/ComoFunciona";
import VisualizarQuestionario from 'pages/VisualizarQuestionario';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ModalProvider} from 'contexts/ModalContext';
import EditarUsuario from 'pages/Administrar/AdministrarUsuarios/EditarUsuario';
import SelecionarQuestionarioUsuario from 'pages/Comparacao/Usuario/SelecionarQuestionario';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function AppRoutes() {
    const {id, loading, nivelPermissao, refresh} = useUserContext();

    function PrivateRoute({element}: any) {
        if (loading) return <LoadingScreen/>
        if (!id) {
            return <Navigate to="/login"/>
        }

        return element ? element : <DashboardUsuario/>;
    }

    function AdminRoute({element, unauthenticated}: any) {
        if (loading) return <LoadingScreen/>
        if (!id || !nivelPermissao || nivelPermissao < 1) {
            return <PrivateRoute element={unauthenticated ? unauthenticated : <DashboardUsuario/>}/>
        }

        return element;
    }

    if (loading) return <LoadingScreen/>

    return (
        <>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/loading" element={<LoadingScreen/>}/>
                <Route path="/como-funciona" element={<ComoFunciona/>}/>

                <Route path="/dashboard"
                       element={
                           <AdminRoute element={<Dashboard/>}/>
                       }
                />

                {
                    /*
                        Rotas para a administração
                        e visualização de questionários
                     */
                }
                <Route path="/meus-questionarios" element={<AdminRoute element={<MeusQuestionarios/>}/>}/>
                <Route path="/administrar-questionario/:id"
                       element={<AdminRoute element={<AdministrarQuestionario/>}/>}/>

                <Route path="/selecionar-questionario" element={<AdminRoute element={<SelecionarQuestionario/>}
                                                                            unauthenticated={
                                                                                <SelecionarQuestionarioUsuario/>}/>}/>
                <Route path="/selecionar-respostas/:id"
                       element={<AdminRoute element={<SelecionarRespostas/>} unauthenticated={
                           <SelecionarRespostasUsuario/>}/>}/>
                <Route path="/comparar-respostas" element={<AdminRoute element={<CompararRespostas/>}
                                                                       unauthenticated={
                                                                           <CompararRespostasUsuario/>}/>}/>


                <Route path="/visualizar-resposta/:id"
                       element={<PrivateRoute element={<RespostasIndividuaisUsuario/>}/>}/>
                <Route path="/visualizar-questionario/:id" element={<VisualizarQuestionario/>}/>

                {
                    /*
                        Rotas para usuário comum
                        responder a visualizar
                        respostas de questionários
                     */
                }
                <Route path="/responder-questionario/:id" element={<PrivateRoute element={<ResponderQuestionario/>}/>}/>
                <Route path="/questionarios-disponiveis"
                       element={<PrivateRoute element={<QuestionariosDisponiveis/>}/>}/>
                <Route path="/meus-diagnosticos" element={<PrivateRoute element={<MeusDiagnosticos/>}/>}/>

                <Route path="/opcoes-administrador" element={<AdminRoute element={<OpcoesAdministrador/>}/>}/>

                <Route path="/administrar-eixos" element={<AdminRoute element={<AdministrarEixos/>}/>}/>

                <Route path="/painel-usuarios" element={<AdminRoute element={<PainelUsuarios/>}/>}/>
                <Route path="/novo-usuario" element={<AdminRoute element={<NovoUsuario/>}/>}/>
                <Route path="/editar-usuario/:id" element={<AdminRoute element={<EditarUsuario/>}/>}/>

                <Route path="/painel-ramos" element={<AdminRoute element={<PainelRamoAtividade/>}/>}/>
                <Route path="/novo-ramo" element={<AdminRoute element={<NovoRamo/>}/>}/>

                <Route path="/painel-organizacoes" element={<AdminRoute element={<PainelOrganizacoes/>}/>}/>
                <Route path="/nova-organizacao" element={<AdminRoute element={<NovaEmpresa/>}/>}/>

                <Route path="/configuracoes" element={<PrivateRoute element={<Configuracoes/>}/>}/>

                <Route path="/biblioteca-ativos" element={<AdminRoute element={<BibliotecaDeAtivos/>}
                                                                      unauthenticated={
                                                                          <BibliotecaDeAtivosUsuarios/>}/>}/>
            </Routes>
        </>
    )
}

root.render(
    <BrowserRouter>
        <ModalProvider>
            <UserProvider>
                <QueryClientProvider client={queryClient}>
                    <QuestionarioProvider>
                        <AppRoutes/>
                    </QuestionarioProvider>
                </QueryClientProvider>
            </UserProvider>
        </ModalProvider>
    </BrowserRouter>
)
