import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai"

interface SorterProps {
    sortState: [sort: string, setSort: Function],
    columnMetadata: any,
}

export default function Sorter({sortState: [sort, setSort], columnMetadata}: SorterProps) {
    const {filterKey, sortingKey} = columnMetadata.columnDef.meta ?? {}

    const key = sortingKey ?? columnMetadata.id

    function clickSort() {

        if (sort === key) return setSort(`-${key}`);
        if (sort === `-${key}`) return setSort('')
        return setSort(key);
    }

    return (
        <div className="flex flex-col cursor-pointer select-none mx-[1px]"
             onClick={clickSort}
        >
            <AiFillCaretUp className={sort === key ? "text-[0.7rem]" : "opacity-[30%] text-[0.7rem]"}/>
            <AiFillCaretDown className={sort === `-${key}` ? "text-[0.7rem]" : "opacity-[30%] text-[0.7rem]"}/>
        </div>
    )
}