import React from 'react';
import {LoginForm} from "./components/LoginForm";

export default function Login() {
    return (
        <div className="flex relative">
            <div className="w-[62%] absolute z-10">
                <img className="zh-screen w-full" src={require("../../assets/images/top-viewtop.png")} alt="Logo"/>
            </div>
            <div
                className="w-[40%] h-screen ml-auto rounded-bl-3xl rounded-tl-3xl flex items-center justify-center bg-white-1 z-20">
                <LoginForm/>
            </div>
        </div>
    );
};


