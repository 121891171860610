// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-icon {
    bottom: 1rem;
    transform: translateX(1000%);
    z-index: 999;
}

.checkbox-wrapper .round {
    position: relative;
}

.checkbox-wrapper .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;
    display: block;
}

.checkbox-wrapper .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 9px;
    transform: rotate(-45deg);
    width: 12px;
}

.checkbox-wrapper .round input[type="checkbox"] {
    visibility: hidden;
    display: none;
    opacity: 0;
}

.checkbox-wrapper .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.checkbox-wrapper .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/EditarQuestionario/components/PerguntasQuestionarioForm/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,4BAA4B;IAC5B,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".border-icon {\n    bottom: 1rem;\n    transform: translateX(1000%);\n    z-index: 999;\n}\n\n.checkbox-wrapper .round {\n    position: relative;\n}\n\n.checkbox-wrapper .round label {\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 50%;\n    cursor: pointer;\n    height: 28px;\n    width: 28px;\n    display: block;\n}\n\n.checkbox-wrapper .round label:after {\n    border: 2px solid #fff;\n    border-top: none;\n    border-right: none;\n    content: \"\";\n    height: 6px;\n    left: 8px;\n    opacity: 0;\n    position: absolute;\n    top: 9px;\n    transform: rotate(-45deg);\n    width: 12px;\n}\n\n.checkbox-wrapper .round input[type=\"checkbox\"] {\n    visibility: hidden;\n    display: none;\n    opacity: 0;\n}\n\n.checkbox-wrapper .round input[type=\"checkbox\"]:checked + label {\n    background-color: #66bb6a;\n    border-color: #66bb6a;\n}\n\n.checkbox-wrapper .round input[type=\"checkbox\"]:checked + label:after {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
