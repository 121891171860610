import {SimpleQuestionario} from "interfaces/Questionario"
import LinhaAtividade from "./Graficos/LinhaAtividade";
import Radar from "components/Graficos/Radar";
import {atenEixoToDataset} from "utils/AtenEixoToDataset";
import Barras from "components/Graficos/Barras";
import {RgbaCores, RgbaCoresReduzidas} from "utils/Cores";


export default function EstatisticasQuestionario(
    {
        questionario,
    }:
        {
            questionario: SimpleQuestionario,
        }
) {


    const atividade_ultimos_meses = questionario.atividade_ultimos_meses!.reverse().slice(5);

    return (
        <div className="bg-white-1 p-8 rounded-2xl shadow-md">

            <div className="flex justify-left gap-8">
                <div className="w-[20%] bg-white-1 p-8 rounded-xl shadow-xl">
                    <p className="font-bold font-raleway text-lg text-center">
                        Respostas Recebidas
                    </p>

                    <p className="font-semibold text-[#132D46] font-raleway text-6xl mt-4 text-center">
                        {questionario.qtd_respostas}
                    </p>
                </div>
                <div className="w-[40%] bg-white-1 p-8 rounded-xl shadow-xl">
                    <LinhaAtividade data={atividade_ultimos_meses}/>
                </div>
            </div>


            <div className="flex justify-between mt-10">
                <div className="w-[30%] bg-white-1 p-8 rounded-xl shadow-2xl">
                    <Radar
                        data={atenEixoToDataset({
                            graficos: [
                                {
                                    label: "Média de Respostas",
                                    resultado: questionario.medias!,
                                    backgroundColor: RgbaCoresReduzidas.AzulRgba,
                                    borderColor: RgbaCores.AzulRgba,
                                    fill: false
                                }
                            ]
                        })}
                        op={2}
                    />
                </div>

                <div className=" bg-white-1 p-8 w-[60%] rounded-xl shadow-xl">
                    <Barras
                        data={atenEixoToDataset({
                            graficos: [
                                {
                                    label: "Média de Respostas",
                                    resultado: questionario.medias!,
                                    backgroundColor: RgbaCoresReduzidas.AzulRgba,
                                    borderColor: RgbaCores.AzulRgba,
                                    fill: false
                                }
                            ]
                        })}
                        op={2}
                    />
                </div>

            </div>
        </div>
    )
}