import {CellContext, createColumnHelper} from "@tanstack/react-table";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {useNavigate} from "react-router-dom";
import {SlOptionsVertical} from "react-icons/sl";
import {useState} from "react";
import ManualTable from "../../../../components/ManualTable";

export default function TabelaQuestionariosRecentes({data}: { data: UsuarioRespondeQuestionario[] }) {
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState<number | null>(null);

    const toggleDropdown = (rowId: number) => {
        setSelectedRow(selectedRow === rowId ? null : rowId);
    };

    const columnHelper = createColumnHelper<UsuarioRespondeQuestionario>();
    const columns = [
        columnHelper.accessor('questionario.nome', {
            header: 'Questionário',
            cell: (info: CellContext<UsuarioRespondeQuestionario, string>) => (
                <div className="relative rounded-md py-2">
                    {info.getValue()}
                </div>
            ),
            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.accessor('data_resposta', {
            header: 'Data',
            cell: (info: CellContext<UsuarioRespondeQuestionario, string>) => (
                <div>
                    {new Date(info.getValue()).toLocaleDateString()}
                </div>
            ),
            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.display({
            id: 'actions',
            header: '',
            cell: (info: CellContext<UsuarioRespondeQuestionario, unknown>) => {
                const rowId = Number(info.row.id);
                return (
                    <div className="relative text-right pr-4">
                        <button
                            className="p-2 text-gray-600 hover:bg-gray-200 rounded-full"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(rowId);
                            }}
                        >
                            <SlOptionsVertical/>
                        </button>

                        {/* Dropdown */}
                        {selectedRow === rowId && (
                            <div className="absolute right-0 mt-2 w-48 bg-white-1 rounded-md shadow-lg z-50">
                                <ul className="py-1">
                                    <li>
                                        <button
                                            className="block px-4 py-2 text-sm text-left hover:bg-gray-100 w-full"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/visualizar-resposta/${info.row.original.id}`);
                                                setSelectedRow(null);
                                            }}
                                        >
                                            Visualizar resultados
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="block px-4 py-2 text-sm text-left hover:bg-gray-100 w-full"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/responder-questionario/${info.row.original.questionario.id}`);
                                                setSelectedRow(null);
                                            }}
                                        >
                                            Refazer questionário
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                );
            },
            enableColumnFilter: false,
            enableSorting: false
        })
    ];

    function onRowClick(row: UsuarioRespondeQuestionario) {
        navigate('/visualizar-resposta/' + row.id);
    }

    return (
        <ManualTable
            className="rounded-lg w-full shadow-lg"
            data={data}
            columns={columns}
            rowOnClick={onRowClick}
        />
    );
}
