import {api} from "./api";

interface GetQuestionariosProps {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export async function serviceMeusQuestionarios({page = 1, pageSize = 10, filters, ordering}: GetQuestionariosProps) {
    const response = await api.get('questionario/', {
        params: {page, page_size: pageSize, ordering, ...filters}
    });
    return response;
}

