import React from 'react';

interface Estado {
    id: number;
    mensagem: string;
}

interface PaginacaoProps {
    estados: Estado[];
    estadoAtual: number;
}

export default function PaginacaoQuestionarios({estados, estadoAtual}: PaginacaoProps) {
    return (
        <div className="flex flex-col p-[1em] gap-4 space-y-2 bg-[white] rounded-lg h-fit drop-shadow-xl">
            {estados.map((estado) => (
                <div
                    key={estado.id}
                    className={`flex items-center text-[#6F6F6F] text-base font-normal space-x-2 ${estado.id === estadoAtual ? 'font-bold text-[#6F6F6F]' : ''}`}
                >
                    <div
                        className={`w-8 h-8 font-bold text-base flex items-center justify-center rounded-full border border-[#01C38E] ${estado.id === estadoAtual ? 'bg-[#01C38E] text-white-1' : 'bg-white-1 text-[#01C38E]'}`}
                    >
                        {estado.id}
                    </div>
                    <span>{estado.mensagem}</span>
                </div>
            ))}
        </div>
    );
};
