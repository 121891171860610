import {UsuarioRespondeQuestionario} from "../../../../../interfaces/UsuarioRespondeQuestionario";
import {createColumnHelper} from "@tanstack/react-table";
import {useParams} from "react-router-dom";
import useGetManualTableFilters from "hooks/UseGetManualTableFilters";
import {useGetRespostas} from "hooks/UseGetRespostasQuestionario";
import ManualTable from "components/ManualTable";
import ManualPaginationArrows from "components/ManualTable/components/ManualPaginationArrows";

export default function TabelaRespostas(
    {
        respostasSelecionadas: [respostasSelecionadas, setRespostasSelecionadas],
    }:
        {
            respostasSelecionadas: [UsuarioRespondeQuestionario[], React.Dispatch<React.SetStateAction<UsuarioRespondeQuestionario[]>>]
        }) {

    const {id} = useParams();
    const {page, setPage, pageSize, filters, setFilters, ordering, setOrdering} = useGetManualTableFilters(10);
    const {respostas, pagination} = useGetRespostas({
        page: page,
        filters: {
            ...filters,
            questionario__id: id!
        },
        ordering: ordering
    });
    const columnHelper = createColumnHelper<UsuarioRespondeQuestionario>();
    const columns = [
        columnHelper.display(
            {
                id: 'checkbox',
                header: '',
                cell: info => (
                    <div className='flex items-center'>
                        <input
                            type='checkbox'
                            className='mr-[12px] w-[14px] h-[14px]'
                            key={info.row.original.usuario.nome_organizacao}
                            checked={respostasSelecionadas.includes(info.row.original)}
                        />
                        <p>{info.row.original.usuario.nome_organizacao}</p>
                    </div>
                ),
                size: 100
            }
        ),
        columnHelper.accessor(
            'usuario.nome_completo',
            {
                header: 'Nome',
                cell: info => <>{info.row.original.usuario.nome_completo}</>,
                size: 400,
                meta: {
                    filterKey: 'usuario__nome_completo__icontains',
                    sortingKey: 'usuario__nome_completo',
                }
            }
        ),
        columnHelper.accessor(
            'usuario.organizacao.nome',
            {
                header: 'Instituição',
                cell: info => <>{info.row.original.usuario.organizacao.nome}</>,
                size: 400,
                meta: {
                    filterKey: 'usuario__organizacao__nome__icontains',
                    sortingKey: 'usuario__organizacao__nome',
                }
            }
        ),
        columnHelper.accessor(
            'usuario.organizacao.ramo_atividade.nome',
            {
                header: 'Ramo de Atividade',
                cell: info => (
                    <p>{info.row.original.usuario.organizacao.ramo_atividade.nome}</p>
                ),
                meta: {
                    filterKey: 'usuario__organizacao__ramo_atividade__nome__icontains',
                    sortingKey: 'usuario__organizacao__ramo_atividade__nome'
                }
            }
        ),
        columnHelper.accessor(
            'data_resposta',
            {
                header: 'Data',
                cell: (info: any) => (
                    new Date(info.row.original.data_resposta).toLocaleDateString()
                ),
                size: 1,
                enableColumnFilter: false,
            },
        ),

    ]

    function rowOnClick(row: any) {
        if (respostasSelecionadas.includes(row)) {
            setRespostasSelecionadas(
                respostasSelecionadas.filter(resposta => resposta.id !== row.id)
            );
        } else if (respostasSelecionadas.length < 6) {
            setRespostasSelecionadas([...respostasSelecionadas, row]);
        }
    }


    return (
        <div className="m-auto pt-[0.75rem] text-[0.75rem]">
            <ManualTable
                className="w-full m-auto rounded-lg shadow-lg overflow-hidden"
                columns={columns}
                data={respostas}
                filterState={[filters, setFilters]}
                ordering={[ordering, setOrdering]}
                rowOnClick={rowOnClick}
            />

            <ManualPaginationArrows
                currentPageState={[page, setPage]}
                lastPage={Math.ceil(pagination.count / pageSize)}
            />

        </div>
    );
}