import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import PerguntaAlternativas from 'interfaces/PerguntaAlternativa';
import {Eixo} from 'interfaces/Eixo';
import {serviceAtualizarPergunta} from 'api/QuestionarioService';
import {SimpleQuestionario} from 'interfaces/Questionario';
import {useMutation} from '@tanstack/react-query';

export default function RelacaoEixosPerguntasForm(
    {
        questionarioState: [questionario, setQuestionario],
        editingQueueState: [editedQueue, setEditedQueue],
    }: {
        questionarioState: [SimpleQuestionario, (questionario: SimpleQuestionario) => void],
        editingQueueState: [number[], Dispatch<SetStateAction<number[]>>]
    }) {

    const [perguntas, setPerguntas] = useState<PerguntaAlternativas[]>(questionario.perguntas!);
    const eixos: Eixo[] = questionario.eixos!;

    useEffect(() => {
        setQuestionario({
            ...questionario,
            perguntas
        });
    }, [perguntas]);

    function updateQueue(id: number) {
        if (!editedQueue.includes(id)) {
            setEditedQueue((prevQueue) => [...prevQueue, id]);
        }
    }

    const {mutate: mutateUpdatePergunta, isPending: isPendingUpdatePergunta} = useMutation({
        mutationFn: serviceAtualizarPergunta,
        onSuccess: (response: any) => {
            setPerguntas((prevPerguntas: any) => prevPerguntas.map((pergunta: any) => {
                if (pergunta.id === response.data.id) {
                    return response.data;
                }
                return pergunta;
            }))
        },
        onError: (error: any) => {
            console.log(error);
        }
    });

    useEffect(() => {
        function liveSync() {
            if (editedQueue.length > 0) {
                editedQueue.forEach(async (id) => {
                    const pergunta = perguntas.find(pergunta => pergunta.id === id)!;
                    if (!pergunta) return;
                    mutateUpdatePergunta({pergunta});
                    setEditedQueue((prevQueue) => prevQueue.filter((queueId) => queueId !== id));
                });
            }
        }

        const interval = setInterval(liveSync, 5000);

        return () => clearInterval(interval);
    }, [editedQueue, perguntas]);


    return (
        <div>
            <div className="justify-self-start font-semibold text-lg uppercase rounded-xl shadow-xl p-3">
                Editar relação eixo-pergunta
            </div>

            {perguntas.map((pergunta, perguntaIindex) => (
                <div key={perguntaIindex} className="justify-self-center rounded-xl shadow-xl p-8 relative mb-4">

                    <div className="flex flex-col gap-4">

                        <div className="flex flex-col">
                            <div className="font-extralight text-lg mb-2">{`Questão ${perguntaIindex + 1}`}</div>
                            <div className="mb-4 text-xl">{pergunta.descricao}</div>
                        </div>

                        <div className="flex gap-4">

                            {pergunta.pesos!.map((peso, pesoIndex) =>
                                <div key={pesoIndex} className="flex flex-col w-[7rem]">
                                    <div
                                        className="line-clamp-2 text-sm font-normal text-[#4F4F4F] text-center mb-2">{eixos[eixos.findIndex(eixo => eixo.id === peso.eixo_id)].nome}</div>
                                    <input
                                        value={peso.peso}
                                        onChange={(e) => {
                                            setPerguntas((prevPerguntas) => prevPerguntas.map((prevPergunta) => {
                                                if (prevPergunta.id === pergunta.id) {
                                                    return {
                                                        ...prevPergunta,
                                                        pesos: prevPergunta.pesos!.map((prevPeso, prevPesoIndex) => {
                                                            if (prevPesoIndex === pesoIndex) {
                                                                return {
                                                                    ...prevPeso,
                                                                    peso: Number(e.target.value)
                                                                }
                                                            }
                                                            return prevPeso;
                                                        })
                                                    }
                                                }
                                                return prevPergunta;
                                            }))

                                            updateQueue(pergunta.id!);
                                        }}
                                        type="number" className="border w-[5rem] mt-auto mx-auto rounded-xl"
                                    />
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            ))}
        </div>
    )
}