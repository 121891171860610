import {Header} from "components/Headers/HeaderFelipe";
import {ModalCriarExistente, ModalCriarQuestionario, TabelaQuestionarios} from "./components";
import {TemplateLeftBar} from "components";
import {useState} from "react";
import {FaPlus} from "react-icons/fa6";

export function MeusQuestionarios() {
    const [novoQuestionarioOpen, setNovoQuestionarioOpen] = useState(false);
    const [criarExistenteOpen, setCriarExistenteOpen] = useState(false);

    function ShowModalCriarQuestionario() {
        return (
            <>
                {
                    novoQuestionarioOpen &&
                    <ModalCriarQuestionario
                        modalState={[novoQuestionarioOpen, setNovoQuestionarioOpen]}
                        modalDupState={[criarExistenteOpen, setCriarExistenteOpen]}
                    />
                }
            </>
        )
    }

    function ShowModalCriarExistente() {
        return (
            <>
                {
                    criarExistenteOpen &&
                    <ModalCriarExistente
                        modalState={[criarExistenteOpen, setCriarExistenteOpen]}
                    />
                }
            </>
        )
    }

    return (
        <TemplateLeftBar>
            <Header fluxo={[
                {nome: 'Questionários', path: '/meus-questionarios'},
                {nome: 'Meus Questionários', path: '/meus-questionarios'},
            ]}/>

            <ShowModalCriarQuestionario/>
            <ShowModalCriarExistente/>

            <div className={"flex justify-between relative"}>
                <div className={"mt-3 text-2xl font-raleway font-bold"}>
                    <h1>Meus Questionários</h1>
                </div>

                <div className="mt-1 mx-1 flex items-center">
                    <div
                        className="p-2 rounded-lg justify-center items-center flex bg-[#01C38E]"
                        onClick={() => setNovoQuestionarioOpen(true)}
                    >
                        <h1 className="text-base flex justify-center items-center font-raleway cursor-pointer font-bold text-[#FFFFFF]">
                            <FaPlus className={'mr-2'}/> <p>Novo Questionário</p>
                        </h1>
                    </div>
                </div>
            </div>

            <div className="bg-white-2 px-6 py-2 rounded mt-5 w-full">
                <TabelaQuestionarios/>
            </div>

        </TemplateLeftBar>
    )
}