// Tabs.js

import React, {useState} from 'react';

interface Tab {
    axis_name: string;
    children: React.ReactNode;
}

interface TabsProps {
    tabs: Tab[];
}

const GenericTabs: React.FC<TabsProps> = ({tabs}) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className="tabs-frame w-full">
            <div className="tab-buttons bg-white-2 w-fit rounded-t-lg ">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => handleTabClick(index)}
                        className={`h-[3.56rem] rounded-t-lg font-raleway font-bold text-[1.187rem] ${index === activeTab ? 'active bg-white-1 w-[17.5rem] text-blue-smart_city' : 'shadow-[10px_0_60px_-15px_rgba(0,0,0,0.3)] ml px-4 w-[16rem] text-gray-5 text-opacity-70 '}`}
                    >
                        {tab.axis_name}
                    </button>
                ))}
            </div>
            <div
                className="tab-content w-full bg-white-1 rounded-b-xl rounded-r-xl px-[3.95rem] py-[2rem] shadow-xl">
                {tabs[activeTab].children}
            </div>
        </div>
    );
};

export default GenericTabs;
