// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay {
    position: fixed; /* Cover the entire page */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Gray out background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of everything */
    pointer-events: all; /* Capture all pointer events */
}

.loading-svg circle {
    stroke-dasharray: 0, 251.2;
    animation: loading-gradient 2s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingOverlay/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,0BAA0B;IAC3C,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B,EAAE,wBAAwB;IACxD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,qCAAqC;IACpD,mBAAmB,EAAE,+BAA+B;AACxD;;AAEA;IACI,0BAA0B;IAC1B,8CAA8C;AAClD","sourcesContent":[".loading-overlay {\n    position: fixed; /* Cover the entire page */\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5); /* Gray out background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999; /* Ensure it's on top of everything */\n    pointer-events: all; /* Capture all pointer events */\n}\n\n.loading-svg circle {\n    stroke-dasharray: 0, 251.2;\n    animation: loading-gradient 2s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
