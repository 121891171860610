import React from 'react';
import {TemplateLeftBar} from "components";
import {Header} from "components/Headers/HeaderFelipe";
import {useNavigate} from 'react-router-dom';

export default function OpcoesAdministrador() {

    const navigate = useNavigate();

    return (
        <TemplateLeftBar>
            <Header fluxo={[
                {nome: 'Administrador', path: '/opcoes-administrador'},
            ]}/>

            <div className={"flex flex-col justify-between"}>

                <div className={"mt-3 text-2xl font-raleway font-bold"}>
                    <h3>Administrar</h3>
                </div>

                <div className={"mt-[0.125rem] text-base font-raleway"}>
                    <h3>Edite informações de usuários, ramos de atividades ou organizações</h3>
                </div>

                <button onClick={() => navigate('/painel-usuarios')}>
                    <div
                        className={'w-1/2 p-[3em] bg-green-smart_city text-white-1 text-2xl font-bold text-start mt-8 rounded-xl'}>
                        Usuários
                    </div>
                </button>

                <button onClick={() => navigate('/painel-ramos')}>
                    <div
                        className={'w-1/2 p-[3em] bg-green-smart_city text-white-1 text-2xl font-bold text-start mt-8 rounded-xl'}>
                        Ramos de Atividades
                    </div>
                </button>

                <button onClick={() => navigate('/painel-organizacoes')}>
                    <div
                        className={'w-1/2 p-[3em] bg-green-smart_city text-white-1 text-2xl font-bold text-start mt-8 rounded-xl'}>
                        Organizações
                    </div>
                </button>

            </div>
        </TemplateLeftBar>
    )
}