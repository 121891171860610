import PerguntaAlternativas from "interfaces/PerguntaAlternativa";
import {SimpleQuestionario} from "interfaces/Questionario";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";

type RespostasTabProps = {
    questionario: SimpleQuestionario;
    resposta: UsuarioRespondeQuestionario;
}

type PerguntaCardProps = {
    pergunta: PerguntaAlternativas;
    alternativa_id: number;
}

function PerguntaCard({pergunta, alternativa_id}: PerguntaCardProps) {
    console.log(pergunta, alternativa_id);
    return (
        <>
            <div className="pt-4 py-4 font-raleway">
                <h2 className="text-xl font-semibold text-gray-800">{pergunta.descricao}</h2>
            </div>

            <div className="pb-12">
                <div>
                    {pergunta.alternativas.map((alternativa, index) => (
                        <div key={alternativa.id}
                             className="mb-4 flex items-center justify-between font-raleway text-[#4F4F4F]">
                            <h1 className={`${alternativa.id === alternativa_id ? 'font-bold' : ''}`}>
                                {String.fromCharCode(97 + index)} {alternativa.descricao}
                            </h1>
                        </div>
                    ))}
                </div>
            </div>


        </>
    )
}

export default function RespostasTab({questionario, resposta}: RespostasTabProps) {
    console.log(questionario);

    return (
        <div>
            {
                questionario.perguntas!.map((pergunta, index) => {
                    return (
                        <>
                            <p className="text-lg font-semibold text-gray-600 font-raleway">
                                Pergunta {index + 1}
                            </p>
                            <PerguntaCard
                                pergunta={pergunta}
                                alternativa_id={resposta.respostas.find(resposta => resposta.pergunta_id === pergunta.id)!.alternativa_id}
                            />
                        </>
                    )
                })
            }
        </div>

    )

}
