import {createColumnHelper} from "@tanstack/react-table";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";

export default function ColunasUltimasRespostas() {
    const columnHelper = createColumnHelper<UsuarioRespondeQuestionario>();


    return [
        columnHelper.display(
            {
                id: "questionario.nome",
                header: "Questionário",
                cell: info => (
                    <>
                        {info.row.original.questionario.nome}
                    </>
                ),
            },
        ),
        columnHelper.display(
            {
                id: 'usuario.organizacao.nome',
                header: "Orgão",
                cell: info => (
                    <>
                        {info.row.original.usuario.organizacao.nome}
                    </>
                ),
            }
        ),
        columnHelper.display(
            {
                id: "data_resposta",
                header: "Data",
                cell: info => (
                    <>
                        {new Date(info.row.original.data_resposta).toLocaleDateString()}
                    </>
                ),
            }
        ),

    ]
}