import {TemplateLeftBar} from "components";
import {Header} from "components/Headers/HeaderFelipe";
import CardsMaisAcessados from "./components/CardAcesso";
import Colunas from "./components/Colunas";
import {useState} from "react";
import {Ativo} from "interfaces/Ativo";
import TabelaHover from "components/Tables/TabelaHover";
import {FiPlus} from "react-icons/fi";
import ModalNovoAtivo from "./components/modalNovoAtivo";
import {useQuery} from "@tanstack/react-query";
import ModalEditarAtivo from "./components/ModalEditarAtivo";
import {serviceGetAtivos} from "api/AtivoService";

export default function BibliotecaDeAtivos() {
    const [modalOpen, setModalOpen] = useState(false);

    const {data: arquivos = [], refetch} = useQuery<Ativo[]>({
        queryKey: ['ativos'],
        queryFn: () => serviceGetAtivos().then(response => response.data.data),
    });


    const [ativoSelecionado, setAtivoSelecionado] = useState<Ativo | null>(null);
    const [modalEditarAtivoOpen, setModalEditarAtivoOpen] = useState(false);

    function abrirModalEditarAtivo(ativo: Ativo) {
        setAtivoSelecionado(ativo);
        setModalEditarAtivoOpen(true);
    }

    return (
        <TemplateLeftBar>

            <Header fluxo={[
                {nome: 'Biblioteca de Ativos', path: '/biblioteca-ativos'}
            ]}/>

            <div className='text-blue-login font-bold text-2xl my-8'>Mais acessados</div>

            <div className="flex bg-white-1 p-6 gap-8 rounded-xl shadow-xl overflow-hidden flex-wrap">
                {
                    arquivos.slice(0, 5).map((arquivo) => (
                        <CardsMaisAcessados arquivo={arquivo}/>
                    ))
                }
            </div>


            <div className='flex'>
                <div className='text-blue-login font-bold text-2xl my-8'>Todos os arquivos</div>

                <div className="mt-1 mx-1 flex items-center ml-auto cursor-pointer"
                     onClick={() => setModalOpen(true)}>
                    <div className="p-2 rounded-lg justify-center items-center flex bg-[#01C38E]">
                        <FiPlus className='text-2xl text-white-1'/>
                        <h1 className="text-base font-raleway font-bold text-[#FFFFFF]">
                            Novo Ativo
                        </h1>
                    </div>
                </div>
            </div>

            <ModalNovoAtivo
                modalState={[modalOpen, setModalOpen]}
                refetch={refetch}
            />

            {
                ativoSelecionado &&
                <ModalEditarAtivo
                    modalState={[modalEditarAtivoOpen, setModalEditarAtivoOpen]}
                    ativo={ativoSelecionado}
                    refetch={refetch}
                />
            }

            <TabelaHover
                columns={Colunas({
                    refetch: refetch,
                    editarAtivo: abrirModalEditarAtivo
                })}
                data={arquivos}
            />
        </TemplateLeftBar>
    )
}