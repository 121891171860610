import {FaAngleDown, FaAngleRight} from "react-icons/fa";
import React, {useState} from "react";

interface ComoFuncionaOpcoesProps {
    titulo: string;
    Conteudo: React.ComponentType;
}

export default function ComoFuncionaOpcoes({titulo, Conteudo}: ComoFuncionaOpcoesProps) {
    const [isContentVisible, setIsContentVisible] = useState(false);
    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    return (
        <div className='flex flex-col space-y-4 mt-4'>
            <div
                className={`w-full ${isContentVisible ? 'h-auto' : 'h-20'} bg-white-1 rounded-xl shadow-xl flex flex-col pb-8`}
                onClick={toggleContentVisibility}
            >
                <div className='flex items-center w-auto mt-7 ml-8'>
                    <div className='text-lg font-bold text-gray-5 ml-8'>{titulo}</div>
                    {isContentVisible ? (
                        <FaAngleDown className='text-3xl text-gray-5/50 ml-auto mr-8'/>
                    ) : (
                        <FaAngleRight className='text-3xl text-gray-5/50 ml-auto mr-8'/>
                    )}
                </div>

                {isContentVisible && (
                    <div className='ml-32 mt-8 px-4'>
                        <Conteudo/>
                    </div>
                )}
            </div>
        </div>
    );
}
