import {
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {Pagination} from "components/Tables/components";
import Filter from "components/Tables/components/Filter";
import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";
import {useState} from "react";

export default function TabelaHover(
    {
        data,
        columns,
        className,
        showPagination = true,
        pageSize = 10,
        rowOnClick = null
    }: {
        data: any[],
        columns: any[],
        className?: string,
        showPagination?: boolean,
        pageSize?: number,
        rowOnClick?: ((row: any) => void) | null
    }) {

    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: pageSize,
            },
        },

        state: {
            sorting,
            columnFilters,
        },

        onSortingChange: (updaterOrValue: any) => {
            setSorting(updaterOrValue);
        },
        getSortedRowModel: getSortedRowModel(),

        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),

    })

    function getHeaders(headerGroup: any) {
        return (
            <tr key={headerGroup.id}
                className=
                    {`text-left bg-gray-light text-blue-smart_city font-bold 
                        bg-opacity-50 text-lg`}
            >
                {headerGroup.headers.map((header: any) => (
                    <th key={header.id}
                        className={`p-4`}
                        style={{width: header.column.columnDef.size}}
                    >
                        <div className="flex items-center justify-between">
                            <div>
                                {
                                    flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )
                                }
                            </div>
                            <div className="flex items-center justify-between">
                                {
                                    header.column.getCanFilter() ? (
                                        <div className="mx-1 relative">
                                            <Filter column={header.column}/>
                                        </div>
                                    ) : null
                                }
                                {
                                    header.column.getCanSort() ?
                                        {
                                            asc: (
                                                <div className="flex flex-col cursor-pointer select-none mx-1"
                                                     onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    <AiFillCaretUp className="opacity-[30%] text-[0.7rem]"/>
                                                    <AiFillCaretDown className="text-[0.7rem]"/>
                                                </div>
                                            ),
                                            desc: (
                                                <div className="flex flex-col cursor-pointer select-none mx-1"
                                                     onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    <AiFillCaretUp className="text-[0.7rem]"/>
                                                    <AiFillCaretDown className="opacity-[30%] text-[0.7rem]"/>
                                                </div>
                                            ),
                                            false: (
                                                <div className="flex flex-col cursor-pointer select-none mx-1"
                                                     onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    <AiFillCaretUp className="text-[0.7rem]"/>
                                                    <AiFillCaretDown className="text-[0.7rem]"/>
                                                </div>
                                            ),
                                        }[header.column.getIsSorted() as keyof {
                                            asc: Element;
                                            desc: Element;
                                            false: Element;
                                        }] ?? null
                                        : null
                                }
                            </div>
                        </div>
                    </th>
                ))}
            </tr>

        )
    }

    function getRow(row: any) {
        return (
            <tr key={row.id}
                className={`bg-white-1 text-gray-5 font-medium text-[0.875rem]
                    ${rowOnClick && "cursor-pointer"}
                `}
                onMouseEnter={(e) => {
                    e.currentTarget.classList.remove("bg-white-1");
                    e.currentTarget.classList.add("bg-gray-200");
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.classList.remove("bg-gray-200");
                    e.currentTarget.classList.add("bg-white-1");
                }}
                onClick={() => rowOnClick && rowOnClick(row)}
            >
                {row.getVisibleCells().map((cell: any) => (
                    <td key={cell.id} className=
                        {`p-4 text-base font-medium`}
                    >
                        {
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                        }
                    </td>
                ))}
            </tr>
        )
    }

    function getFooter(footerGroup: any) {
        return (
            <tr key={footerGroup.id}>
                {footerGroup.headers.map((header: any) => (
                    <th key={header.id} className="">
                        {
                            flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                            )
                        }
                    </th>
                ))}
            </tr>
        )
    }

    return (
        <div className={`${className}`}>
            <div className="rounded-lg overflow-hidden">
                <table className="w-full">
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => getHeaders(headerGroup))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => getRow(row))}
                    </tbody>
                    <tfoot>
                    {table.getFooterGroups().map(footerGroup => getFooter(footerGroup))}
                    </tfoot>
                </table>
            </div>

            {
                showPagination && (
                    <div className={`flex justify-between items-center pt-[0.75rem] text-[0.75rem]`}>
                        <p className="text-gray-500 text-base font-medium p-[1.25rem] px-[0.625rem]">
                            Mostrando {table.getRowModel().rows.length} de {data.length} registros
                        </p>

                        <Pagination table={table}/>
                    </div>
                )
            }
        </div>
    );

}

