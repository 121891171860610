import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {FaCircle} from "react-icons/fa";
import {getCorHexadecimal} from "utils/Cores";

export default function TabelaTaxasServico({respostas}: { respostas: UsuarioRespondeQuestionario[] }) {

    function buildDatasets({resp}: { resp: UsuarioRespondeQuestionario[] }) {
        const labels = resp[0].resultados.map((item: any) => item.nome);

        const datasets = resp.map((resposta: any, index: number) => {
            const data = resposta.resultados.map((item: any) => item.resultado);

            return {
                label: resposta.usuario.nome_organizacao,
                data: data,
                backgroundColor: getCorHexadecimal(index),
                borderColor: getCorHexadecimal(index),
                borderWidth: 1
            }
        });

        const data = {
            labels: labels,
            datasets: datasets
        }

        return data;
    }

    const data = buildDatasets({resp: respostas});

    console.log("data", data);

    return (

        <div className="mt-5">
            <p className="font-bold text-xl text-[#132D46]">
                Taxas de serviço
            </p>

            <table className="table-fixed w-full">
                <thead className="font-raleway font-bold text-base bg-[#F2F2F2]">
                <tr className="border-[#C0C0C0] border-[0.063rem]">
                    <th>
                        <span
                            className="px-12 py-3">
                                Eixo
                        </span>
                    </th>
                    {
                        data.datasets.map((item: any) => (
                            <th>
                                    <span
                                        className="px-16 flex items-center justify-center text-center py-3 "
                                        key={item.label}>
                                            <FaCircle className={`w-3 h-3 mr-1`} style={{color: item.backgroundColor}}/>
                                        {item.label}
                                    </span>
                            </th>
                        ))
                    }
                </tr>
                </thead>
                <tbody className="text-[#132D46] text-base font-raleway font-bold bg-[#F2F2F2] bg-opacity-50">
                {
                    data.labels.map((item: any, index: number) => (
                        <tr key={index}>
                            <td className="py-3 pl-6 border-[#C0C0C0] border-[0.063rem] text-start">
                                {item}
                            </td>
                            {
                                data.datasets.map((dataset: any, indexDs: number) => (
                                    <td key={indexDs}
                                        className="py-3 px-12 border-[#C0C0C0] border-[0.063rem] text-center">
                                        {dataset.data[index]}%
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}