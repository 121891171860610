import {useState} from "react";
import {useQuestionarioContext} from "../../../../contexts/QuestionarioContext";
import {TabelaRespostas} from "./components";
import {Header} from "components/Headers/HeaderFelipe";
import {useNavigate} from "react-router-dom";
import {TemplateLeftBar} from "components";
import ModalSelecao from "components/Modals/ModalSelecao";
import {UsuarioRespondeQuestionario} from "interfaces/UsuarioRespondeQuestionario";
import {useModalContext} from "contexts/ModalContext";

export default function SelecionarRespostasUsuario() {
    const navigate = useNavigate();

    const {updateIdRespostas} = useQuestionarioContext();

    const [modalOpen, setModalOpen] = useState(false);
    const [respostasSelecionados, setRespostasSelecionados] = useState<UsuarioRespondeQuestionario[]>([]);
    const {openNotification} = useModalContext();

    function handleCompararRespostas() {
        if (respostasSelecionados.length < 2) {
            openNotification("Selecione ao menos duas respostas para comparação", "error");
            return;
        }

        updateIdRespostas(respostasSelecionados.map(resposta => resposta.id));
        navigate("/comparar-respostas");
    }

    return (
        <TemplateLeftBar>
            <ModalSelecao
                mensagens={respostasSelecionados.map(resposta => new Date(resposta.data_resposta).toLocaleDateString())}
                acao={() => handleCompararRespostas()}
                modalState={[modalOpen, setModalOpen]}
            />

            <Header fluxo={[
                {nome: 'Questionários', path: '/meus-questionarios'},
                {nome: 'Comparar respostas', path: '/selecionar-respostas'},
            ]}/>

            <div className={"mt-4 text-2xl font-raleway font-bold"}>
                <h1>Selecionar Resposta</h1>
            </div>
            <div className={"mt-0.5 text-base font-raleway"}>
                <h3>Selecione respostas para comparação (selecione até 5 respostas)</h3>
            </div>


            <div className="mt-1 flex flex-col w-full">
                <div className="bg-white-2 rounded mt-5 w-full">
                    <TabelaRespostas respostasSelecionadas={[respostasSelecionados, setRespostasSelecionados]}/>
                </div>

                <div className="flex justify-end">
                    <button className="bg-blue-light_2 text-white-1 rounded p-2 mt-1 w=[20%] font-bold"
                            onClick={() => setModalOpen(true)}
                    >
                        Comparar Respostas
                    </button>
                </div>
            </div>
        </TemplateLeftBar>
    )
}