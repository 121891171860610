import {AtendimentoEixo} from "interfaces/Eixo";

export default function TabelaAtendimento(
    {
        resultado,
        className = ""
    }: {
        resultado: AtendimentoEixo[],
        className?: string
    }
) {
    return (
        <table className={`w-full ${className}`}>
            <thead>
            <tr className="text-left bg-gray-light text-blue-smart_city font-bold font-raleway bg-opacity-50 text-xl">
                <th className="p-2">Eixo</th>
                <th className="p-2 text-center">Resultado</th>
            </tr>
            </thead>
            <tbody className="bg-white-1 text-gray-5 font-medium font-raleway text-lg">
            {resultado.map((item, index) => (
                <tr key={index}>
                    <td className="p-2 text-start">{item.nome}</td>
                    <td className="p-2 text-center">{item.resultado}%</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}
