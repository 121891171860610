import {HashLink as Link} from "react-router-hash-link";

export default function SectionBotoes() {
    return (
        <div className='flex gap-20 bg-blue-login p-32 justify-center items-center'>

            <div className='flex items-center justify-start font-bold text-3xl text-white-1'>
                <Link className='bg-green-smart_city rounded-lg px-[2em] py-[0.5em]' to={'/login'}>Ir para
                    SmartCity</Link>
            </div>

        </div>
    )
}