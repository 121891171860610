import Barras from "components/Graficos/Barras";
import Radar from "components/Graficos/Radar";
import TabelaAtendimento from "components/Tables/TabelaAtendimento";
import {AtendimentoEixo} from "interfaces/Eixo";
import {atenEixoToDataset} from "utils/AtenEixoToDataset";
import {Cores, RgbaCores} from "utils/Cores";


export default function GraficosResultados({
                                               resultado,
                                           }: {
    resultado: AtendimentoEixo[];
}) {
    return (
        <>
            <div className='flex justify-between'>
                <p className="text-[1.5rem] text-[#132D46] font-bold font-raleway">
                    Resultado obtido
                </p>
            </div>


            <div className='mt-12 m-auto w-[40%]'>
                <Radar
                    data={
                        atenEixoToDataset({
                            graficos: [{
                                label: 'Resultado',
                                resultado: resultado,
                                backgroundColor: RgbaCores.AzulRgba,
                                borderColor: Cores.Azul,
                                fill: false
                            }]
                        })}
                    op={2}
                />
            </div>

            <div className='mt-12 m-auto w-[80%]'>
                <Barras
                    data={
                        atenEixoToDataset({
                            graficos: [{
                                label: 'Resultado',
                                resultado: resultado,
                                backgroundColor: RgbaCores.AzulRgba,
                                borderColor: Cores.Azul,
                                fill: false
                            }]
                        })}
                />
            </div>

            <p className="text-[1.5rem] text-[#132D46] font-bold mt-12 font-raleway">
                Taxas de serviço
            </p>

            <TabelaAtendimento
                className='w-[60%] mt-4'
                resultado={resultado}
            />

        </>
    )
}