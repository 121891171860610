export default function ComoFuncionaQuestionarios() {
    return (
        <div className='flex flex-col gap-4'>
            <div className='text-lg font-bold text-green-smart_city'>
                Como criar questionários?
            </div>

            <div className='text-base font-medium text-gray-5 flex flex-col gap-4'>
                <div>Crie questionários e obtenha diagnósticos para sua pesquisa!</div>
                <div className='flex'>
                    <div>Vá no menu &nbsp;</div>
                    <div className='font-bold'>Questionários &gt; Meus questionários &gt; Novo Questionário :</div>
                </div>
                <li className='list-inside'>Você pode escolher entre criar um questionário do zero ou criar a partir de
                    existente (explicar o que é cada um deles)
                </li>
            </div>

            <div className='text-lg font-bold text-green-smart_city'>
                Editando questionários
            </div>

            <div className='text-base font-medium text-gray-5 flex flex-col gap-4'>
                <div>Preencha as etapas de construção do questionário, no canto direito
                    você pode observar todas elas enumeradas de 1 a 5,
                    sendo elas: Nome, eixos, questões, eixo-pergunta e configurações.
                </div>
            </div>

            <img className='w-[40rem] h-auto'
                 src={require('../../../assets/images/ComoFuncionaImages/como_funciona_q_01.png')} alt=""/>

            <ol className='list-decimal font-bold text-base text-gray-5 flex flex-col gap-4'>

                <li>Nome e Descrição</li>
                <div className='font-medium'>Adicione o nome e a descrição ao seu questionário para que os respondentes
                    saibam para qual assunto e propósito estarão contribuindo.
                </div>

                <li>Eixos do questionário</li>
                <div className='font-medium'>Adicione os eixos que serão avaliados no seu questionário (Mais informações
                    na seção “eixos” dessa página).
                </div>

                <li>Questões</li>
                <div className='font-medium'>Nessa etapa será criada as questões que seu público irá responder. Você
                    pode adicionar perguntas, descrições da pergunta
                    (opcional) e até 10 respostas de múltipla escolha. Você precisa atribuir pesos às respostas para
                    gerar o gráfico de resultados.
                </div>

                <li>Relação Eixo-Pergunta</li>
                <div className='font-medium'>Defina a relação de pesos entre as perguntas e os eixos que você atribuiu a
                    este questionário.
                </div>

                <li>Configure</li>
                <div className='font-medium flex flex-col gap-4'>
                    <div>Configure o status do questionário, ele pode ser:</div>
                    <li className='list-inside list-disc'>
                        <strong>Em construção:</strong> quando o questionário ainda está sendo criado e é possível fazer
                        alterações e edições. Nesse status ele não está visível para respostas ainda;
                    </li>

                    <li className='list-inside list-disc'>
                        <strong>Disponível:</strong> quando o questionário está pronto para ser respondido pelo seu
                        público, mas atenção, uma vez disponível não é possível fazer alterações em seus dados, por
                        isso, revise bem as informações.
                    </li>

                    <li className='list-inside list-disc'>
                        <strong>Inativo:</strong> nesse status o questionário não fica mais disponível para respostas
                        mas seu criador ainda pode observar os resultados
                    </li>

                </div>
            </ol>
        </div>


    )
}