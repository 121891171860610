import {Modal} from "@mui/material";
import {FaCheckSquare} from "react-icons/fa";

export default function ModalSelecao
(
    {
        mensagens,
        acao,
        modalState: [open, setOpen]
    }:
        {
            mensagens: string[],
            acao: () => void,
            modalState: [boolean, (open: boolean) => void]
        }
) {

    return (
        <>
            {
                open && (
                    <Modal
                        className="w-[40rem] h-[26rem] m-auto"
                        open={open}
                        onClose={() => setOpen(false)}
                    >
                        <div
                            className={`bg-white-1 w-full h-full flex flex-col rounded-[1rem] space-y-8`}
                        >
                            <p className={`text-left font-bold font-raleway text-[1.5rem] text-[#132D46] text mt-6 ml-5`}>
                                Você Selecionou:
                            </p>

                            <div className="flex flex-col h-[14rem]">
                                {mensagens.map((mensagem, index) => (
                                    <div key={index} className="flex items-center justify-between bg-gray-100 p-2 mb-2">
                                        <div className="flex items-center pl-7">
                                            <FaCheckSquare color={"#01C38E"} className=" mr-2 ml-3"/>
                                            <p className="font-bold font-raleway text-[#132D46] text-[1rem]">{mensagem}</p>
                                        </div>
                                        {/* <p className="font-bold font-raleway text-[#132D46] text-[1rem] pr-20">{"data"}</p> */}
                                    </div>
                                ))}
                            </div>
                            <div className={"flex flex-shrink-0 justify-center space-x-5"}>
                                <button
                                    className={"bg-gray-light_2  rounded-[0.175rem] w-[12rem] text-[1.2rem] py-[0.3rem] text-black-2"}>
                                    Cancelar
                                </button>
                                <button
                                    className={`bg-[#01C38E]  rounded-[0.175rem] w-[12rem] text-[1.2rem] py-[0.3rem] text-[#FFFFFf]`}
                                    onClick={() => acao()}
                                >
                                    Comparar
                                </button>
                            </div>

                        </div>
                    </Modal>
                )}
        </>
    )
}
