import {useEffect, useState} from 'react';
import {PaginatedResponse, Pagination} from 'interfaces/Pagination';
import {useQuery} from '@tanstack/react-query';
import {UsuarioRespondeQuestionario} from 'interfaces/UsuarioRespondeQuestionario';
import {getRespostas, getRespostasQuestionario} from 'api/RespostasService';
import {api} from "../api/api";

interface UseGetRespostasQuestionarioProps {
    idQuestionario: number;
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}

export function useGetRespostasQuestionario({
                                                idQuestionario,
                                                page = 1,
                                                pageSize = 10,
                                                filters,
                                                ordering
                                            }: UseGetRespostasQuestionarioProps) {
    const [respostas, setRespostas] = useState<UsuarioRespondeQuestionario[]>([]);
    const [pagination, setPagination] = useState<Pagination>({count: 0});
    const [debouncedParams, setDebouncedParams] = useState({page, pageSize, filters, ordering});

    const {data, refetch, error} = useQuery<PaginatedResponse<UsuarioRespondeQuestionario>>({
        queryKey: ['respostas', debouncedParams.page, debouncedParams.pageSize, debouncedParams.filters, debouncedParams.ordering],
        queryFn: async () => {
            const response = await getRespostasQuestionario({
                idQuestionario,
                page: debouncedParams.page,
                pageSize: debouncedParams.pageSize,
                filters: debouncedParams.filters,
                ordering: debouncedParams.ordering,
            });
            return response.data;
        },
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedParams({page, pageSize, filters, ordering});
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page, pageSize, filters, ordering]);

    useEffect(() => {
        if (data) {
            setRespostas(data.results);
            setPagination({
                ...data
            });
        }
    }, [data]);

    return {respostas, pagination, refetch, error};
}

interface UseGetRespostasProps {
    page?: number;
    pageSize?: number;
    filters?: { [key: string]: string };
    ordering?: string;
}


export function useGetRespostas({page = 1, pageSize = 10, filters, ordering}: UseGetRespostasProps) {
    const [respostas, setRespostas] = useState<UsuarioRespondeQuestionario[]>([]);
    const [pagination, setPagination] = useState<Pagination>({count: 0});
    const [debouncedParams, setDebouncedParams] = useState({page, pageSize, filters, ordering});

    const {data, refetch, error} = useQuery<PaginatedResponse<UsuarioRespondeQuestionario>>({
        queryKey: ['respostas', debouncedParams.page, debouncedParams.pageSize, debouncedParams.filters, debouncedParams.ordering],
        queryFn: async () => {
            const response = await getRespostas({
                page,
                pageSize,
                filters,
                ordering,
            });
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setRespostas(data.results);
            setPagination({
                ...data
            });
        }
    }, [data]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedParams({page, pageSize, filters, ordering});
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page, pageSize, filters, ordering]);

    return {respostas, pagination, refetch, error};
}

export function useGetRespostaQuestionario(idQuestionario: number) {
    const [resposta, setResposta] = useState<UsuarioRespondeQuestionario | null>(null);
    const {data, refetch, error} = useQuery<UsuarioRespondeQuestionario>({
        queryKey: ['resposta', idQuestionario],
        queryFn: async () => {
            const response = await api.get(`questionario/resposta/${idQuestionario}`);
            return response.data;
        },
    });

    useEffect(() => {
        if (data) {
            setResposta(data);
        }
    }, [data]);

    return {resposta, refetch, error};
}