import React, {useEffect, useState} from 'react';
import CardPerguntas from "./components/CardPergunta";
import {responderQuestionario} from "../../api/QuestionarioService";
import {TemplateLeftBar} from 'components';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetQuestionario} from 'hooks/UseGetQuestionarios';
import {useModalContext} from 'contexts/ModalContext';
import LoadingScreen from 'components/LoadingScreen';


export function ResponderQuestionario() {
    const navigate = useNavigate();
    const {id} = useParams();
    const {questionario} = useGetQuestionario(Number(id));
    const {openNotification, openConfirmationModal} = useModalContext();

    const [error, setError] = useState<string | null>(null);

    const [respostas, setRespostas] = useState<Array<{ idPergunta: number | null, idAlternativa: number | null }>>([]);

    function handleOptionChange(idPergunta: number, idAlternativa: number) {
        setRespostas(respostas.map((resposta) => {
            if (resposta.idPergunta === idPergunta) {
                return {...resposta, idAlternativa};
            }
            return resposta;
        }))
    }

    function handleReset() {
        setRespostas(questionario!.perguntas!.map((pergunta) => {
            return {idPergunta: pergunta.id!, idAlternativa: pergunta.alternativas![0].id!};
        }));
    }

    useEffect(() => {
        if (questionario) {
            handleReset();
        }
    }, [questionario]);

    async function handleSend() {

        const idPerguntaNaoRespondida = respostas.findIndex(resposta => resposta.idAlternativa === null);
        if (idPerguntaNaoRespondida !== -1) {
            openNotification(`Pergunta ${idPerguntaNaoRespondida + 1} não respondida`, 'error');
            return;
        }

        try {
            const response = await responderQuestionario({
                idQuestionario: questionario!.id!,
                respostas: respostas.map(resposta => ({
                    pergunta_id: resposta.idPergunta!,
                    alternativa_id: resposta.idAlternativa!
                }))
            });
            navigate('/administrar-questionario/' + response.data.id);
        } catch (error: any) {
            const mensagem = error.response && error.response.data ? error.response.data.error
                : "Erro ao responder questionário";
            openNotification(mensagem, 'error');
        }

    }

    if (!questionario) {
        return <LoadingScreen/>
    }

    return (
        <TemplateLeftBar>
            <div className="p-4 px-20 flex-grow">
                <div className="flex flex-col gap-4">
                    <div
                        className="rounded-lg bg-white-1 text-left text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-white-1">
                        <div className="h-5 bg-[#00BC8F] rounded-t-lg"/>
                        <div className="p-6">
                            <h5 className="mb-1 text-5xl font-normal leading-tight ">
                                {questionario?.nome}
                            </h5>
                            <p className="mb-4 text-base leading-normal">
                                {questionario?.descricao}
                            </p>
                        </div>
                    </div>

                    {questionario?.perguntas?.map((pergunta: any, index: number) => (
                        <CardPerguntas
                            key={pergunta.id}
                            title={'Pergunta ' + (index + 1) + ': ' + pergunta.descricao}
                            description={pergunta.comentario}
                            options={pergunta.alternativas.map((alternativa: any) => ({
                                id: alternativa.id,
                                text: alternativa.descricao
                            }))}
                            selectedOption={respostas.find(resposta => resposta.idPergunta === pergunta.id)?.idAlternativa || null}
                            onOptionChange={(idAlternativa: number) => handleOptionChange(pergunta.id, idAlternativa)}
                        />
                    ))}


                    <div className="flex justify-between">
                        <button className="mr-2 px-4 py-2 bg-blue-login text-white-1 rounded-md" onClick={handleReset}>
                            Limpar Respostas
                        </button>
                        <button className="px-4 py-2 bg-[#00BC8F] text-white-1 rounded-md" onClick={handleSend}>
                            Enviar Respostas
                        </button>
                    </div>
                </div>
            </div>
        </TemplateLeftBar>


    );
}
