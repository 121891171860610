import React from 'react';
import {Link} from 'react-router-dom';

export function Header({fluxo}: { fluxo: { nome: string, path: string }[] }) {
    return (
        <>
            <div className={"flex mt-6 justify-between"}>
                <div className={"font-raleway font-normal text-base w-11/12 text-[#232323] opacity-80 justify-start"}>
                    <h1>
                        {
                            fluxo.map((item, index) => (
                                <span key={index}>
                                    <Link to={item.path} className="text-blue-500 hover:underline">
                                        {item.nome}
                                    </Link>
                                    {index < fluxo.length - 1 ? ' > ' : ''}
                                </span>
                            ))
                        }
                    </h1>
                </div>
            </div>
        </>
    );
}