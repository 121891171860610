import {api, fileApi} from "./api";

export async function serviceCriarAtivo({nome, descricao, arquivo}: {
    nome: string,
    descricao: string,
    arquivo: File
}) {
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('arquivo', arquivo);
    const response = await fileApi.post('ativos/', formData);
    return response;
}

export async function serviceDeletarAtivo({idAtivo}: { idAtivo: number }) {
    const response = await api.delete('ativos/' + idAtivo);
    return response;
}

export async function serviceUpdateAtivo({idAtivo, nome, descricao}: {
    idAtivo: number,
    nome: string,
    descricao: string
}) {
    const response = await api.put('ativos/' + idAtivo, {nome: nome, descricao: descricao});
    return response;
}

export async function serviceGetAtivos() {
    const response = await api.get('ativos/');
    return response;
}

