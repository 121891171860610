export enum Cores {
    Verde = '#01C38E',
    Azul = '#5BA2E2',
    Laranja = '#FF8E2B',
    Vermelho = '#F43232',
    Rosa = '#EC3FEF',
}

export enum RgbaCores {
    VerdeRgba = 'rgba(1, 195, 142, 1)',
    AzulRgba = 'rgba(91, 162, 226, 1)',
    LaranjaRgba = 'rgba(255, 142, 43, 1)',
    VermelhoRgba = 'rgba(244, 50, 50, 1)',
    RosaRgba = 'rgba(236, 63, 239, 1)',
}

export enum RgbaCoresReduzidas {
    VerdeRgba = 'rgba(1, 195, 142, 0.5)',
    AzulRgba = 'rgba(91, 162, 226, 0.5)',
    LaranjaRgba = 'rgba(255, 142, 43, 0.5)',
    VermelhoRgba = 'rgba(244, 50, 50, 0.5)',
    RosaRgba = 'rgba(236, 63, 239, 0.5)',
}

export function getCor(index: number) {
    const cores = Object.values(RgbaCores);

    if (index >= cores.length) {
        return '#000000';
    }

    return cores[index];
}

export function getCorReduzida(index: number) {
    const cores = Object.values(RgbaCoresReduzidas);

    if (index >= cores.length) {
        return '#000000';
    }

    return cores[index];
}

export function getCorHexadecimal(index: number) {
    const cores = Object.values(Cores);

    if (index >= cores.length) {
        return '#000000';
    }

    return cores[index];
}